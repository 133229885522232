/**
 * 模块名称: 待支付列表 => 费用订单列表页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import {
  Table,
  Form,
  Input,
  DatePicker,
  Select,
  message,
  Row,
  Col,
  Modal
} from 'antd'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpQuanQian'
import BtnGroup from '@/components/BtnGroup'
import CryptoJS from 'crypto-js'
import ReviseModal from './module/ReviseModal'
import ClosingModal from './module/ClosingModal'
import PayFailedModal from './module/PayFailedModal'
import '../assets/style.scss'

const { Column } = Table
const FormItem = Form.Item
const { Option } = Select
let pageSize = 10
let currentPage = 1
let apiOperateTimer = true

const { RangePicker } = DatePicker

//所属公司筛选
let filter = {
  companyId: ''
}

//筛选初始值
let exporfilter = {
  id: '',
  costClass: '',
  payWay: '',
  applyStaffName: '',
  busCode: 1,
  sTime: '',
  eTime: '',
  sPassTime: '',
  ePassTime: ''
}

const CostStay = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, resetFields, setFieldsValue, validateFields } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [visibleRevise, setVisibleRevise] = useState(false)//操作=>修改
  const [visibleClosing, setVisibleClosing] = useState(false)//操作=>支付
  const [visiblePayFailed, setVisiblePayFailed] = useState(false)//操作=>终止付款
  const [costClassList, setCostClassList] = useState([])//获取费用类别
	const [payWayList, setPayWayList] = useState([])//获取付款方式
	const [busStatusList, setBusStatusList] = useState([])//获取业务状态
  const [revise, setRevise] = useState({})//操作=>修改内容
  const [closing, setClosing] = useState({})//操作=>支付内容
  const [payFailed, setPayFailed] = useState({})//操作=>终止付款内容
  const [authList, setAuthList] = useState([])

  const search = parseSearch(location.search)

  useEffect(() => {
    filter = {
      companyId: ''
    }
    if (!history.location.state) {
      currentPage = search.page ? +search.page : 1
      pageSize = search.limit ? +search.limit : 10
      exporfilter = {
        id: '',
        costClass: '',
        payWay: '',
        applyStaffName: '',
        busCode: 1,
        sTime: '',
        eTime: '',
        sPassTime: '',
        ePassTime: ''
      }
    } else {
      currentPage = search.page ? +search.page : currentPage
      pageSize = search.limit ? +search.limit : pageSize
      setTimeout(() => {
        setFieldsValue({
          id: exporfilter.id !== '' ? exporfilter.id : undefined,
          costClass: exporfilter.costClass !== '' ? exporfilter.costClass : undefined,
          payWay: exporfilter.payWay !== '' ? exporfilter.payWay : undefined,
          applyStaffName: exporfilter.applyStaffName !== '' ? exporfilter.applyStaffName : undefined,
          busCode: exporfilter.busCode !== '' ? exporfilter.busCode : undefined,
          Time: exporfilter.sTime !== '' ? [moment(exporfilter.sTime), moment(exporfilter.eTime)] : undefined,
          passTime: exporfilter.sPassTime !== '' ? [moment(exporfilter.sPassTime), moment(exporfilter.ePassTime)] : undefined
        })
      }, 1000)
    }
    apiOperateTimer = true
    onCommon()
    getOfficeCostManagementForNotPayFinished()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  //获取列表
  const getOfficeCostManagementForNotPayFinished = () => {
    if (apiOperateTimer) {
      setLoading(true)
      let params = {
        limit: pageSize,
        page: currentPage,
        companyId: filter.companyId,
        // busCode: 1,
        ...exporfilter
      }
      api.getOfficeCostManagementForNotPayFinished(params).then(data => {
        setList(data.list)
        setCount(data.count)
        setLoading(false)
      }).catch(() => setLoading(false))
      apiOperateTimer = false
      let timer = setTimeout(() => {
        apiOperateTimer = true
        clearTimeout(timer)
      }, 1000)
    } else {
      return message.info('请不要频繁操作')
    }
  }

  //获取公共接口
  const onCommon = () => {
    api.getOfficeCostManagementCostClass().then(res => setCostClassList(res)).catch(err => console.log(err))//获取费用类别
		api.getOfficeCostManagementPayWay().then(res => setPayWayList(res)).catch(err => console.log(err))//获取付款方式
		api.getOfficeCostManagementBusStatus().then(res => {
      let arr = res.filter(v => v.id == 1 || v.id == 4)
      // console.log(arr)
      setBusStatusList(arr)
    }).catch(err => console.log(err))//获取业务状态
  }

  //筛选时调接口
  const getEmployeeList = () => {
    setLoading(true)
    api.getOfficeCostManagementForNotPayFinished({
      limit: pageSize,
      page: currentPage,
      companyId: filter.companyId,
      id: exporfilter.id,
      costClass: exporfilter.costClass,
      payWay: exporfilter.payWay,
      applyStaffName: exporfilter.applyStaffName,
      busCode: exporfilter.busCode,
      sTime: exporfilter.sTime,
      eTime: exporfilter.eTime,
      sPassTime: exporfilter.sPassTime,
      ePassTime: exporfilter.ePassTime,
    }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  //点击筛选提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //const values = Object.values(vals)
      //console.log(values)
      if (!err) {
        // console.log(vals)
        currentPage = 1
        history.replace(match.path)
        exporfilter.id = vals.id ? vals.id : ''
        exporfilter.costClass = vals.costClass ? vals.costClass : ''
        exporfilter.payWay = vals.payWay ? vals.payWay : ''
        exporfilter.applyStaffName = vals.applyStaffName ? vals.applyStaffName : ''
        exporfilter.busCode = vals.busCode ? vals.busCode : ''
        exporfilter.sTime = vals.Time ? vals.Time[0].format('YYYY-MM-DD') : ''
        exporfilter.eTime = vals.Time ? vals.Time[1].format('YYYY-MM-DD') : ''
        exporfilter.sPassTime = vals.passTime ? vals.passTime[0].format('YYYY-MM-DD') : ''
        exporfilter.ePassTime = vals.passTime ? vals.passTime[1].format('YYYY-MM-DD') : ''
        getEmployeeList()
      }
    })
  }

  const onReset = (e) => {
    e.preventDefault()
    exporfilter = {
      id: '',
      costClass: '',
      payWay: '',
      applyStaffName: '',
      busCode: 1,
      sTime: '',
      eTime: '',
      sPassTime: '',
      ePassTime: ''
    }
    resetFields()
    currentPage = 1
    history.replace(match.path)
    getEmployeeList()
  }

  //筛选
  const onCorpFilter = (data) => {
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getOfficeCostManagementForNotPayFinished()
  }

  const onChangeTable = (pagination) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getEmployeeList()
  }

  //操作=>修改
  const onRevise = (data) => {
    setRevise(data)
    setVisibleRevise(true)
  }

  //操作 => 支付
  const onClosing = (data) => {
    setClosing(data)
    setVisibleClosing(true)
  }

  //操作=> 终止付款
  const onPayFailed = (data) => {
    setPayFailed(data)
    setVisiblePayFailed(true)
  }

  // 预览/下载凭证
  const onVoucherAction = (record, download = false) => {
    api.getHost({}).then((res) => {
      if (download) {
        window.location = `${res.url}/api/File/Downloads?url=${res.url}/api/OfficeCostManagement/Download?id=${record.id}&name=${record.no}.pdf`;
      } else {
        window.open(
          `${res.url}/api/OfficeCostManagement/Preview?id=${record.id}`,
          '_blank'
        );
      }
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <>
      <CorpFilter onChange={onCorpFilter} part='feiyong'/>
      {/* <CorpFilter supra={false} defaultValue={filter.companyId == '' ? 1 : filter.companyId} onChange={onCorpFilter} part='feiyong' /> */}
      <div className="comm-stay-list">
        <Form onSubmit={formSubmit} {...formItemLayout}>
          <Row gutter={24}>
            <Col span={6}>
              <FormItem label="业务编号">
                {getFieldDecorator('id')(<Input allowClear={true} placeholder="请输入业务编号"/>)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="费用类别">
                {getFieldDecorator('costClass')(
                  <Select
                    placeholder="全部"
                    allowClear={true}
                    dropdownMatchSelectWidth={false}
                    showSearch={true}
                    optionFilterProp="children"
                  >
                    {
                      costClassList.map(v => {
                        return <Option key={v.id}>{v.name}</Option>
                      })
                    }
                  </Select>)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="付款方式">
                {getFieldDecorator('payWay')(
                  <Select
                    placeholder="全部"
                    allowClear={true}
                    dropdownMatchSelectWidth={false}
                    showSearch={true}
                    optionFilterProp="children"
                  >
                    {
                      payWayList.map(v => {
                        return <Option key={v.id}>{v.name}</Option>
                      })
                    }
                  </Select>)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="申请人">
                {getFieldDecorator('applyStaffName')(<Input allowClear={true} placeholder="请输入申请人"/>)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <FormItem label="状态">
                {getFieldDecorator('busCode', { initialValue: '1' })(
                  <Select
                    placeholder="全部"
                    allowClear={true}
                    dropdownMatchSelectWidth={false}
                    showSearch={true}
                    optionFilterProp="children"
                  >
                    {
                      busStatusList.map(v => {
                        return <Option key={v.id}>{v.name}</Option>
                      })
                    }
                  </Select>)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="申请时间">
                {getFieldDecorator('Time')(<RangePicker allowClear={false} />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="审批通过时间">
                {getFieldDecorator('passTime')(<RangePicker allowClear={false} />)}
              </FormItem>
            </Col>
          </Row>
          <div className="comm-stay-btn" >
            <BtnGroup cancelName="重置" confirmName="查询" onCancel={onReset} />
          </div>
        </Form>
      </div>
      <div className="line"></div>
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1600 }}
        onChange={onChangeTable}
      >
        <Column title="业务编号" dataIndex="id" fixed="left" />
        <Column title="费用类别" dataIndex="costClassName" />
        <Column title="公司主体" dataIndex="companyName" />
        <Column title="付款方式" dataIndex="payWayName" />
        <Column title="收款单位" dataIndex="payee" />
        <Column title="开户行" dataIndex="bankName" />
        <Column title="银行账号" dataIndex="bankAccount" />
        <Column title="付款金额" dataIndex='money' />
        <Column title="申请人" dataIndex="applyStaffName" />
        <Column title="状态" dataIndex="busCodeName" />
        <Column title="申请时间" dataIndex="addTime" />
        <Column title="审批通过时间" dataIndex="passTime" />
        <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
          <div>
            <Link target="_blank" to={`/office/costManagement/details?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`}>详情</Link>
            {/* {record.editFlag && <div><a style={{ cursor: 'pointer' }} onClick={() => onRevise(record)}>修改</a></div>} */}
            {record.settlementFlag && <div><a style={{ cursor: 'pointer' }} onClick={() => onClosing(record)}>支付</a></div>}
            {record.payFailFlag && <div><a style={{ cursor: 'pointer' }} onClick={() => onPayFailed(record)}>终止付款</a></div>}
            {record.previewFlag && <div><a type='link' size='small' onClick={() => onVoucherAction(record)} >凭证预览</a></div>}
            {record.downloadFlag && <div><a type='link' size='small' onClick={() => onVoucherAction(record, true)}>下载凭证</a></div>}
          </div>
        )} />
      </Table>
      <Modal
        title="收款方信息修改"
        visible={visibleRevise}
        onCancel={() => setVisibleRevise(false)}
        bodyStyle={{
          height: '480px'
        }}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        footer={null}
      >
        <ReviseModal revise={revise} setVisibleRevise={setVisibleRevise} getEmployeeList={getEmployeeList}/>
      </Modal>
      <Modal
        title="支付确认"
        visible={visibleClosing}
        onCancel={() => setVisibleClosing(false)}
        bodyStyle={{
          height: '680px'
        }}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        footer={null}
      >
        <ClosingModal closing={closing} setVisibleClosing={setVisibleClosing} getEmployeeList={getEmployeeList}/>
      </Modal>
      <Modal
        title="终止付款确认"
        visible={visiblePayFailed}
        onCancel={() => setVisiblePayFailed(false)}
        bodyStyle={{
          height: '600px'
        }}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        footer={null}
      >
        <PayFailedModal payFailed={payFailed} setVisiblePayFailed={setVisiblePayFailed} getEmployeeList={getEmployeeList}/>
      </Modal>
    </>
  )
}

export default Form.create()(CostStay)