/**
 * 模块名称: 培训档案编辑抽屉
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import api from '@/api'
import {
	Form,
	Input,
	Select,
	message,
	Row,
	Col,
  DatePicker
} from 'antd'
import BtnGroup from '@/components/BtnGroup'

const FormItem = Form.Item
const Option = Select.Option

const UploadDocDrawer = (props) => {
	const { getFieldDecorator, validateFields, setFieldsValue } = props.form

	const { setEditVisible, getTrainrecordList, setEditLoading, rowData } = props

  const [saveLoading, setSaveLoading] = useState(false)//eslint-disable-line
  const [staffName, setStaffName] = useState('')
  const [depart, setDepart] = useState('')
  const [categoryList, setCategoryList] = useState([])
  const [organizationList, setOrganizationList] = useState([])
  const [courseList, setCourseList] = useState([])

	useEffect(() => {
		onEditDocRow()
	},[])

	const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
	}
  //获取类别列表
  const getCategoryList = () => {
    api.getTrainrecordCategoryList().then(res => {
      setCategoryList(res)
    })
  }
  //获取组织/教授类别列表
  const getOrganizationList = () => {
    api.getTrainRecordOrganizaData().then(res => {
      setOrganizationList(res)
    })
  }
  //课程列表接口
  const getTrainRecordCourseList = () => {
    api.getTrainRecordCourseList().then(res => {
      setCourseList(res)
    })
  }
	//编辑
	const onEditDocRow = () => {
		setEditLoading(false)	
    setStaffName(rowData.staffName)
    setDepart(rowData.departmentName) 
    setFieldsValue({
      categoryId: rowData.categoryId ? rowData.categoryId : undefined,
      trainingdate: rowData.trainingdate ? moment(rowData.trainingdate) : undefined,
      courseId: rowData.courseId ? rowData.courseId : undefined,
      organization: rowData.organization,
      trainer: rowData.trainer,
      trainclass: rowData.trainclass,
      allscore: rowData.allscore,
      testway: rowData.testway,
      testdate: rowData.testdate ? moment(rowData.testdate) : undefined,
      testresult: rowData.testresult,
      makeupscore: rowData.makeupscore,
      idea: rowData.idea,
      note: rowData.note
    })
    getCategoryList()
    getOrganizationList()
    getTrainRecordCourseList()
	}
	//提交表单
	const formSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			//console.log(vals)
      let data = {}
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item === '' || item !== undefined)) {
          data = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'trainingdate' || k === 'testdate') {
                data[k] = moment(vals[k]).format('YYYY-MM-DD')
              } else {
                data[k] = vals[k]
              }
            }
            if(vals[k] === undefined){
              data[k] = ''
            }
          }
        }
			  let params = Object.assign({}, data)
        params.id = rowData.id
        // console.log(params)
				api.onTrainRecordUpdate(params).then(res => {
					message.success('操作成功')
					setEditVisible(false)
					getTrainrecordList()
				})
      }			
    })
	}
	
	return (
    <>
			<Form onSubmit={formSubmit} {...formItemLayout}>
          <FormItem label="姓名">
            <Row gutter={8}>
              <Col span={12}>
                {staffName}
              </Col>
              <Col span={12}>
                所属部门：{depart}
              </Col>
            </Row>
          </FormItem>
          <FormItem label="类别">
            {getFieldDecorator('categoryId', {
              rules: [{ required: false, message: '请选择类别' }]
            })(
              <Select placeholder="请选择类别">
                {
                  categoryList.map((item, index) => {
                    return <Option key={item.id} value={item.id}>{item.catName}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>
          <FormItem label="培训时间">
            {getFieldDecorator('trainingdate', {
            })(<DatePicker allowClear={false} style={{width: '100%'}} />)}
          </FormItem>
          <FormItem label="课程/项目">
            {getFieldDecorator('courseId', {
              rules: [{ required: false, message: '请输入课程/项目名称' }]
            })(
              <Select placeholder="请输入课程/项目名称">
                {
                  courseList.map((item, index) => {
                    return <Option key={item.id} value={item.id}>{item.name}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>
          <FormItem label="组织/讲授部门">
            {getFieldDecorator('organization', {
              rules: [{ required: false, message: '请选择组织/讲授部门' }]
            })(
              <Select 
                placeholder="请选择组织/讲授部门"
                showSearch={true}
                optionFilterProp="children"
              >
                {
                  organizationList.map((item, index) => {
                    return <Option key={item.id} value={item.id}>{item.name}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>
          <FormItem label="主讲人">
            {getFieldDecorator('trainer')(<Input placeholder="请输入主讲人" />)}
          </FormItem>
          <FormItem label="培训课时">
            {getFieldDecorator('trainclass')(<Input placeholder="请输入培训课时" />)}
          </FormItem>
          <FormItem label="整体评分">
            {getFieldDecorator('allscore')(<Input placeholder="请输入整体评分" />)}
          </FormItem>
          <FormItem label="考核方式">
            {getFieldDecorator('testway')(<Input placeholder="请输入考核方式" />)}
          </FormItem>
          <FormItem label="考核时间">
            {getFieldDecorator('testdate', {
            })(<DatePicker allowClear={false} style={{width: '100%'}} />)}
          </FormItem>
          <FormItem label="考核成绩">
            {getFieldDecorator('testresult')(<Input placeholder="请输入考核成绩" />)}
          </FormItem>
          <FormItem label="补考分数">
            {getFieldDecorator('makeupscore')(<Input placeholder="请输入补考分数" />)}
          </FormItem>
          <FormItem label="提出的建议和意见">
            {getFieldDecorator('idea')(<Input.TextArea placeholder="请输入提出的建议和意见" />)}
          </FormItem>
          <FormItem label="备注">
            {getFieldDecorator('note')(<Input.TextArea placeholder="请输入备注" />)}
          </FormItem>
          <BtnGroup onCancel={() => setEditVisible(false)} loading={saveLoading} />
        </Form>
		</>
	)
}
export default Form.create()(UploadDocDrawer)