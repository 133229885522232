/**
 * 模块名称: 已结算列表 => 费用订单
 * @author jiashaowang@372163.com
 */

 import React from 'react'
 import { Switch, Route } from 'react-router-dom'
 import CostDone from './CostDone'
 
 const CostDoneIndex = ({ match, history }) => {
 
   return (
     <Switch>
       <Route component={CostDone} />
     </Switch>
   )
 }
 
 export default CostDoneIndex