/*
 * @Author: zhuyan 
 * @Date: 2021-11-16 09:53:34 
 * 模块名称: 已支付-充值订单
 */

import React, { useState, useEffect, useRef } from 'react';
import api from '@/api';
import { Link } from 'react-router-dom';
import { Table, Button, Alert } from 'antd';
import CorpQuanQian from '@/components/CorpQuanQian';
import FiltersForm from '@/components/FiltersForm';
import Export from '@/components/Export';
import { parseSearch } from '@/utils';
import Auth from '@/components/AuthMiddleware';
import CryptoJS from 'crypto-js';

// 导出用：筛选条件初始值
let exporfilter = {
  companyId: [],
};

const PayDone = (props) => {
  const { history, match, location } = props;
  const search = parseSearch(location.search);

  const initParams = {
    companyId: 1,
    page: search.page || 1,
    limit: search.limit || 10,
  }
  const [states, setStates] = useState({
    dataSource: [], // 列表
    loading: true,
    total: 0, // 总条数
    filter: {
      project: [], // 项目
      payWay: [{
        id: 1,
        name: '银行转账'
      }, {
        id: 2,
        name: '线上支付'
      }, {
        id: 3,
        name: '信用金还款'
      }], // 筛选：付款方式
    },
    // 筛选条件：列表接口参数
    params: initParams,
  });
  const { dataSource, filter, loading, total, params } = states;

  const productsRef = useRef();

  const [showExport, setShowExport] = useState(false); // 显示导出浮层

  const [authList, setAuthList] = useState([]); // 按钮权限

  useEffect(() => {
    getList();
    const { page, limit, ...others } = params;
    exporfilter = { ...others };
  }, [params]);

  useEffect(() => {
    getPageAuth();
  }, []);

  // 获取列表
  const getList = () => {
    api
      .getTopUpHasCloseList(params)
      .then((res) => {
        setStates((current) => ({
          ...current,
          loading: false,
          dataSource: res.list,
          total: res.count,
        }));
      })
      .catch(() => {
        setStates((current) => ({
          ...current,
          loading: false,
        }));
      });
  };

  // 获取按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then((list) => {
      setAuthList(list);
    });
  };

  // 项目筛选
  const getsysProjectChilds = () => {
    api.getsysProjectChilds({ pid: 0 }).then(data => {
      setStates((current) => ({
        ...current,
        filter: {
          ...filter,
          project: data,
        },
      }));
    })
  }

  // 点击选择所属公司
  const onChangeCorp = (data) => {
    setStates((current) => ({
      ...current,
      loading: true,
      params: {
        ...initParams,
        companyId: data.map((item) => item.id),
      },
    }));
    history.replace(match.path);
  };

  // 分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    const { current: page, pageSize: limit } = pagination;
    setStates((current) => ({
      ...current,
      loading: true,
      params: {
        ...params,
        page: page,
        limit: limit,
      },
    }));
    history.replace(match.path + '?page=' + page + '&limit=' + limit);
  };

  // 点击筛选
  const onSubmit = (values) => {
    initParams.page = 1;
    initParams.limit = 10;
    setStates((current) => ({
      ...current,
      loading: true,
      params: {
        ...initParams,
        companyId: params.companyId,
        ...values,
      },
    }));
    history.replace(match.path);
  };

  // 预览/下载凭证
  const onVoucherAction = (record, download = false) => {
    api.getHost({}).then((res) => {
      if (download) {
        window.location = `${res.url}/api/fortune/File/DownPdf?id=${record.id}&type=${'TopUpProof'}`;
      } else {
        window.open(
          `${res.url}/api/fortune/File/PrevPdf?id=${record.id}&type=${'TopUpProof'}`,
          '_blank'
        );
      }
    });
  };

  // 批量下载凭证
  //  const onMultiDownload = () => {
  //    api.getHost({}).then((res) => {
  //      const params = new URLSearchParams();
  //      for (let key in exporfilter) {
  //        if (exporfilter[key] && exporfilter[key] !== '') {
  //          params.set(key, exporfilter[key]);
  //        }
  //      }
  //      window.location = `${
  //        res.url
  //      }/api/fortune/refundOrder/multiDownload?${params.toString()}`;
  //    });
  //  };

  const columns = [
    {
      title: '订单编号',
      dataIndex: 'number',
      fixed: 'left',
      width: 160,
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入订单编号',
      },
    },
    {
      title: '业务编号',
      dataIndex: 'id',
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入业务编号',
      },
      render: (text, record) => {
        return (
          <Link target='_blank'
            to={`/supplie/topUp/detail?id=${record.id
              }&time=${+new Date()}&sign=${CryptoJS.MD5(
                record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + +new Date()
              ).toString()}`}
          >
            {text}
          </Link>
        );
      },
    },
    {
      title: '项目',
      dataIndex: 'projectId',
      valueType: 'Select',
      optionFilterProp: "children",
      fieldProps: {
        placeholder: '全部',
        showSearch: true,
        options: filter.project.map((item) => ({
          label: item.name,
          value: item.id,
        })),
        onDropdownVisibleChange: (open) => {
          if (open) {
            getsysProjectChilds();
          }
        },
      },
      render: (text, record) => record.projectName,
    },
    { title: '公司主体', dataIndex: 'companyName' },
    {
      title: '付款方式',
      dataIndex: 'payType',
      valueType: 'Select',
      optionFilterProp: "children",
      fieldProps: {
        placeholder: '全部',
        showSearch: true,
        options: filter.payWay.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      },
      render: (text, record) => record.payTypeName,
    },

    { title: '收款单位', dataIndex: 'payee' },
    { title: '开户行', dataIndex: 'openBank' },
    { title: '银行账号', dataIndex: 'bankNumber' },
    {
      title: '充值金额',
      dataIndex: 'money',
      render: (text, record) => (+record.money).toFixed(2),
    },
    {
      title: '申请时间',
      dataIndex: 'createTime',
      valueType: 'RangePicker',
      fieldProps: {
        allowClear: false,
      },
      search: {
        transform: (value) => {
          return {
            applyStart: value[0].format('YYYY-MM-DD'),
            applyEnd: value[1].format('YYYY-MM-DD'),
          };
        },
      },
    },
    {
      title: '审批通过时间',
      dataIndex: 'auditTime',
      valueType: 'RangePicker',
      fieldProps: {
        allowClear: false,
      },
      search: {
        transform: (value) => {
          return {
            auditStart: value[0].format('YYYY-MM-DD'),
            auditEnd: value[1].format('YYYY-MM-DD'),
          };
        },
      },
    },
    {
      title: '付款完成时间',
      dataIndex: 'financeOpeTime',
      valueType: 'RangePicker',
      fieldProps: {
        allowClear: false,
      },
      search: {
        transform: (value) => {
          return {
            payStart: value[0].format('YYYY-MM-DD'),
            payEnd: value[1].format('YYYY-MM-DD'),
          };
        },
      },
    },
    {
      title: '操作',
      key: 'oprate',
      fixed: 'right',
      width: 120,
      render: (text, record) => {
        return (
          // <Auth auths={authList} code='operate'>
          <>
            {record.hasPrev ?
              <Button
                type='link'
                size='small'
                onClick={() => onVoucherAction(record)}
              >
                凭证预览
             </Button> : null
            }
            {record.hasDown ?
              <Button
                  type='link'
                  size='small'
                  onClick={() => onVoucherAction(record, true)}
                >
                  下载凭证
              </Button> : null
            }
          </>
          // </Auth>
        );
      },
    },
  ];

  return (
    <>
      <CorpQuanQian supra={false} defaultValue={params.companyId} onChange={onChangeCorp} />
      <FiltersForm columns={columns} onSubmit={onSubmit} onReset={onSubmit} />
      <div className='line'></div>
      <div className='add-wrap'>
        <Auth auths={authList} code='export'>
          <Button
            type='primary'
            title='导出'
            onClick={() => setShowExport(true)}
            style={{ marginRight: 10 }}
          >
            导出
           </Button>
        </Auth>
        {/* <Auth auths={authList} code='operate'>
           <Button type='primary' title='批量下载凭证' onClick={onMultiDownload}>
             批量下载凭证
           </Button>
         </Auth> */}
      </div>
      <Alert
        className='corp-count'
        message={`总计：${total}条数据`}
        type='info'
        showIcon
      />
      <Table
        size='small'
        columns={columns}
        dataSource={dataSource}
        rowKey='id'
        loading={loading}
        className='askforleave-table'
        pagination={{
          pageSize: parseInt(params.limit),
          total: total,
          current: parseInt(params.page),
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        scroll={{ x: 2000 }}
      ></Table>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false);
          productsRef.current.changeVal();
        }}
        tplUrl='getTopUpChangeExportTpl'
        fieldsUrl='getTopUpChangeTplItems'
        saveUrl='saveTopUpChangeExportTpls'
        exportUrl='exportTopUpChange'
        method='export'
        parame={{
          ExportType: 'topup.export',
          ...exporfilter,
        }}
        cRef={productsRef}
      />
    </>
  )
};

export default PayDone;

