/*
 * @Author: zhuyan 
 * @Date: 2021-11-05 11:26:08 
 * @Last Modified by: zhuyan
 * @Last Modified time: 2021-11-24 14:57:09
 * 系统充值详情
 */

import React, { useState, useEffect, useRef } from 'react'
import {
  Descriptions,
  Steps,
  Button,
  Modal,
  Input,
  Icon,
  message,
  Spin,
  Upload
} from 'antd'
import api from '@/api'
import { parseSearch, debounce, changeMoneyToChinese } from '@/utils'
import { upLoadModule } from '@/utils/common'
import CryptoJS from 'crypto-js'

const dItem = Descriptions.item
const { Step } = Steps
const { Dragger } = Upload

const TopUpDetail = (props) => {
  const { location, history } = props
  const proofRef = useRef(null)
  const [detailData, setDetailData] = useState({})
  const [proof, setProof] = useState([])
  const [detailDataId, setdetailDataId] = useState('')
  const [statusList, setStatusList] = useState([])
  const [logConList, setLogConList] = useState([])
  const [buttonAuthList, setButtonAuthList] = useState([])
  const [passModalVisible, setModalVisible] = useState(false) //确认弹框
  const [turnModalVisible, setTurnModalVisible] = useState(false) //驳回弹框
  const [textAreaVal, setTextAreaVal] = useState('') //判断框是否为空
  const [textDisplay, setTextDisplay] = useState(true) //是否提示必选
  const [pasDesc, setPasDesc] = useState('')  //通过意见
  const [showFile, setShowFile] = useState([]) //附件
  const search = parseSearch(location.search)
  const [pageLoading, setPageLoading] = useState(false)
  const [moneyChinese, setMoneyChinese] = useState('')
  const [proofVisible, setProofVisible] = useState(false)
  const [proofList, setProofList] = useState([])

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if ((plaintext && plaintext) == (plaintextTwo && plaintextTwo) && +new Date() - +search.time <= 21600000) {
      setPageLoading(true)
      getDetail(search.id)
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  // 获取详情
  const getDetail = (id) => {
    api.getTopUpDetail({ id }).then(data => {
      setPageLoading(false)
      if (search.msgId) {
        api.setMessageReaded({ ids: [search.msgId] })
      }
      const { basic, auditStatus, auditHistory, auditButton } = data
      const { id, proof, attachment, money } = basic
      setDetailData(basic)
      setdetailDataId(id)
      setProof(Array.isArray(proof) ? proof : JSON.parse(proof)) // 凭证
      setStatusList(auditStatus) // 审批流
      setLogConList(auditHistory) // 审批记录表
      setButtonAuthList(auditButton) // 审批权限
      setShowFile(attachment) //附件
      setMoneyChinese(changeMoneyToChinese(money))
    }).catch(() => {
      setPageLoading(false)
    })
  }

  // 通过
  const onBtnPassShow = () => {
    setModalVisible(true)
  }

  const onPassCancel = () => {
    setModalVisible(false)
  }

  const onPassDescChange = (e) => {
    setPasDesc(e.target.value)
  }

  const onApprove = () => {
    api.getTopUpAudit({
      id: detailDataId,
      type: 'pass',
      remark: pasDesc
    }).then(() => {
      getDetail(search.id) //确认通过后
    })
    setModalVisible(false)
  }

  // 驳回
  const onBtnTurnShow = () => {
    setTurnModalVisible(true)
    setTextDisplay(true)
  }

  const onTurnCancel = () => {
    setTurnModalVisible(false)
  }

  const onTurnApprove = () => {
    if (buttonAuthList.reject_msg_require) {
      if (textAreaVal.trim() === '') {
        setTextDisplay(false)
        return
      }
    }
    api.getTopUpAudit({
      id: detailDataId,
      type: 'reject',
      remark: textAreaVal
    }).then(() => {
      getDetail(search.id) //确认驳回后
    })
    setTurnModalVisible(false)
  }

  const onDescChange = (e) => {
    setTextAreaVal(e.target.value)
    setTextDisplay(true)
  }

  // 驳回样式
  const turnNode = () => {
    return <>
      确认驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#DC143C" />
    </>
  }

  // 同意样式
  const agreeNode = () => {
    return <>
      确认通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    </>
  }

  // 转上级
  const onTurnUp = () => {
    api.getTopUpAudit({
      id: detailDataId,
      type: 'turnUp',
      remark: ''
    }).then(() => {
      history.push('/supplie/topUp')
    }).catch()
  }

  // 补充资料
  const onReplenish = () => {
    api.getTopUpAudit({
      id: detailDataId,
      type: 'replenish',
      remark: ''
    }).then(() => {
      history.push('/supplie/topUp')
    }).catch()
  }

  //退回列表页
  const onGoBack = () => {
    history.push('/supplie/topUp', { goBack: 1 })
  }

  // 凭证修改上传 
  const uploadProofFiles = upLoadModule({
    fileList: proofList,
    setFileList: setProofList,
    draggerRef: proofRef,
    accept: '.xls, .xlsx, .png, .jpg, .jpeg, .pdf, .zip',
    allowSizeType: 2,
    limitNum: 20
  })

  const onProofFiles = () => {  // 凭证修改
    setProofVisible(true)
    setProofList([])
  }

   // 凭证上传 
   const onProofOk = () => {
    if (proofList.length == '' || proofList.length < 0) {
      return message.info('请上传附件')
    }
    let uploadproofList = []
    for (let i = 0, k = proofList.length; i < k; i++) {
      if (proofList[i].status !== 'removed') {
        uploadproofList.push({
          url: proofList[i].url,
          name: proofList[i].name
        })
      }
    }
    //修改凭证附件
    api.editTopUpProofList({
      id: detailData.id,
      proof: uploadproofList
    }).then(data => {
      getDetail(search.id)
    })
    setProofVisible(false)
  }

  return (
    <Spin spinning={pageLoading}>
      <div className="topUp-detail">
        {
          search.new && search.new == 1 && <Button onClick={onGoBack} type="primary" style={{
            position: 'absolute',
            right: '20px',
            top: '-20px'
          }}>返回上一页</Button>
        }
        <div style={{ marginBottom: 8 }}>业务编号：{detailData.id}</div>
        <div className="line-box"></div>
        <Descriptions title={<>
          <span>当前状态</span>
          <Button type="primary" style={{ marginLeft: 10 }}>{detailData.statusName}</Button>
        </>} className="top-detail" column={1}>
          {
            detailData.status == 5 ? //付款终止
              <>
                <div>终止原因：{detailData.reason}</div>
                <div>操作人：{detailData.financeOpeName}</div>
              </> : null
          }
          {
            detailData.status == 4 ?  // 已付款
              <>
                <div>付款凭证：{
                  Array.isArray(proof) && proof.length >= 1 ? proof.map((item, i) => {
                    return <span style={{ marginRight: 18 }} key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></span>
                  }) : <span style={{ marginRight: 26 }}></span>
                } {detailData.hasPayEdit && <a onClick={() => onProofFiles()}>修改</a>}</div>
                <div>操作人：{detailData.financeOpeName}</div>
              </> : null
          }
        </Descriptions>
        <div className="line-box"></div>
        <Descriptions title="审批进度" className="top-detail"></Descriptions>
        <div className="promotionProcess" >
          <Steps>
            {
              statusList.map((item, index) => {
                let status = 'wait'
                if (item.time && item.time.length > 1) {
                  status = 'process'
                }
                return <Step status={status}
                  key={index}
                  value={item.time}
                  title={item.nodeName ? item.nodeName : <div>&nbsp;</div>}
                  description={
                    <>
                      {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                      <div title={item.statusName}>{item.statusName}</div>
                      <div title={item.time}>{item.time}</div>
                    </>
                  }
                />
              })
            }
          </Steps>
        </div>
        <div className="line-box"></div>
        <div style={{ position: 'relative' }}>
          <Descriptions title="基本信息" className="top-detail">
            <dItem label="项目">{detailData.projectName}</dItem>
            <dItem label="产品">{detailData.productName}</dItem>
            <dItem label="供应商名称">{detailData.supplierName}</dItem>
            <dItem label="类型">{detailData.typeName}</dItem>
            <dItem label="公司主体">{detailData.companyName}</dItem>
            <dItem label="付款方式">{detailData.payTypeName}</dItem>
            <dItem label="开户行">{detailData.openBank}</dItem>
            <dItem label="银行账号">{detailData.bankNumber}</dItem>
            <dItem label="收款单位">{detailData.payee}</dItem>
            <dItem label="充值金额" span={3}>{detailData.money}</dItem>
            <dItem label="大写金额">{moneyChinese}</dItem>
          </Descriptions>
          {detailData.type === 3 && <div className="detail-other-type">{detailData.otherType}</div>}
        </div>
        
        <div className="line-box"></div>
        <Descriptions title="其他信息" className="top-detail">
          <dItem label="附件" span={3}>
            <>
              {
                Array.isArray(showFile) && showFile.length >= 1 ? showFile.map((item, i) => {
                  return <span style={{ marginRight: 16 }} key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></span>
                }) : ''
              }
            </>
          </dItem>
          <dItem label="备注">
            <>
              {detailData.remark ? detailData.remark : ''}
            </>
          </dItem>
        </Descriptions>
        {/* 审批记录 */}
        <div className="line-box"></div>
        <Descriptions
          title="审批记录"
          layout="horizontal"
          className="top-detail"
        >
        </Descriptions>
        {logConList.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none", marginBottom: "-30px" }}>
          <dItem label="姓名"></dItem>
          <dItem label="时间"></dItem>
          <dItem label="状态"></dItem>
          <dItem label="审批意见"></dItem>
        </Descriptions>}
        {
          logConList.length > 0 ? logConList.map((item, index) => {
            return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{ borderBottom: "none", marginBottom: "-10px" }}>
              <dItem label="">{item.userInfo.staffName}</dItem>
              <dItem label="">{item.addTime}</dItem>
              <dItem label="">{item.status}</dItem>
              <dItem label="">{item.chargeMsg}</dItem>
            </Descriptions>
          }) : '-'
        }
        <div className="btn-setting">
          <Button hidden={!buttonAuthList.replenish} onClick={debounce(() => { onReplenish() }, 3000)}>补充资料</Button>
          <Button hidden={!buttonAuthList.return_up} onClick={debounce(() => { onTurnUp() }, 3000)}>转上级</Button>
          <Button hidden={!buttonAuthList.hasChargerPower} onClick={onBtnTurnShow}>驳回</Button>
          <Modal
            // title="确认驳回"
            title={turnNode()}
            visible={turnModalVisible}
            onOk={onTurnApprove}
            onCancel={onTurnCancel}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入驳回审批意见" value={textAreaVal} onChange={onDescChange} maxLength={100} />
            </div>
            <span className="poine-text" hidden={textDisplay}>请填写审批意见</span>
          </Modal>
          <Button hidden={!buttonAuthList.hasChargerPower} type="primary" onClick={onBtnPassShow}>通过</Button>
          <Modal
            // title="确认通过"
            title={agreeNode()}
            visible={passModalVisible}
            onOk={onApprove}
            onCancel={onPassCancel}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入通过审批意见" value={pasDesc} onChange={onPassDescChange} maxLength={100} />
            </div>
          </Modal>
        </div>
        <Modal
          title={'修改'}
          visible={proofVisible}
          onOk={debounce(() => { onProofOk() }, 3000)}
          onCancel={() => setProofVisible(false)}
          destroyOnClose={true}
          footer={[
            <Button key="back" onClick={() => setProofVisible(false)}>取消</Button>,
            <Button key="submit" type="primary" onClick={debounce(() => { onProofOk() }, 3000)}>确定</Button>
          ]}
        >

          <div className="dragger-box" ref={proofRef}>
            <Dragger
              {...uploadProofFiles}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
              <p className="ant-upload-hint">
                附件支持格式：xls、xlsx、png、jpg、pdf、zip 单个附件大小不超过5M，不超过20个附件。
                </p>
            </Dragger>
          </div>
        </Modal>
      </div>
    </Spin>
  )
}

export default TopUpDetail