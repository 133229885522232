/*
 * @Author: zhuyan 
 * @Date: 2021-11-04 13:59:29 
 * @Last Modified by: zhuyan
 * @Last Modified time: 2021-11-12 11:23:39
 * 系统充值管理 => 充值申请
 */
import React, { useState, useEffect, useRef } from 'react'
import {
  Form,
  Input,
  Select,
  Upload,
  message,
  Icon,
  Spin,
  Row,
  Col,
  InputNumber
} from 'antd'
import api from '@/api'
import BtnGroup from '@/components/BtnGroup'
import { upLoadModule } from '@/utils/common'
import { changeMoneyToChinese } from '@/utils'

const FormItem = Form.Item
const Option = Select.Option
const { Dragger } = Upload

const payTypeData = [{
  id: 1,
  name: '银行转账'
}, {
  id: 2,
  name: '线上支付'
}, {
  id: 3,
  name: '信用金还款'
}]

const supplierTypeData = [{
  id: 1,
  name: '货款'
}, {
  id: 2,
  name: '保证金'
}, {
  id: 3,
  name: '其他'
}]

const AddTopUp = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue, getFieldValue } = props.form
  const { location, history } = props
  const [selectData, setSelectData] = useState({
    projectList: [], // 项目
    companyList: [] // 主体
  })
  const { state } = location
  const [formLoading, setformLoading] = useState(true)
  const draggerRef = useRef(null)
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [projectName, setProjectName] = useState('')
  const [productName, setProductName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [payName, setPayName] = useState('')
  const [productList, setProductList] = useState([]) //产品
  const [supplierList, setSupplierList] = useState([]) //供应商
  const [supplierName, setSupplierName] = useState('')
  const [showOtherType, setShowOtherType] = useState(false)
  const [moneyChinese, setMoneyChinese] = useState('')

  useEffect(() => {
    onEdit()
  }, [])

  // 获取数据
  const getSelectData = () => {
    setformLoading(true)
    return Promise.all([
      api.getsysProjectChilds({ pid: 0 }), // 项目
      api.getHeadCompanyList() // 主体
    ]).then(list => {
      setformLoading(false)
      setSelectData({
        projectList: list[0],
        companyList: list[1]
      })
      return list
    }).catch(() => setformLoading(false))
  }

  // 编辑
  const onEdit = () => {
    if (state && state.id) {
      api.getTopUpDetail({ id: state.id }).then(res => {
        const { 
          projectId, 
          companyId,
          payType,
          payee,
          openBank,
          bankNumber,
          money,
          remark,
          productId,
          productName,
          supplierId,
          supplierName,
          projectName,
          companyName,
          payTypeName,
          attachment,
          type,
          otherType,
        } = res.basic
        getSelectData().then(() => {
          setFieldsValue({
            projectId,
            companyId,
            payType,
            payee,
            openBank,
            bankNumber,
            money,
            remark,
            type: type === 0 ? undefined : type,
          })
          api.getsysProjectChilds({ pid: projectId }).then(data => {
            setProductList(data)
            setFieldsValue({
              productId,
            })
            setProductName(productName)
          })
          api.getProjectSupplier({ sysProjectId: projectId }).then(data => {
            setSupplierList(data.list)
            setFieldsValue({
              supplierId,
            })
            setSupplierName(supplierName)
          })
          setProjectName(projectName)
          setCompanyName(companyName)
          setPayName(payTypeName)
          setMoneyChinese(changeMoneyToChinese(money))
          if (attachment.length) {
            attachment.forEach((item, index) => {
              fileList.push({
                status: 'done',
                uid: index,
                name: item.name,
                url: item.url
              })
            })
            setFileList(attachment.map((item, index) => ({ ...item, uid: index })))
          }
          if (type === 3) {
            setShowOtherType(true)
            setFieldsValue({
              otherType,
            })
          }

        })
      })
    } else {
      getSelectData()
    }
  }

  // 文件上传 
  const uploadFiles = upLoadModule({
    fileList: fileList,
    setFileList: setFileList,
    draggerRef: draggerRef,
    accept: '.gif, .png, .jpg, .jpeg, .PDF, .zip',
    allowSizeType: 2,
    limitNum: 20
  })

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    }
  }

  //提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      let params = Object.assign({}, vals)
      let uploadList = []
      for (let i = 0, k = fileList.length; i < k; i++) {
        if (fileList[i].status !== 'removed') {
          uploadList.push({
            url: fileList[i].url,
            name: fileList[i].name
          })
        }
      }
      params.attachment = uploadList
      params.companyName = companyName
      params.projectName = projectName
      params.productName = productName
      params.payTypeName = payName
      params.supplierName = supplierName
      params.id = state && state.id
      if (!err) {
        setLoading(true)
        api.getTopUpApply(params).then(res => {
          setLoading(false)
          message.success('提交成功')
          history.push('/supplie/topUp')
        }).catch(() => setLoading(false))
      }
    })
  }

  //取消
  const onCancel = () => {
    history.goBack()
  }

  const onSupplierType = key=> {
    if (key === 3) {
      setShowOtherType(true)
    } else {
      setShowOtherType(false)
    }
  }

  const onSelectProject = (key, Option) => {
    setProjectName(Option.props.children)
    setFieldsValue({
      productId: undefined,
      supplierId: undefined
    })
    setProductName('')
    setSupplierName('')
    api.getsysProjectChilds({ pid: key }).then(data => {
      setProductList(data)
    })
    api.getProjectSupplier({ sysProjectId: key }).then(data => {
      setSupplierList(data.list)
    })
  }

  return (
    <div>
      <Spin spinning={formLoading}>
        <h4 style={{ marginTop: 0, marginBottom: 26 }}>{state && state.id ? '充值驳回重提申请' : '充值申请'}</h4>
        <h4 style={{ marginTop: 12 }}>基本信息</h4>
        <Form onSubmit={formSubmit} {...formItemLayout} className="ant-advanced-search-formTwo">
          {/* 充值申请信息 */}
          <Row gutter={24}>
            <Col span={8}>
              <FormItem label="项目" labelCol={{ span: 8 }}>
                {getFieldDecorator('projectId', {
                  rules: [{ required: true, message: '请选择项目' }]
                })(
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    optionFilterProp="children"
                    dropdownMatchSelectWidth={false}
                    onSelect={onSelectProject}
                  >
                    {selectData.projectList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                  </Select>)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="产品" labelCol={{ span: 8 }}>
                {getFieldDecorator('productId', {
                  rules: [{ required: true, message: '请选择产品' }]
                })(
                  <Select
                    style={{ width: 200 }}
                    dropdownMatchSelectWidth={false}
                    onSelect={(key, Option) => setProductName(Option.props.children)}
                  >
                    {productList && productList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <FormItem label="供应商名称" labelCol={{ span: 8 }}>
                {getFieldDecorator('supplierId', {
                  rules: [{ required: true, message: '请选择供应商' }]
                })(
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    dropdownMatchSelectWidth={false}
                    onSelect={(key, Option) => setSupplierName(Option.props.children)}
                    optionFilterProp="children"
                  >
                    {supplierList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="类型" labelCol={{ span: 8 }}>
                {getFieldDecorator('type', {
                  rules: [{ required: true, message: '请选择类型' }]
                })(
                  <Select
                    style={{ width: 200 }}
                    dropdownMatchSelectWidth={false}
                    onSelect={onSupplierType}
                  >
                    {supplierTypeData.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                  </Select>
                )}
              </FormItem>
            </Col>
            {showOtherType && (
              <div className='supplier-other-type'>
                <FormItem>
                  {getFieldDecorator('otherType', {
                  rules: [{ required: true, message: '请填写类型' }]
                })(<Input maxLength={10} style={{ width: 200 }} />)}
                </FormItem>
              </div>
            )}
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <FormItem label="公司主体" labelCol={{ span: 8 }}>
                {getFieldDecorator('companyId', {
                  rules: [{ required: true, message: '请选择公司主体' }]
                })(
                  <Select
                    style={{ width: 200 }}
                    dropdownMatchSelectWidth={false}
                    onSelect={(key, Option) => setCompanyName(Option.props.children)}
                  >
                    {selectData.companyList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="付款方式" labelCol={{ span: 8 }}>
                {getFieldDecorator('payType', {
                  rules: [{ required: true, message: '请选择付款方式' }]
                })(
                  <Select
                    style={{ width: 200 }}
                    dropdownMatchSelectWidth={false}
                    onSelect={(key, Option) => setPayName(Option.props.children)}
                  >
                    {payTypeData.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          {
            getFieldValue('payType') === 2 || getFieldValue('payType') === 3 ? null : 
              <Row gutter={24}>
                <Col span={8}>
                  <FormItem label="开户行" labelCol={{ span: 8 }}>
                    {getFieldDecorator('openBank', {
                      rules: [{ required: true, message: '请输入开户行' }]
                    })(
                      <Input style={{ width: 200 }} />
                    )}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label="银行账号" labelCol={{ span: 8 }}>
                    {getFieldDecorator('bankNumber', {
                      rules: [{ required: true, message: '请输入银行账号' }]
                    })(<Input style={{ width: 200 }} />)}
                  </FormItem>
                </Col>
              </Row>
          }
          <Row gutter={24}>
            <Col span={8}>
              <FormItem label="收款单位" labelCol={{ span: 8 }}>
                {getFieldDecorator('payee', {
                  rules: [{ required: true, message: '请输入收款单位' }]
                })(<Input style={{ width: 200 }} />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="充值金额" labelCol={{ span: 8 }}>
                {getFieldDecorator('money', {
                  rules: [{ required: true, message: '请输入充值金额' }]
                })(<InputNumber 
                  precision={2} 
                  style={{ width: 200 }} 
                  onBlur={evt => setMoneyChinese(changeMoneyToChinese(evt.target.value))} 
                />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6} offset={8}>
              <FormItem label="大写金额" labelCol={{ span: 8 }}>
                <span>{moneyChinese}</span>
              </FormItem>
            </Col>
          </Row>
          <div>
            <h4 style={{ marginTop: 12 }}>其他信息</h4>
            <div style={{ marginTop: 20 }}>
              <FormItem label="附件" labelCol={{ span: 2 }}>
                <div className="dragger-box" ref={draggerRef}>
                  <Dragger
                    {...uploadFiles}
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                    <p className="ant-upload-hint">
                      附件支持格式：GIF、PNG、JPG、PDF、zip  支持添加多个附件，单个附件大小不超过5M，文件数量不超过20个。
                  </p>
                  </Dragger>
                </div>
              </FormItem>
              <FormItem label="备注" labelCol={{ span: 2 }}>
                {getFieldDecorator('remark')(
                  <Input.TextArea maxLength={100} />
                )}
              </FormItem>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <BtnGroup onCancel={onCancel} confirmName='提交' right loading={loading} />
          </div>
        </Form>
      </Spin>
    </div>
  )
}
export default Form.create()(AddTopUp)
