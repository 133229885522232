/**
 * 模块名称: 供应商管理 => 合同内容确认
 * @author liujingxue@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import SupplierContract from './SupplierContract'
import Apply from './Apply'
import Details from './Details'

const SupplierContractIndex = ({ match, history }) => {

	return (
		<Switch>
			<Route path={match.path + '/apply'} component={Apply} />
			<Route path={match.path + '/details'} component={Details} />
			<Route component={SupplierContract} />
		</Switch>
	)
}

export default SupplierContractIndex