/**
 * 模块名称: 讲师培训编辑抽屉
 * @author liujingxue@372163.com
 */
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import api from '@/api'
import {
	Form,
	Input,
	Select,
	message,
	Row,
	Col,
  DatePicker
} from 'antd'
import BtnGroup from '@/components/BtnGroup'

const FormItem = Form.Item
const Option = Select.Option

const EvaluateEdit = (props) => {
	const { getFieldDecorator, validateFields, setFieldsValue } = props.form

	const { setEditVisible, getTrainrecordList, setEditLoading, rowData } = props

  const [saveLoading, setSaveLoading] = useState(false)//eslint-disable-line
  const [staffName, setStaffName] = useState('')
  // const [depart, setDepart] = useState('')
  const [categoryList, setCategoryList] = useState([])
  const [organizationList, setOrganizationList] = useState([])

	useEffect(() => {
    onDocEditDocRow()
	},[])

	const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
	}
  //获取类别列表
  const getCategoryList = () => {
    api.getTrainrecordCategoryList().then(res => {
      setCategoryList(res)
    })
  }
  //获取组织/教授类别列表
  const getOrganizationList = () => {
    api.getTrainRecordOrganizaData().then(res => {
      setOrganizationList(res)
    })
  }
	//编辑
	const onDocEditDocRow = () => {
		setEditLoading(false)	
    setStaffName(rowData.stfname)
    // setDepart(rowData.depart) 
    //console.log(rowData)
    setFieldsValue({
      classCategoryId: Number(rowData.classCategoryId) ? Number(rowData.classCategoryId) : undefined,
      dodate: rowData.dodate ? moment(rowData.dodate) : undefined,
      classname: rowData.classname,
      departId: rowData.departId ? rowData.departId : undefined,
      peoplenum: rowData.peoplenum,
      secondcategory: rowData.secondcategory,
      trainclass: rowData.trainclass,
      okdegree: rowData.okdegree,
      easydegree: rowData.easydegree,
      isscience: rowData.isscience,
      isapply: rowData.isapply,
      interactive: rowData.interactive,
      lecturer: rowData.lecturer,
      affinity: rowData.affinity,
      logical: rowData.logical,
      grasp: rowData.grasp,
      atmosphere: rowData.atmosphere,
      classCore: rowData.classCore,
      idea: rowData.idea
    })
    getCategoryList()
    getOrganizationList()
	}
	//提交表单
	const formSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			//console.log(vals)
      let data = {}
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item === '' || item !== undefined)) {
          data = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'dodate') {
                data[k] = moment(vals[k]).format('YYYY-MM-DD')
              } else {
                data[k] = vals[k]
              }
            }
            if(vals[k] === undefined){
              data[k] = ''
            }
          }
        }
			  let params = Object.assign({stfname: staffName}, data)
        params.id = rowData.id
        // console.log(params)
				api.onTrainEvaluationUpdate(params).then(res => {
					message.success('操作成功')
					setEditVisible(false)
					getTrainrecordList()
				})
      }			
    })
	}
	
	return (
    <>
			<Form onSubmit={formSubmit} {...formItemLayout}>
          <FormItem label="讲师">
            <Row gutter={8}>
              <Col span={12}>{staffName}</Col>
              <Col span={12}></Col>
            </Row>
          </FormItem>
          <FormItem label="类别">
            {getFieldDecorator('classCategoryId', {
              rules: [{ required: false, message: '请选择类别' }]
            })(
              <Select placeholder="请选择类别">
                {
                  categoryList.map((item, index) => {
                    return <Option key={item.id} value={item.id}>{item.catName}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>
          <FormItem label="实施时间">
            {getFieldDecorator('dodate', {
            })(<DatePicker allowClear={false} style={{width: '100%'}} />)}
          </FormItem>
          <FormItem label="课程名称">
            {getFieldDecorator('classname', {
              rules: [{ required: false, message: '请输入课程名称' }]
            })(<Input placeholder="请输入课程名称" />)}
          </FormItem>
          <FormItem label="组织/讲授部门">
            {getFieldDecorator('departId', {
              rules: [{ required: false, message: '请选择组织/讲授部门' }]
            })(
              <Select 
                placeholder="请选择组织/讲授部门"
                showSearch={true}
                optionFilterProp="children"
              >
                {
                  organizationList.map((item, index) => {
                    return <Option key={item.id} value={item.id}>{item.name}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>
          <FormItem label="人数">
            {getFieldDecorator('peoplenum')(<Input placeholder="请输入人数" />)}
          </FormItem>
          <FormItem label="二级类别">
            {getFieldDecorator('secondcategory')(<Input placeholder="请输入二级类别" />)}
          </FormItem>
          <FormItem label="课程满意度">
            {getFieldDecorator('okdegree')(<Input placeholder="请输入课程满意度" />)}
          </FormItem>
          <FormItem label="难易程度">
            {getFieldDecorator('easydegree')(<Input placeholder="请输入难易程度" />)}
          </FormItem>
          <FormItem label="内容编排科学">
            {getFieldDecorator('isscience', {
            })(<Input placeholder="请输入内容编排科学" />)}
          </FormItem>
          <FormItem label="内容适用">
            {getFieldDecorator('isapply')(<Input placeholder="请输入内容适用" />)}
          </FormItem>
          <FormItem label="互动性">
            {getFieldDecorator('interactive')(<Input placeholder="请输入互动性" />)}
          </FormItem>
					<FormItem label="讲师满意度">
            {getFieldDecorator('lecturer')(<Input placeholder="请输入讲师满意度" />)}
          </FormItem>
          <FormItem label="讲师亲和力">
            {getFieldDecorator('affinity')(<Input placeholder="请输入讲师亲和力" />)}
          </FormItem>
          <FormItem label="授课连贯有逻辑">
            {getFieldDecorator('logical')(<Input placeholder="请输入授课连贯有逻辑" />)}
          </FormItem>
          <FormItem label="课程时间把握">
            {getFieldDecorator('grasp', {
            })(<Input placeholder="请输入课程时间把握" />)}
          </FormItem>
          <FormItem label="课堂气氛">
            {getFieldDecorator('atmosphere')(<Input placeholder="请输入课堂气氛" />)}
          </FormItem>
          <FormItem label="课程评分">
            {getFieldDecorator('classCore')(<Input placeholder="请输入课程评分" />)}
          </FormItem>
          <FormItem label="提出的建议和意见">
            {getFieldDecorator('idea')(<Input.TextArea placeholder="请输入提出的建议和意见" />)}
          </FormItem>
          <BtnGroup onCancel={() => setEditVisible(false)} loading={saveLoading} />
        </Form>
		</>
	)
}
export default Form.create()(EvaluateEdit)