/**
 * 模块名称: 供应商管理 => 合同内容确认 => 申请
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
	Form,
	Input,
	Select,
	Upload,
	message,
	Icon,
	Spin,
	Row,
	Col
} from 'antd'
import api from '@/api'
import BtnGroup from '@/components/BtnGroup'
import { upLoadModule } from '@/utils/common'
import { parseSearch } from '@/utils'

const FormItem = Form.Item
const Option = Select.Option
const { Dragger } = Upload

const Apply = (props) => {
	const { getFieldDecorator, validateFields, setFieldsValue, getFieldValue } = props.form
	const { location, history } = props
	const [selectData, setSelectData] = useState({
		projectList: [], // 项目
		companyList: [] // 主体
	})
	const search = parseSearch(location.search)
	const [formLoading, setformLoading] = useState(true)
	const draggerRef = useRef(null)
	const [fileList, setFileList] = useState([])
	const [loading, setLoading] = useState(false)
	const [projectName, setProjectName] = useState('')
	const [productName, setProductName] = useState('')
	const [companyName, setCompanyName] = useState('')
	const [payName, setPayName] = useState('')
	const [productList, setProductList] = useState([]) //产品
	const [supplierList, setSupplierList] = useState([]) //供应商
	const [supplierName, setSupplierName] = useState('')
	const [showOtherType, setShowOtherType] = useState(false)
	const [moneyChinese, setMoneyChinese] = useState('')
	const [companyList, setCompanyList] = useState([])

	useEffect(() => {
		//获取公司主体
		api.getHeadCompanyList({ limit: global.paramsLimit, type: 'feiyong' }).then(data => setCompanyList(data))//公司主体
		onEdit()
		setformLoading(false)
	}, [])

	// 编辑
	const onEdit = () => {
		if (search && search.id) {
			api.getSupplierContractInfo({ id: search.id }).then(res => {
				setFieldsValue({
					companyId: res.companyId,
					supplierName: res.supplierName,
					remark: res.remark
				})
				const fileLists = res.files
				if (fileLists.length) {
					fileLists.forEach((item, index) => {
						fileList.push({
							status: 'done',
							uid: index,
							name: item.name,
							url: item.url
						})
					})
					setFileList(fileLists.map((item, index) => ({ ...item, uid: index })))
				}
			})
		}
	}

	// 文件上传 
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.doc, .docx, .png, .jpg, .PDF',
		allowSizeType: 8,
		limitNum: 10
	})

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 10 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 14 },
		}
	}

	//提交表单
	const formSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			let params = Object.assign({}, vals)
			let uploadList = []
			for (let i = 0, k = fileList.length; i < k; i++) {
				if (fileList[i].status !== 'removed') {
					uploadList.push({
						url: fileList[i].url,
						name: fileList[i].name
					})
				}
			}
			if (!uploadList.length > 0) {
				return message.error('请填写完整后提交')
			}
			params.files = JSON.stringify(uploadList)
			if (search && search.id) params.id = search.id
			console.log(params)
			if (!err) {
				setLoading(true)
				api.setSupplierContractSave(params).then(res => {
					setLoading(false)
					message.success('提交成功')
					history.push('/supplie/supplierContract')
				}).catch(() => setLoading(false))
			}
		})
	}

	//取消
	const onCancel = () => {
		history.goBack()
	}

	return (
		<div>
			<Spin spinning={formLoading}>
				<h3 style={{ marginTop: 0, marginBottom: 20, fontWeight: 600 }}>新合同申请</h3>
				<div className="line"></div>
				<Form onSubmit={formSubmit} {...formItemLayout} className="ant-advanced-search-formTwo">
					<Row gutter={24}>
						<Col span={10}>
							<FormItem label="公司主体" labelCol={{ span: 10 }}>
								{getFieldDecorator('companyId', {
									rules: [{ required: true, message: '请填写完整后提交' }]
								})(
									<Select
										dropdownMatchSelectWidth={false}
										placeholder="请选择公司主体"
										style={{ width: '200px' }}
										onSelect={(key, Option) => setCompanyName(Option.props.children)}
									>
										{companyList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
									</Select>
								)}
							</FormItem>
						</Col>
						
					</Row>
					<Row gutter={24}>
						<Col span={10}>
							<FormItem label="供应商名称" labelCol={{ span: 10 }}>
								{getFieldDecorator('supplierName', {
									rules: [{ required: true, message: '请填写完整后提交' }]
								})(<Input  placeholder="请输入供应商名称" style={{ width: '200px' }}/>)}
							</FormItem>
						</Col>
					</Row>
					<div style={{ marginTop: 20 }}>
						<FormItem label={<><span style={{ color: 'red', marginRight: '3px', fontSize: '19px' }}>*</span><span>合同附件</span></>} labelCol={{ span: 4 }}>
							<div className="dragger-box" ref={draggerRef}>
								<Dragger
									{...uploadFiles}
								>
									<p className="ant-upload-drag-icon">
										<Icon type="inbox" />
									</p>
									<p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
									<p className="ant-upload-hint">
										附件支持格式：doc、docx、PDF、PNG、JPG 支持添加多个附件，单个附件大小不超过20M，文件数量不超过10个。
									</p>
								</Dragger>
							</div>
						</FormItem>
						<FormItem label="备注" labelCol={{ span: 4 }}>
							{getFieldDecorator('remark')(
								<Input.TextArea maxLength={100} />
							)}
						</FormItem>
					</div>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<BtnGroup onCancel={onCancel} confirmName='提交' right loading={loading} />
					</div>
				</Form>
			</Spin>
		</div>
	)
}

export default Form.create()(Apply)