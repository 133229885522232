/**
 * 模块名称: 待结算列表 => 充值订单
 * @author jiashaowang@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PayStay from './PayStay'

const PayStayIndex = ({ match, history }) => {
  return (
    <Switch>
      <Route component={PayStay} />
    </Switch>
  )
}

export default PayStayIndex