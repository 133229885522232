/**
 * 模块名称: 供应商管理 => 合同内容确认列表
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
	Table,
	Form,
	Alert,
	Button,
	Input,
	Select,
	message,
	Popconfirm,
	DatePicker,
	Switch
} from 'antd'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CorpQuanQian from '@/components/CorpQuanQian'
import { Link } from 'react-router-dom'
import CryptoJS from 'crypto-js'
import moment from 'moment'

const { Column } = Table
const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1
let filter = {}
let shouldMe = 0

const SupplierContract = (props) => {
	const { match, location, history } = props
	const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
	const [loading, setLoading] = useState(false)
	const [list, setList] = useState([])
	const [count, setCount] = useState(0)
	const [authList, setAuthList] = useState([])
	const search = parseSearch(location.search)
	const [projectList, setProjectList] = useState([])
	const [groupList, setGroupList] = useState([])//获取事业群
	const [departmentList, setDepartmentList] = useState([])//获取部门
	const [supplierContractStatus, setSupplierContractStatus] = useState([])

	useEffect(() => {
		currentPage = search.page ? +search.page : currentPage
		pageSize = search.limit ? +search.limit : pageSize
		common()
		if (!history.location.state) {
			filter = {
				companyId: '',
				id: '',
				contractNo: '',
				supplierName: '',
				applyName: '',
				applyStaffNo: '',
				groupId: '',
				departmentId: '',
				businessStatus: '',
			}
			shouldMe = 0
		} else {
			const {
				id,
				contractNo,
				supplierName,
				applyName,
				applyStaffNo,
				groupId,
				departmentId,
				businessStatus,
			} = filter
			setTimeout(() => {
				setFieldsValue({
					id: id ? id : undefined,
					contractNo: contractNo ? contractNo : undefined,
					supplierName: supplierName ? supplierName : undefined,
					applyName: applyName ? applyName : undefined,
					applyStaffNo: applyStaffNo ? applyStaffNo : undefined,
					groupId: groupId ? groupId : undefined,
					departmentId: departmentId ? departmentId : undefined,
					businessStatus: businessStatus ? businessStatus : undefined
				})
			}, 600)
		}
		getSupplierContractList()
		api.getPageAuth().then(list => setAuthList(list))
	}, [])

	const getSupplierContractList = () => {
		setLoading(true)
		filter.shouldMe = shouldMe
		// if (shouldMe) {
		// 	filter.shouldMe = shouldMe
		// } else {
		// 	for (let i in filter) {
		// 		if (i == 'shouldMe') delete filter[i]
		// 	}
		// }
		let params = Object.assign({}, filter)
		for (let i in params) {
			if (params[i] === '') delete params[i]
		}
		api.getSupplierContractList({ ...params, type: 2, limit: pageSize, page: currentPage })
			.then(data => {
				setLoading(false)
				setList(data.list)
				setCount(data.count)
			})
			.catch(() => setLoading(false))
	}

	const common = () => {
		api.getSelectGroupList({ limit: global.paramsLimit }).then(res => setGroupList(res.list)).catch(err => console.log(err))//获取事业群
		api.getSelectDepartmentList({ limit: global.paramsLimit }).then(data => setDepartmentList(data.list)) //部门
		api.getCommon({ type: 'SupplierContract.businessStatus' }).then(data => {
			let arr = data.filter(v => v.id !== 0)
			setSupplierContractStatus(arr)
		})
	}

	// 查询
	const onSearch = evt => {
		evt.preventDefault()
		validateFields((err, vals) => {
			if (!err) {
				currentPage = 1
				history.replace(match.path)
				// if (vals.applyTime) vals.applyStart = vals.applyTime[0].format('YYYY-MM-DD')
				// if (vals.applyTime) vals.applyEnd = vals.applyTime[1].format('YYYY-MM-DD')
				filter = { ...filter, ...vals }
				for (let i in filter) {
					if (filter[i] === undefined || i === 'applyTime') {
						filter[i] = ''
					}
				}
				getSupplierContractList()
			}
		})
	}

	// 重置
	const onReset = () => {
		resetFields()
		shouldMe = 0
		filter = {
			companyId: filter.companyId,
			id: '',
			contractNo: '',
			supplierName: '',
			applyName: '',
			applyStaffNo: '',
			groupId: '',
			departmentId: '',
			businessStatus: '',
		}
		api.getSelectDepartmentList({ limit: global.paramsLimit }).then(data => setDepartmentList(data.list)) //部门
		currentPage = 1
		history.replace(match.path)
		getSupplierContractList()
	}

	// 删除
	const onDelete = (id) => {
		api.setSupplierContractDelete({ id }).then(() => {
			message.success('删除成功')
			getSupplierContractList()
		})
	}

	const onChangeTable = (pagination, filters, sorter) => {
		currentPage = pagination.current
		pageSize = pagination.pageSize
		history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
		getSupplierContractList()
	}

	const onChangeCorp = (data) => {
		filter.companyId = data.map(item => item.id).join(',')
		history.replace(match.path)
		currentPage = 1
		getSupplierContractList()
	}

	const onSetShouldMe = checked => {
		shouldMe = checked ? 1 : 0
		getSupplierContractList()
	}

	//选择事业群时部门筛选
	const onSelectGroup = (value) => {
		console.log(value)
		api.getSelectDepartmentList({ limit: global.paramsLimit, groupId: value }).then(data => setDepartmentList(data.list)) //部门
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 6 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 18 },
		}
	}
	return (
		<>
			<CorpQuanQian onChange={onChangeCorp} part='feiyong'/>
			<div className="search-doc-wrap">
				<Form onSubmit={onSearch} {...formItemLayout}>
					<div className="form-box">
						<FormItem label="业务编号">
							{getFieldDecorator('id')(<Input allowClear={true} />)}
						</FormItem>
						<FormItem label="合同号">
							{getFieldDecorator('contractNo')(<Input allowClear={true} />)}
						</FormItem>
						<FormItem label="供应商名称">
							{getFieldDecorator('supplierName')(<Input allowClear={true} />)}
						</FormItem>
						<FormItem label="申请人">
							{getFieldDecorator('applyName')(<Input allowClear={true} />)}
						</FormItem>
						<FormItem label="员工编号">
							{getFieldDecorator('applyStaffNo')(<Input allowClear={true} />)}
						</FormItem>
						<FormItem label="所在事业群">
							{getFieldDecorator('groupId')(
								<Select
									placeholder="全部"
									showSearch={true}
									optionFilterProp="children"
									dropdownMatchSelectWidth={false}
									allowClear={true}
									onSelect={onSelectGroup}
								>
									{groupList && groupList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
								</Select>)}
						</FormItem>
						<FormItem label="所在部门">
							{getFieldDecorator('departmentId')(
								<Select 
									placeholder="全部" 
									showSearch={true}
									optionFilterProp="children" 
									dropdownMatchSelectWidth={false} 
									allowClear={true}
								>
									{departmentList && departmentList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
								</Select>
							)}
						</FormItem>
						<FormItem label="状态">
							{getFieldDecorator('businessStatus')(
								<Select placeholder="全部" allowClear={true}>
									{supplierContractStatus.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
								</Select>
							)}
						</FormItem>
					</div>
					<FormItem className="btns" label=" " colon={false}>
						<Switch
							checkedChildren="待审批"
							unCheckedChildren="待审批"
							disabled={loading}
							onClick={onSetShouldMe}
							className="shouldme-switch-btn"
							checked={shouldMe ? true : false}
						/>
						<Button onClick={onReset}>重置</Button>
						<Button type="primary" htmlType="submit">查询</Button>
					</FormItem>
				</Form>
			</div>
			<div className="line"></div>
			<div className="add-wrap">
				<Auth auths={authList} code="supplier-contract-apply">
					<Link to={`${match.path}/apply`}>
						<Button type="primary">新合同申请</Button>
					</Link>
				</Auth>
			</div>
			<Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
			<Table
				size="small"
				dataSource={list}
				rowKey="id"
				className="table-page"
				loading={loading}
				pagination={{
					pageSize,
					total: count,
					current: currentPage,
					showQuickJumper: true,
					showSizeChanger: true,
					pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
				}}
				scroll={{ x: 1400, y: 500 }}
				onChange={onChangeTable}
			>
				<Column title="业务编号" dataIndex="id" />
				<Column title="合同号" dataIndex="contractNo" />
				<Column title="供应商名称" dataIndex="supplierName" />
				<Column title="公司主体" dataIndex="companyName" />
				<Column title="申请人" dataIndex="applyName" />
				<Column title="员工编号" dataIndex="applyStaffNo" />
				<Column title="所在事业群" dataIndex="companyGroupName" />
				<Column title="所在部门" dataIndex="departmentName" />
				<Column title="状态" dataIndex="businessStatusCn" />
				<Column title="申请时间" dataIndex="addTime" width={120} />
				<Column title="操作" key="set" fixed="right" align="center" width={80} render={(text, record) => (
					<>
						<Link to={`${match.path}/details?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`}>
							详情
						</Link>
						{record.userAuth && record.userAuth.allowRakeUp && <div>
							<Link to={`${match.path}/apply?id=${record.id}`}>编辑</Link>
						</div>}
						{record.userAuth && record.userAuth.allowDelete && (
							<div>
								<Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
									<a>删除</a>
								</Popconfirm>
							</div>
						)}
						{record.userAuth && record.userAuth.allowGaizhang && <div>
							<Link to={`/supplie/supplierContractSeal/apply?id=${record.id}&type=1`}>盖章申请</Link>
						</div>}
					</>
				)} />
			</Table>
		</>
	)
}

export default Form.create()(SupplierContract)