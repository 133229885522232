/**
 * 模块名称: 公积金管理
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
// import urls from '@/api/urls'
import {
  Table,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Row,
  Col,
  DatePicker
} from 'antd'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpFilter'
import Import from '@/components/Import'
import Export from '@/components/Export'

const { Column } = Table
const { MonthPicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1

let filter = {
  companyId: '',
  staffNo: '',
  staffName: '',
  identityCardNo: '',
  payDate: '',
  groupId: '',
  departmentId: ''
}

let selectedIds = ''

const Funds = (props) => {
  const productsRef = useRef()
  const ImportRef = useRef()
  const { match, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [expand, setExpand] = useState(false)
  const [batchDepartment, setBatchDepartment] = useState([])  //部门
  const [groupList, setGroupList] = useState([]) //事业群
  const [authList, setAuthList] = useState([])
  const [showExport, setShowExport] = useState(false)
  const [showImport, setShowImport] = useState(false)
  const [selectedCount, setSelectedCount] = useState(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const search = parseSearch(props.location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10

    filter = {
      companyId: '',
      staffNo: '',
      staffName: '',
      identityCardNo: '',
      payDate: '',
      groupId: '',
      departmentId: ''
    }
    getList()
    onDepartment()
    onGroupList()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getList = () => {
    setLoading(true)
    api.getSocialAccumulateGetList({ ...filter, limit: pageSize, page: currentPage })
      .then(data => {
        setLoading(false)
        // let mapData = data.list.filter(item => item.operateAuth.indexOf("browse") !== -1)
        // setList(mapData)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
  }


  const onChangeCorp = (data) => {
    setBatchDepartment([])
    setSelectedCount(0)
    setSelectedRowKeys([])
    setFieldsValue({'departmentId': undefined})
    filter.departmentId = ''
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  // 部门
  const onDepartment = () => {
    api.getSelectDepartmentList({
      companyId: filter.companyId,
      limit: global.paramsLimit
    }).then(data => {
      setBatchDepartment(data.list)
    }).catch(() => setLoading(false))
  }

  const onDropdownVisible = () => {
    api.getSelectDepartmentList({ limit: global.paramsLimit, companyId: filter.companyId }).then(data => setBatchDepartment(data.list)) //部门
  }

  // 事业群
  const onGroupList = () => {
    api.getSelectGroupList().then(data => {
      setGroupList(data.list)
    })
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        currentPage = 1
        history.replace(match.path)
        filter = {
          ...filter,
          ...vals,
          payDate: vals.payDate ? vals.payDate.format('YYYYMM') : '',
        }
        getList()
      }
    })
  }

  // 重置
  const onReset = () => {
    resetFields()
    filter = {
      companyId: filter.companyId,
      staffNo: '',
      staffName: '',
      identityCardNo: '',
      payDate: '',
      groupId: '',
      departmentId: ''
    }
    currentPage = 1
    history.replace(match.path)
    getList(currentPage)
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getList()
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const onShowImportDialog = () => {
    ImportRef.current.onRemoved()
    setShowImport(true)
  }

  // 导入确定
  const onConfirmImport = (fileList) => {
    if (fileList[0].status !== 'removed') {
      api.getAccumulateImportExcel({
        path: fileList[0].url
      }).then(res => {
        getList()
        message.success('导入成功')
        ImportRef.current.onLoading()
        setShowImport(false)
      }).catch(res => {
        // console.log('导入', res)
        ImportRef.current.onLoading()
      })
    } else {
      message.error('请导入模板')
    }
  }

  const onShowExportDialog = () => {
    setShowExport(true)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setSelectedCount(selectedRowKeys.length)
      setSelectedRowKeys(selectedRowKeys)
      selectedIds = selectedRowKeys
    },
    selectedRowKeys: selectedRowKeys
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }
  
  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={6}>
                <FormItem label="缴纳年月" labelCol={{ span: 8 }}>
                  {getFieldDecorator('payDate')(<MonthPicker placeholder="请选择" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="员工编号" labelCol={{ span: 8 }}>
                  {getFieldDecorator('staffNo')(<Input placeholder="请输入员工编号" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="真实姓名" labelCol={{ span: 8 }}>
                  {getFieldDecorator('staffName')(<Input placeholder="请输入员工真实姓名" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="身份证号" labelCol={{ span: 8 }}>
                  {getFieldDecorator('identityCardNo')(<Input placeholder="请输入身份证号" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={6}>
                <FormItem label="事业群" labelCol={{ span: 8 }}>
                  {getFieldDecorator('groupId')(
                    <Select 
                      placeholder="全部" 
                      dropdownMatchSelectWidth={false}
                      showSearch
                      optionFilterProp="children"
                    >
                      {groupList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="部门" labelCol={{ span: 8 }}>
                  {getFieldDecorator('departmentId')(
                    <Select 
                      placeholder="全部"
                      dropdownMatchSelectWidth={false}
                      onDropdownVisibleChange={bool => bool && onDropdownVisible()}
                      showSearch
                      optionFilterProp="children"
                    >
                      {batchDepartment.map(v =>{
                          return !filter.companyId.length ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                      })}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{ cursor: 'pointer', color: '#1890ff' }}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>
        <div className="recruit-search-btns">
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
        </div>
      </Form>
      <div className="line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="operate">
          <Button type="primary" icon="download" style={{ marginLeft: 15 }} onClick={onShowImportDialog}>导入</Button>
          <Button type="primary" icon="upload" style={{ marginLeft: 15 }} onClick={onShowExportDialog}>导出</Button>
        </Auth>
      </div>
      <Alert className="corp-count" message={
        <>
          <span>已选择 <span style={{ color: '#168FFF' }}>{selectedCount}</span> 项</span>
          {/* <a style={{ marginLeft: 10, color: '#168FFF'}}>反选</a>
          <a style={{ marginLeft: 10, color: '#168FFF'}}>全选</a> */}
          <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
        </>
      } type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 2000 }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="员工编号" dataIndex="staffNo" fixed="left" width={150} />
        <Column title="员工真实姓名" dataIndex="trueName" fixed="left" width={120} />
        <Column title="公司用名" dataIndex="staffName" />
        <Column title="缴纳年月" dataIndex="payDate" />
        <Column title="直属上级" dataIndex="leaderName" />
        <Column title="事业群" dataIndex="groupName" />
        <Column title="部门" dataIndex="departName" />
        <Column title="身份证号" dataIndex="personalId" />
        <Column title="公积金户口性质" dataIndex="attribute" />
        <Column title="公积金个人编号" dataIndex="cardId" />
        <Column title="职级" dataIndex="jobPosition" />
        <Column title="级别" dataIndex="jobStation" />
        <Column title="入职时间" dataIndex="entryDate" />
        <Column title="性别" dataIndex="sexName" />
        <Column title="学历" dataIndex="educationLevel" />
        <Column title="基数" dataIndex="basementNum" />
        <Column title="单位缴纳" dataIndex="stfPayment" />
        <Column title="个人缴纳" dataIndex="personPayment" />
        <Column title="总额" dataIndex="totalPayment" />
      </Table>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getSocialAccumulateExportTpl"
        fieldsUrl="getSocialAccumulateTpl"
        saveUrl="getAccumulateSaveTpls"
        exportUrl="getSocialAccumulateExport"
        method="getlist"
        parame={{ ExportType: 'socialaccumulate.getlist', id: selectedIds, ...filter }}
        cRef={productsRef}
      />
      <Import
        onSearch={onConfirmImport}
        visibleFile={showImport}
        onCloseImport={() => setShowImport(false)}
        ImportTemplate="/api/File/DownTemplate?url=/templates/socialAccumulate.xlsx&name=公积金管理导入模板"
        ImportRef={ImportRef}
      />
    </>
  )
}

export default Form.create()(Funds)
