/**
 * 模块名称: 供应商管理 => 合同盖章申请
 * @author liujingxue@372163.com
 */

 import React from 'react'
 import { Switch, Route } from 'react-router-dom'
 import SupplierContractSeal from './SupplierContractSeal'
 import Apply from './Apply'
 import Details from './Details'
 
 const SupplierContractSealIndex = ({ match, history }) => {
 
     return (
         <Switch>
             <Route path={match.path + '/apply'} component={Apply} />
             <Route path={match.path + '/details'} component={Details} />
             <Route component={SupplierContractSeal} />
         </Switch>
     )
 }
 
 export default SupplierContractSealIndex