/**
 * 模块名称: 讲师评价
 * @author liujingxue@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Drawer,
  Input,
  Button,
  Select,
  Icon,
  Popconfirm,
  message,
  Spin,
  Tooltip
} from 'antd'
import Export from '@/components/Export'
import Import from '@/components/Import'
import Auth from '@/components/AuthMiddleware'
import EvaluateEdit from './module/EvaluateEdit'
import { parseSearch } from '@/utils'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option

let pageSize = 10
let currentPage = 1
let searchParamsTmp = {
  limit: pageSize,
  page: currentPage
}

let selectedIds = ''

let editId = '' //eslint-disable-line

const Evaluate = props => {
  const ImportRef = useRef()
  const productsRef = useRef()
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const { history, match } = props

  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)

  const [visibleFile, setVisibleFile] = useState(false)
  const [visible, setVisible] = useState(false)
  const [formLoading, setformLoading] = useState(false)
  const [showExport, setShowExport] = useState(false)
  const [catName, setCatName] = useState([])
  const [rowData, setRowData] = useState({})
  const intervalRef = useRef()

  const [authList, setAuthList] = useState([])//按钮权限
  const search = parseSearch(props.location.search)


  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage
    }
    getTrainEvaluationList()
    api.getTrainrecordCategoryList().then(data => setCatName(data))
    getPageAuth()
    return () => clearTimeout(intervalRef.current)

  }, [])

  //按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then(list => {
      setAuthList(list)
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  //获取培训讲师列表
  const getTrainEvaluationList = () => {
    let id = setTimeout(() => {
      api.getTrainEvaluationList(searchParamsTmp).then(res => {
        //console.log(res)
        setList(res.list)
        setCount(res.count)
        setLoading(false)
      }).catch(() => { setLoading(false) })
    }, 500)
    intervalRef.current = id
  }

  //重置
  const onReset = () => {
    resetFields()
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage
    }
    getTrainEvaluationList()
  }

  //提交
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (vals !== undefined) {
        if (vals.classname !== undefined) searchParamsTmp.classname = vals.classname
        if (vals.staffName !== undefined) searchParamsTmp.staffName = vals.staffName
        if (vals.categoryId !== undefined) searchParamsTmp.categoryId = vals.categoryId
      }
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getTrainEvaluationList()
      }
    })
  }

  //点击分页
  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    searchParamsTmp.limit = pageSize
    searchParamsTmp.page = currentPage
    getTrainEvaluationList()
  }

  const onEdit = (data) => {
    setVisible(true)
    //console.log(data)
    setRowData(data)
    editId = data.id
    setFieldsValue({
      name: data.name,
      joblevel: data.joblevel,
      jobStation: data.jobStation
    })
  }

  const onDelete = (id) => {
    //console.log(id)
    api.getTrainEvaluationDelete({ id: id }).then(() => {
      //console.log('删除成功')
      getTrainEvaluationList()
    })
  }

  const onSearch = (fileList) => {
    if (fileList.length > 0) {
      if (fileList[0].status !== "removed") {
        api.getTrainEvaluationImportExcel({
          path: fileList[0].url
        }).then(data => {
          message.success('导入成功，请到导入列表查看。')
          ImportRef.current.onLoading()
          setVisibleFile(false)
        }).catch(data => {
          ImportRef.current.onLoading()
        })
      } else {
        message.error('请导入模板')
      }
    } else {
      message.error('请导入模板')
    }
  }

  //导入
  const onImportFile = () => {
    // console.log('导入')
    setVisibleFile(true)
    // onRemoved就是子组件暴露给父组件的方法
    ImportRef.current.onRemoved()
  }

  const onCloseImport = () => {
    setVisibleFile(false)
  }

  //导出
  const onExportFile = () => {
    setShowExport(true)
  }

  const rowSelection = {//eslint-disable-line
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(selectedRowKeys, selectedRows)
      selectedIds = selectedRowKeys
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  }

  return (
    <>
      <div className="search-doc-wrap search-office-wrap">
        <div className="evaluate-title" >其他选项：</div>
        <Form onSubmit={formSubmit}  {...formItemLayout} style={{ marginLeft: '70px' }}>
          <div className="form-box">
            <FormItem label="课程名称" >
              {getFieldDecorator('classname')(<Input placeholder="请输入课程名称" style={{ width: 150 }} />)}
            </FormItem>
            <FormItem label="讲师">
              {getFieldDecorator('staffName')(<Input placeholder="请输入员工姓名" style={{ width: 150 }} />)}
            </FormItem>
            <FormItem label="课程类别">
              {getFieldDecorator('categoryId', {
              })(<Select placeholder="全部" style={{ width: 150 }} dropdownMatchSelectWidth={false}>
                {
                  catName.map((v, i) => {
                    return <Option value={v.id} key={i}>{v.catName}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem className="evaluate-btns" label=" " colon={false}>
              <Button onClick={onReset}>重置</Button>
              <Button type="primary" htmlType="submit">筛选</Button>
            </FormItem>
          </div>
        </Form>
      </div>
      <div className="line"></div>
      <div className="evaluate-button">
        <Auth auths={authList} code="import">
          <Button type="primary" icon="download" onClick={onImportFile} style={{ marginLeft: 15, marginBottom: 15 }}>导入</Button>
          <Import
            onSearch={onSearch}
            visibleFile={visibleFile}
            onCloseImport={onCloseImport}
            ImportTemplate="/api/File/DownTemplate?url=/templates/evaluation.xlsx&name=讲师评价导入模板"
            ImportRef={ImportRef}
          />
        </Auth>
        <Auth auths={authList} code="export">
          <Button type="primary" icon="upload" onClick={onExportFile} style={{ marginLeft: 15, marginBottom: 15 }}>导出</Button>
        </Auth>
        <Export
          show={showExport}
          onCancel={() => {
            setShowExport(false)
            productsRef.current.changeVal()
          }}
          tplUrl="getTrainEvaluationExportTpl"
          fieldsUrl="getTrainEvaluationTplItems"
          saveUrl="getTrainEvaluationSaveExportTpls"
          exportUrl="exportTrainEvaluation"
          method="getlist"
          parame={{ ExportType: 'trainevaluation.getlist', id: selectedIds, ...searchParamsTmp }}
          cRef={productsRef}
        />
      </div>
      <div>
        <Table
          size="middle"
          dataSource={list}
          rowKey="id"
          className="search-table"
          loading={loading}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          // scroll={{ x: 1975 }}
          scroll={{x: 'max-content'}}
          onChange={onChangeTable}
        >
          <Column title="序号" dataIndex="id" fixed="left" />
          <Column title="培训课程/项目" dataIndex="classname" fixed="left" />
          <Column title="人数" dataIndex="peoplenum" fixed="left" />
          <Column title="课程类别" dataIndex="classCategoryName" fixed="left" />
          <Column title="二级类别" dataIndex="secondcategory" fixed="left" />
          <Column title="讲师" dataIndex="stfname" fixed="left" />
          <Column title="讲授部门" dataIndex="departName" fixed="left" />
          <Column title="实施时间" dataIndex="dodate" />
          <Column title="课程满意度" dataIndex="okdegree" />
          <Column title="难易程度" dataIndex="easydegree" />
          <Column title="内容编排科学" dataIndex="isscience" />
          <Column title="内容适用" dataIndex="isapply" />
          <Column title="互动性" dataIndex="interactive" />
          <Column title="讲师满意度" dataIndex="lecturer" />
          <Column title="讲师亲和力" dataIndex="affinity" />
          <Column title="授课连贯有逻辑" dataIndex="logical" />
          <Column title="课程时间把握" dataIndex="grasp" />
          <Column title="课堂气氛" dataIndex="atmosphere" />
          <Column title="课程评分" dataIndex="classCore" />
          <Column title="改进意见" dataIndex="idea" />
          <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
            <>
              <Auth auths={text.operateAuth} code="edit">
                <Tooltip title="编辑" placement="bottom">
                  <Icon className="operate-icon" type="edit" onClick={() => onEdit(record)} style={{ color: '#1890ff' }} />
                </Tooltip>
              </Auth>
              {' '}
              <Auth auths={text.operateAuth} code="delete">
                <Tooltip title="删除" placement="bottom">
                  <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                    <Icon className="operate-icon" type="delete" style={{ color: 'red' }} />
                  </Popconfirm>
                </Tooltip>
              </Auth>
            </>
          )} />
        </Table>
        <Drawer
          title="编辑讲师评价"
          width={700}
          onClose={() => setVisible(false)}
          visible={visible}
          destroyOnClose={true}
        >
          <Spin spinning={formLoading}>
            <EvaluateEdit
              setEditVisible={setVisible}
              getTrainrecordList={getTrainEvaluationList}
              setEditLoading={setformLoading}
              rowData={rowData}
            />
          </Spin>
        </Drawer>
      </div>
    </>
  )
}

export default Form.create()(Evaluate)