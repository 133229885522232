/**
 * 模块名称: 待支付列表 => 内部往来款订单列表页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import {
	Table,
	Form,
	Input,
	DatePicker,
	Select,
	message,
	Row,
	Col,
	Modal,
	Button
} from 'antd'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpQuanQian'
import BtnGroup from '@/components/BtnGroup'
import CryptoJS from 'crypto-js'
import ClosingModal from './module/ClosingModal'
import PayFailedModal from './module/PayFailedModal'
import '../assets/style.scss'

const { Column } = Table
const FormItem = Form.Item
const { Option } = Select
let pageSize = 10
let currentPage = 1
let apiOperateTimer = true

const { RangePicker } = DatePicker

//所属公司筛选
let filter = {
	companyId: 1
}

//筛选初始值
let exporfilter = {
	id: '',
	fromAccountId: '',
	fromCompanyId: '',
	toAccountId: '',
	toCompanyId: '',
	status: 3,
	applyStafName: '',
	applyStart: '',
	applyEnd: '',
	auditStart: '',
	auditEnd: '',
	// type: 1
}

const busStatusList = [{
	id: 3,
	name: '待支付'
},{
	id: 5,
	name: '终止付款'
}]

const InternalAccount = (props) => {
	const { match, location, history } = props
	const { getFieldDecorator, resetFields, setFieldsValue, validateFields } = props.form
	const [loading, setLoading] = useState(false)
	const [list, setList] = useState([])
	const [count, setCount] = useState(0)
	const [visibleRevise, setVisibleRevise] = useState(false)//操作=>修改
	const [visibleClosing, setVisibleClosing] = useState(false)//操作=>支付
	const [visiblePayFailed, setVisiblePayFailed] = useState(false)//操作=>终止付款
	const [revise, setRevise] = useState({})//操作=>修改内容
	const [closing, setClosing] = useState({})//操作=>支付内容
	const [payFailed, setPayFailed] = useState({})//操作=>终止付款内容
	const [costClassList, setCostClassList] = useState([])
	const [payWayList, setPayWayList] = useState([])
	const [authList, setAuthList] = useState([])

	const search = parseSearch(location.search)

	useEffect(() => {
		filter = {
			companyId: 1
		}
		if (!history.location.state) {
			currentPage = search.page ? +search.page : 1
			pageSize = search.limit ? +search.limit : 10
			exporfilter = {
				id: '',
				fromAccountId: '',
				fromCompanyId: '',
				toAccountId: '',
				toCompanyId: '',
				status: 3,
				applyStafName: '',
				applyStart: '',
				applyEnd: '',
				auditStart: '',
				auditEnd: '',
			}
		} else {
			currentPage = search.page ? +search.page : currentPage
			pageSize = search.limit ? +search.limit : pageSize
			setTimeout(() => {
				setFieldsValue({
					id: exporfilter.id !== '' ? exporfilter.id : undefined,
					fromAccountId: exporfilter.fromAccountId !== '' ? exporfilter.fromAccountId : undefined,
					fromCompanyId: exporfilter.fromCompanyId !== '' ? exporfilter.fromCompanyId : undefined,
					toAccountId: exporfilter.toAccountId !== '' ? exporfilter.toAccountId : undefined,
					toCompanyId: exporfilter.toCompanyId !== '' ? exporfilter.toCompanyId : undefined,
					status: exporfilter.status !== '' ? exporfilter.status : undefined,
					applyStafName: exporfilter.applyStafName !== '' ? exporfilter.applyStafName : undefined,
					apply: exporfilter.applyStart !== '' ? [moment(exporfilter.applyStart), moment(exporfilter.applyEnd)] : undefined,
					audit: exporfilter.auditStart !== '' ? [moment(exporfilter.auditStart), moment(exporfilter.auditEnd)] : undefined
				})
			}, 1000)
		}
		apiOperateTimer = true
		onCommon()
		getInnerFundTransferWaitCloseList()
		api.getPageAuth().then(list => setAuthList(list))
	}, [])

	//获取列表
	const getInnerFundTransferWaitCloseList = () => {
		if (apiOperateTimer) {
			setLoading(true)
			let params = {
				limit: pageSize,
				page: currentPage,
				companyId: filter.companyId,
				status: 3,
				// type: 1
			}
			api.getInnerFundTransferWaitCloseList(params).then(data => {
				setList(data.list)
				setCount(data.count)
				setLoading(false)
			}).catch(() => setLoading(false))
			apiOperateTimer = false
			let timer = setTimeout(() => {
				apiOperateTimer = true
				clearTimeout(timer)
			}, 1000)
		} else {
			return message.info('请不要频繁操作')
		}
	}

	//获取公共接口
	const onCommon = () => {
		api.getInnerFundTransferAccountList({ limit: global.paramsLimit }).then(res => {
			console.log(res)
			setCostClassList(res.list)
		}).catch(err => console.log(err))//转出传入账户
		api.getAllCompanyList({ limit: global.paramsLimit }).then(data => setPayWayList(data.list)).catch(err => console.log(err))//获取公司列表
	}

	//筛选时调列表接口
	const getEmployeeList = () => {
		setLoading(true)
		api.getInnerFundTransferWaitCloseList({
			limit: pageSize,
			page: currentPage,
			companyId: filter.companyId,
			id: exporfilter.id,
			fromAccountId: exporfilter.fromAccountId,
			fromCompanyId: exporfilter.fromCompanyId,
			toAccountId: exporfilter.toAccountId,
			toCompanyId: exporfilter.toCompanyId,
			status: exporfilter.status,
			applyStafName: exporfilter.applyStafName,
			applyStart: exporfilter.applyStart,
			applyEnd: exporfilter.applyEnd,
			auditStart: exporfilter.auditStart,
			auditEnd: exporfilter.auditEnd,
			// type: 1
		}).then(data => {
			setList(data.list)
			setCount(data.count)
			setLoading(false)
		}).catch(() => setLoading(false))
	}

	//点击筛选提交表单
	const formSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			//const values = Object.values(vals)
			//console.log(values)
			if (!err) {
				// console.log(vals)
				currentPage = 1
				history.replace(match.path)
				exporfilter.id = vals.id ? vals.id : ''
				exporfilter.fromAccountId = vals.fromAccountId ? vals.fromAccountId : ''
				exporfilter.fromCompanyId = vals.fromCompanyId ? vals.fromCompanyId : ''
				exporfilter.toAccountId = vals.toAccountId ? vals.toAccountId : ''
				exporfilter.toCompanyId = vals.toCompanyId ? vals.toCompanyId : ''
				exporfilter.status = vals.status ? vals.status : ''
				exporfilter.applyStafName = vals.applyStafName ? vals.applyStafName : ''
				exporfilter.applyStart = vals.apply ? vals.apply[0].format('YYYY-MM-DD') : ''
				exporfilter.applyEnd = vals.apply ? vals.apply[1].format('YYYY-MM-DD') : ''
				exporfilter.auditStart = vals.audit ? vals.audit[0].format('YYYY-MM-DD') : ''
				exporfilter.auditEnd = vals.audit ? vals.audit[1].format('YYYY-MM-DD') : ''
				getEmployeeList()
			}
		})
	}

	const onReset = (e) => {
		e.preventDefault()
		exporfilter = {
			id: '',
			fromAccountId: '',
			fromCompanyId: '',
			toAccountId: '',
			toCompanyId: '',
			status: 3,
			applyStafName: '',
			applyStart: '',
			applyEnd: '',
			auditStart: '',
			auditEnd: '',
		}
		resetFields()
		currentPage = 1
		history.replace(match.path)
		getInnerFundTransferWaitCloseList()
	}

	//筛选
	const onCorpFilter = (data) => {
		filter.companyId = data.map(item => item.id).join(',')
		history.replace(match.path)
		currentPage = 1
		setFieldsValue({
			status: '3'
		})
		getInnerFundTransferWaitCloseList()
	}

	const onChangeTable = (pagination) => {
		currentPage = pagination.current
		pageSize = pagination.pageSize
		history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
		getEmployeeList()
	}

	//操作 => 支付
	const onClosing = (data) => {
		setClosing(data)
		setVisibleClosing(true)
	}

	//操作=> 终止付款
	const onPayFailed = (data) => {
		setPayFailed(data)
		setVisiblePayFailed(true)
	}

	// 预览/下载凭证
	const onVoucherAction = (record, download = false) => {
		api.getHost({}).then((res) => {
		  if (download) {
			window.location = `${res.url}/api/File/downPdf?id=${record.id}&type=${'InnerFundTransferProof'}`;
		  } else {
			window.open(
				`${res.url}/api/File/prevPdf?id=${record.id}&type=${'InnerFundTransferProof'}`,
				'_blank'
			);
		  }
		});
	};

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		}
	}

	return (
		<>
			{/* <CorpFilter onChange={onCorpFilter} /> */}
			{/* <CorpFilter supra={false} defaultValue={filter.companyId == '' ? 1 : filter.companyId} onChange={onCorpFilter} /> */}
			<div className="comm-stay-list">
				<Form onSubmit={formSubmit} {...formItemLayout}>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="业务编号">
								{getFieldDecorator('id')(<Input allowClear={true} placeholder="请输入业务编号" />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="转出账户">
								{getFieldDecorator('fromAccountId')(
									<Select
										placeholder="全部"
										allowClear={true}
										dropdownMatchSelectWidth={false}
										showSearch={true}
										optionFilterProp="children"
									>
										{
											costClassList.map(v => {
												return <Option key={v.id}>{v.accountName}</Option>
											})
										}
									</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="转出账户主体">
								{getFieldDecorator('fromCompanyId')(
									<Select
										placeholder="全部"
										allowClear={true}
										dropdownMatchSelectWidth={false}
										showSearch={true}
										optionFilterProp="children"
									>
										{
											payWayList.map(v => {
												return <Option key={v.id}>{v.name}</Option>
											})
										}
									</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="转入账户">
								{getFieldDecorator('toAccountId')(
									<Select
										placeholder="全部"
										allowClear={true}
										dropdownMatchSelectWidth={false}
										showSearch={true}
										optionFilterProp="children"
									>
										{
											costClassList.map(v => {
												return <Option key={v.id}>{v.accountName}</Option>
											})
										}
									</Select>)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="转入账户主体">
								{getFieldDecorator('toCompanyId')(
									<Select
										placeholder="全部"
										allowClear={true}
										dropdownMatchSelectWidth={false}
										showSearch={true}
										optionFilterProp="children"
									>
										{
											payWayList.map(v => {
												return <Option key={v.id}>{v.name}</Option>
											})
										}
									</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="状态">
								{getFieldDecorator('status', { initialValue: '3' })(
									<Select
										placeholder="全部"
										allowClear={true}
										dropdownMatchSelectWidth={false}
										showSearch={true}
										optionFilterProp="children"
									>
										{
											busStatusList.map(v => {
												return <Option key={v.id}>{v.name}</Option>
											})
										}
									</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="申请人">
								{getFieldDecorator('applyStafName')(<Input allowClear={true} placeholder="请输入申请人" />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="申请时间">
								{getFieldDecorator('apply')(<RangePicker allowClear={false} />)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="审批通过时间">
								{getFieldDecorator('audit')(<RangePicker allowClear={false} />)}
							</FormItem>
						</Col>
					</Row>
					<div className="comm-stay-btn" >
						<BtnGroup cancelName="重置" confirmName="查询" onCancel={onReset} />
					</div>
				</Form>
			</div>
			<div className="line"></div>
			<Table
				size="small"
				dataSource={list}
				rowKey="id"
				loading={loading}
				pagination={{
					pageSize,
					total: count,
					current: currentPage,
					showQuickJumper: true,
					showSizeChanger: true,
					pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
				}}
				scroll={{ x: 1600 }}
				onChange={onChangeTable}
			>
				<Column title="业务编号" dataIndex="id" fixed="left" />
				<Column title="转出账户" dataIndex="fromAccountName" />
				<Column title="转出账户主体" dataIndex="fromCompanyName" />
				<Column title="转入账户" dataIndex="toAccountName" />
				<Column title="转入账户主体" dataIndex="toCompanyName" />
				<Column title="转款金额" dataIndex="money" />
				<Column title="状态" dataIndex="statusName" />
				<Column title="申请人" dataIndex="applyStafName" />
				<Column title="申请时间" dataIndex="createTime" />
				<Column title="审批通过时间" dataIndex="auditTime" />
				<Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
					<div>
						{record.hasDetail && <Link target='_blank' to={`/corpAccount/InternalAccount/details?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`} style={{ cursor: 'pointer' }}>详情</Link>}
						{record.hasClose && <div><a style={{ cursor: 'pointer' }} onClick={() => onClosing(record)}>支付</a></div>}
						{record.hasFail && <div><a style={{ cursor: 'pointer' }} onClick={() => onPayFailed(record)}>终止付款</a></div>}
						{record.hasPrev && <div><a style={{ cursor: 'pointer' }} onClick={() => onVoucherAction(record)} >凭证预览</a></div>}
						{record.hasDown && <div><a style={{ cursor: 'pointer' }} onClick={() => onVoucherAction(record, true)} >下载凭证 </a></div>}
					</div>
				)} />
			</Table>
			<Modal
				title="支付确认"
				visible={visibleClosing}
				onCancel={() => setVisibleClosing(false)}
				bodyStyle={{
					height: '680px'
				}}
				closable={false}
				destroyOnClose={true}
				maskClosable={false}
				footer={null}
			>
				<ClosingModal closing={closing} setVisibleClosing={setVisibleClosing} getEmployeeList={getEmployeeList} />
			</Modal>
			<Modal
				title="终止付款确认"
				visible={visiblePayFailed}
				onCancel={() => setVisiblePayFailed(false)}
				bodyStyle={{
					height: '530px'
				}}
				closable={false}
				destroyOnClose={true}
				maskClosable={false}
				footer={null}
			>
				<PayFailedModal payFailed={payFailed} setVisiblePayFailed={setVisiblePayFailed} getEmployeeList={getEmployeeList} />
			</Modal>
		</>
	)
}

export default Form.create()(InternalAccount)