/**
 * 模块名称: 已结算列表-费用订单
 * @author jiashaowang@372163.com
 */

import React, { useState, useEffect, useRef } from 'react';
import api from '@/api';
import { Link } from 'react-router-dom';
import { Table, Button, Alert } from 'antd';
import CorpQuanQian from '@/components/CorpQuanQian';
import FiltersForm from '@/components/FiltersForm';
import Export from '@/components/Export';
import { parseSearch } from '@/utils';
import Auth from '@/components/AuthMiddleware';
import CryptoJS from 'crypto-js';

// 导出用：筛选条件初始值
let exporfilter = {
  companyId: [],
};

const CostDone = (props) => {
  const { history, match, location } = props;
  const search = parseSearch(location.search);

  const initParams = {
    companyId: 1,
    page: search.page || 1,
    limit: search.limit || 10,
  };

  const [states, setStates] = useState({
    dataSource: [], // 列表
    loading: true,
    total: 0, // 总条数
    filter: {
      costClass: [], // 筛选：费用类型
      payWay: [], // 筛选：付款方式
    },
    // 筛选条件：列表接口参数
    params: initParams,
  });
  const { dataSource, filter, loading, total, params } = states;

  const productsRef = useRef();

  const [showExport, setShowExport] = useState(false); // 显示导出浮层

  const [authList, setAuthList] = useState([]); // 按钮权限

  useEffect(() => {
    getList();
    const { page, limit, ...others } = params;
    exporfilter = { ...others };
  }, [params]);

  useEffect(() => {
    getPageAuth();
  }, []);

  // 获取列表
  const getList = () => {
    api
      .getOfficeCostManagementForPayFinishedList(params)
      .then((res) => {
        setStates((current) => ({
          ...current,
          loading: false,
          dataSource: res.list,
          total: res.count,
        }));
      })
      .catch(() => {
        setStates((current) => ({
          ...current,
          loading: false,
        }));
      });
  };

  // 获取按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then((list) => {
      setAuthList(list);
    });
  };

  // 获取费用类别
  const getOfficeCostManagementCostClass = () => {
    api.getOfficeCostManagementCostClass().then((data) => {
      setStates((current) => ({
        ...current,
        filter: {
          ...filter,
          costClass: data,
        },
      }));
    });
  };

  // 获取付款方式
  const getOfficeCostManagementPayWay = () => {
    api.getOfficeCostManagementPayWay().then((data) => {
      setStates((current) => ({
        ...current,
        filter: {
          ...filter,
          payWay: data,
        },
      }));
    });
  };

  // 点击选择所属公司
  const onChangeCorp = (data) => {
    setStates((current) => ({
      ...current,
      loading: true,
      params: {
        ...initParams,
        companyId: data.map((item) => item.id),
      },
    }));
    history.replace(match.path);
  };

  // 分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    const { current: page, pageSize: limit } = pagination;
    setStates((current) => ({
      ...current,
      loading: true,
      params: {
        ...params,
        page: page,
        limit: limit,
      },
    }));
    history.replace(match.path + '?page=' + page + '&limit=' + limit);
  };

  // 获取退款类型
  const getRefundOrderType = () => {
    api.getRefundOrderType().then((data) => {
      setStates((current) => ({
        ...current,
        filter: {
          ...filter,
          type: data,
        },
      }));
    });
  };

  // 点击筛选
  const onSubmit = (values) => {
    initParams.page = 1;
    initParams.limit = 10;
    setStates((current) => ({
      ...current,
      loading: true,
      params: {
        ...initParams,
        companyId: params.companyId,
        ...values,
      },
    }));
    history.replace(match.path);
  };

  // 预览/下载凭证
  const onVoucherAction = (record, download = false) => {
    api.getHost({}).then((res) => {
      if (download) {
        window.location = `${res.url}/api/File/Downloads?url=${res.url}/api/OfficeCostManagement/Download?id=${record.id}&name=${record.no}.pdf`;
      } else {
        window.open(
          `${res.url}/api/OfficeCostManagement/Preview?id=${record.id}`,
          '_blank'
        );
      }
    });
  };

  // 批量下载凭证
  const onMultiDownload = () => {
    api.getHost({}).then((res) => {
      const params = new URLSearchParams();
      for (let key in exporfilter) {
        if (exporfilter[key] && exporfilter[key] !== '') {
          params.set(key, exporfilter[key]);
        }
      }
      window.location = `${
        res.url
      }/api/fortune/refundOrder/multiDownload?${params.toString()}`;
    });
  };

  const columns = [
    {
      title: '订单编号',
      dataIndex: 'no',
      fixed: 'left',
      width: 160,
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入订单编号',
      },
    },
    {
      title: '业务编号',
      dataIndex: 'id',
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入业务编号',
      },
      render: (text, record) => {
        return (
          <Link
            target='_blank'
            to={`/office/costManagement/details?id=${
              record.id
            }&time=${+new Date()}&sign=${CryptoJS.MD5(
              record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + +new Date()
            ).toString()}`}
          >
            {text}
          </Link>
        );
      },
    },
    {
      title: '费用类型',
      dataIndex: 'costClass',
      valueType: 'Select',
      fieldProps: {
        placeholder: '全部',
        showSearch: true,
        options: filter.costClass.map((item) => ({
          label: item.name,
          value: item.id,
        })),
        onDropdownVisibleChange: (open) => {
          if (open) {
            getOfficeCostManagementCostClass();
          }
        },
      },
      render: (text, record) => record.costClassName,
    },
    { title: '公司主体', dataIndex: 'companyName' },
    {
      title: '付款方式',
      dataIndex: 'payWay',
      valueType: 'Select',
      fieldProps: {
        placeholder: '全部',
        showSearch: true,
        options: filter.payWay.map((item) => ({
          label: item.name,
          value: item.id,
        })),
        onDropdownVisibleChange: (open) => {
          if (open) {
            getOfficeCostManagementPayWay();
          }
        },
      },
      render: (text, record) => record.payWayName,
    },

    { title: '收款单位', dataIndex: 'payee' },
    { title: '开户行', dataIndex: 'bankName' },
    { title: '银行账号', dataIndex: 'bankAccount' },
    {
      title: '付款金额',
      dataIndex: 'money',
      render: (text, record) => (+record.money).toFixed(2),
    },
    {
      title: '申请人',
      dataIndex: 'applyStaffName',
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入申请人',
      },
    },
    {
      title: '申请时间',
      dataIndex: 'addTime',
      valueType: 'RangePicker',
      search: {
        transform: (value) => {
          return {
            sTime: value[0].format('YYYY-MM-DD'),
            eTime: value[1].format('YYYY-MM-DD'),
          };
        },
      },
    },
    {
      title: '审批通过时间',
      dataIndex: 'passTime',
      valueType: 'RangePicker',
      search: {
        transform: (value) => {
          return {
            sPassTime: value[0].format('YYYY-MM-DD'),
            ePassTime: value[1].format('YYYY-MM-DD'),
          };
        },
      },
    },
    {
      title: '付款完成时间',
      dataIndex: 'payFinishedTime',
      valueType: 'RangePicker',
      search: {
        transform: (value) => {
          return {
            sPayFinishedTime: value[0].format('YYYY-MM-DD'),
            ePayFinishedTime: value[1].format('YYYY-MM-DD'),
          };
        },
      },
    },
    {
      title: '操作',
      key: 'oprate',
      fixed: 'right',
      width: 120,
      render: (text, record) => {
        return (
          <>
            {record.previewFlag && (
              <Button
                block
                type='link'
                size='small'
                onClick={() => onVoucherAction(record)}
              >
                凭证预览
              </Button>
            )}

            {record.downloadFlag && (
              <Button
                block
                type='link'
                size='small'
                onClick={() => onVoucherAction(record, true)}
              >
                下载凭证
              </Button>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <CorpQuanQian
        supra={false}
        defaultValue={params.companyId}
        onChange={onChangeCorp}
        part='feiyong'
      />
      <FiltersForm columns={columns} onSubmit={onSubmit} onReset={onSubmit} />
      <div className='line'></div>
      <div className='add-wrap'>
        <Auth auths={authList} code='export'>
          <Button
            type='primary'
            title='导出'
            onClick={() => setShowExport(true)}
            style={{ marginRight: 10 }}
          >
            导出
          </Button>
        </Auth>
        {/* <Auth auths={authList} code='operate'>
          <Button type='primary' title='批量下载凭证' onClick={onMultiDownload}>
            批量下载凭证
          </Button>
        </Auth> */}
      </div>
      <Alert
        className='corp-count'
        message={`总计：${total}条数据`}
        type='info'
        showIcon
      />
      <Table
        size='small'
        columns={columns}
        dataSource={dataSource}
        rowKey='id'
        loading={loading}
        className='askforleave-table'
        pagination={{
          pageSize: parseInt(params.limit),
          total: total,
          current: parseInt(params.page),
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        scroll={{ x: 2500 }}
      ></Table>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false);
          productsRef.current.changeVal();
        }}
        tplUrl='getOfficeCostManagementExportTpl'
        fieldsUrl='getOfficeCostManagementTplItems'
        saveUrl='saveOfficeCostManagementExportTpls'
        exportUrl='exportOfficeCostManagement'
        method='export'
        parame={{
          ExportType: 'officecostmanagement.export',
          ...exporfilter,
        }}
        cRef={productsRef}
      />
    </>
  );
};

export default CostDone;
