/**
 * 模块名称: 待支付列表 => 退款订单列表页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import {
  Table,
  Form,
  Input,
  DatePicker,
  Select,
  message,
  Row,
  Col,
  Modal,
  Button
} from 'antd'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpQuanQian'
import BtnGroup from '@/components/BtnGroup'
import CryptoJS from 'crypto-js'
import ReviseModal from './module/ReviseModal'
import ClosingModal from './module/ClosingModal'
import PayFailedModal from './module/PayFailedModal'
import '../assets/style.scss'

const { Column } = Table
const FormItem = Form.Item
const { Option } = Select
let pageSize = 10
let currentPage = 1
let apiOperateTimer = true

const { RangePicker } = DatePicker

//所属公司筛选
let filter = {
  companyId: ''
}

//筛选初始值
let exporfilter = {
  id: '',
  refundType: '',
  client: '',
  clientId: '',
  project: '',
  status: 1,
  applyUserName: '',
  applyStartTime: '',
  applyEndTime: '',
  acceptedStartTime: '',
  acceptedEndTime: '',
  type: 1
}

const RefundStay = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, resetFields, setFieldsValue, validateFields } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [visibleRevise, setVisibleRevise] = useState(false)//操作=>修改
  const [visibleClosing, setVisibleClosing] = useState(false)//操作=>支付
  const [visiblePayFailed, setVisiblePayFailed] = useState(false)//操作=>终止付款
  const [revise, setRevise] = useState({})//操作=>修改内容
  const [closing, setClosing] = useState({})//操作=>支付内容
  const [payFailed, setPayFailed] = useState({})//操作=>终止付款内容
  const [refundOrderType, setRefundOrderType] = useState([])//退款订单类型
  const [refundOrderStatus, setRefundOrderStatus] = useState([])//退款订单状态
  const [projectList, setProjectList] = useState([])
  const [authList, setAuthList] = useState([])

  const search = parseSearch(location.search)

  useEffect(() => {
    filter = {
      companyId: ''
    }
    if (!history.location.state) {
      currentPage = search.page ? +search.page : 1
      pageSize = search.limit ? +search.limit : 10
      exporfilter = {
        id: '',
        refundType: '',
        client: '',
        clientId: '',
        project: '',
        status: 1,
        applyUserName: '',
        applyStartTime: '',
        applyEndTime: '',
        acceptedStartTime: '',
        acceptedEndTime: ''
      }
    } else {
      currentPage = search.page ? +search.page : currentPage
      pageSize = search.limit ? +search.limit : pageSize
      setTimeout(() => {
        setFieldsValue({
          id: exporfilter.id !== '' ? exporfilter.id : undefined,
          refundType: exporfilter.refundType !== '' ? exporfilter.refundType : undefined,
          client: exporfilter.client !== '' ? exporfilter.client : undefined,
          clientId: exporfilter.clientId !== '' ? exporfilter.clientId : undefined,
          project: exporfilter.project !== '' ? exporfilter.project : undefined,
          status: exporfilter.status !== '' ? exporfilter.status : undefined,
          applyUserName: exporfilter.applyUserName !== '' ? exporfilter.applyUserName : undefined,
          applyTime: exporfilter.applyStartTime !== '' ? [moment(exporfilter.applyStartTime), moment(exporfilter.applyEndTime)] : undefined,
          acceptedTime: exporfilter.acceptedStartTime !== '' ? [moment(exporfilter.acceptedStartTime), moment(exporfilter.acceptedEndTime)] : undefined
        })
      }, 1000)
    }
    apiOperateTimer = true
    onCommon()
    getRefundOrderList()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  //获取列表
  const getRefundOrderList = () => {
    if (apiOperateTimer) {
      setLoading(true)
      let params = {
        limit: pageSize,
        page: currentPage,
        companyId: filter.companyId,
        type: 1,
        // status: 1,
        ...exporfilter
      }
      api.getRefundOrderList(params).then(data => {
        setList(data.list)
        setCount(data.count)
        setLoading(false)
      }).catch(() => setLoading(false))
      apiOperateTimer = false
      let timer = setTimeout(() => {
        apiOperateTimer = true
        clearTimeout(timer)
      }, 1000)
    } else {
      return message.info('请不要频繁操作')
    }
  }

  //获取公共接口
  const onCommon = () => {
    api.getTopProjectList().then(res => setProjectList(res.list))//项目
    api.getRefundOrderType().then(res => setRefundOrderType(res))//退款订单类型
    api.getRefundOrderStatus().then(res => {
      let arr = res.filter(v => v.name != '付款成功')
      setRefundOrderStatus(arr)
    })//退款订单状态
  }

  const getTopProjectList = () => {
    api.getTopProjectList({
      companyId: filter.companyId
    }).then(res => setProjectList(res.list))
  }

  //筛选时调列表接口
  const getEmployeeList = () => {
    setLoading(true)
    api.getRefundOrderList({
      limit: pageSize,
      page: currentPage,
      companyId: filter.companyId,
      id: exporfilter.id,
      refundType: exporfilter.refundType,
      client: exporfilter.client,
      clientId: exporfilter.clientId,
      project: exporfilter.project,
      status: exporfilter.status,
      applyUserName: exporfilter.applyUserName,
      applyStartTime: exporfilter.applyStartTime,
      applyEndTime: exporfilter.applyEndTime,
      acceptedStartTime: exporfilter.acceptedStartTime,
      acceptedEndTime: exporfilter.acceptedEndTime,
      type: 1
    }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  //点击筛选提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //const values = Object.values(vals)
      //console.log(values)
      if (!err) {
        // console.log(vals)
        currentPage = 1
        history.replace(match.path)
        exporfilter.id = vals.id ? vals.id : ''
        exporfilter.refundType = vals.refundType ? vals.refundType : ''
        exporfilter.client = vals.client ? vals.client : ''
        exporfilter.clientId = vals.clientId ? vals.clientId : ''
        exporfilter.project = vals.project ? vals.project : ''
        exporfilter.status = vals.status ? vals.status : ''
        exporfilter.applyUserName = vals.applyUserName ? vals.applyUserName : ''
        exporfilter.applyStartTime = vals.applyTime ? vals.applyTime[0].format('YYYY-MM-DD') : ''
        exporfilter.applyEndTime = vals.applyTime ? vals.applyTime[1].format('YYYY-MM-DD') : ''
        exporfilter.acceptedStartTime = vals.acceptedTime ? vals.acceptedTime[0].format('YYYY-MM-DD') : ''
        exporfilter.acceptedEndTime = vals.acceptedTime ? vals.acceptedTime[1].format('YYYY-MM-DD') : ''
        getEmployeeList()
      }
    })
  }

  const onReset = (e) => {
    e.preventDefault()
    exporfilter = {
      id: '',
      refundType: '',
      client: '',
      clientId: '',
      project: '',
      status: 1,
      applyUserName: '',
      applyStartTime: '',
      applyEndTime: '',
      acceptedStartTime: '',
      acceptedEndTime: ''
    }
    resetFields()
    currentPage = 1
    history.replace(match.path)
    getRefundOrderList()
  }

  //筛选
  const onCorpFilter = (data) => {
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getTopProjectList()
    getRefundOrderList()
  }

  const onChangeTable = (pagination) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getEmployeeList()
  }

  //操作=>修改
  const onRevise = (data) => {
    // console.log(data)
    setRevise(data)
    setVisibleRevise(true)
  }

  //操作 => 支付
  const onClosing = (data) => {
    setClosing(data)
    setVisibleClosing(true)
  }

  //操作=> 终止付款
  const onPayFailed = (data) => {
    setPayFailed(data)
    setVisiblePayFailed(true)
  }

  // 预览/下载凭证
  const onVoucherAction = (record, download = false) => {
    api.getHost({}).then((res) => {
      if (download) {
        window.location = `${res.url}/api/File/Downloads?url=${res.url}/api/fortune/refundOrder/download?id=${record.id}&name=${record.number}.pdf`
      } else {
        window.open(
          `${res.url}/api/fortune/refundOrder/preview?id=${record.id}`,
          '_blank'
        )
      }
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <>
      <CorpFilter onChange={onCorpFilter} part='tuikuan'/>
      {/* <CorpFilter supra={false} defaultValue={filter.companyId == '' ? 1 : filter.companyId} onChange={onCorpFilter} part='tuikuan'/> */}
      <div className="comm-stay-list">
        <Form onSubmit={formSubmit} {...formItemLayout}>
          <Row gutter={24}>
            <Col span={6}>
              <FormItem label="业务编号">
                {getFieldDecorator('id')(<Input allowClear={true} placeholder="请输入业务编号"/>)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="退款类型">
                {getFieldDecorator('refundType')(
                  <Select
                    placeholder="全部"
                    allowClear={true}
                    dropdownMatchSelectWidth={false}
                    showSearch={true}
                    optionFilterProp="children"
                  >
                    {
                      refundOrderType.map(v => {
                        return <Option key={v.id}>{v.name}</Option>
                      })
                    }
                  </Select>)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="客户/代理商名称">
                {getFieldDecorator('client')(<Input allowClear={true} placeholder="请输入客户/代理商名称"/>)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="XOAID/XQID">
                {getFieldDecorator('clientId')(<Input allowClear={true} placeholder="请输入XOAID/XQID"/>)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <FormItem label="项目">
                {getFieldDecorator('project')(
                  <Select
                    placeholder="全部"
                    allowClear={true}
                    dropdownMatchSelectWidth={false}
                    showSearch={true}
                    optionFilterProp="children"
                  >
                    {
                      projectList.map(v => {
                        return <Option key={v.id}>{v.name}</Option>
                      })
                    }
                  </Select>)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="状态">
                {getFieldDecorator('status', { initialValue: '1' })( 
                  <Select
                    placeholder="全部"
                    allowClear={true}
                    dropdownMatchSelectWidth={false}
                    showSearch={true}
                    optionFilterProp="children"
                  >
                    {
                      refundOrderStatus.map(v => {
                        return <Option key={v.id}>{v.name}</Option>
                      })
                    }
                  </Select>)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="申请人">
                {getFieldDecorator('applyUserName')(<Input allowClear={true} placeholder="请输入申请人"/>)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="申请时间">
                {getFieldDecorator('applyTime')(<RangePicker allowClear={false} />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <FormItem label="审批通过时间">
                {getFieldDecorator('acceptedTime')(<RangePicker allowClear={false} />)}
              </FormItem>
            </Col>
          </Row>
          <div className="comm-stay-btn" >
            <BtnGroup cancelName="重置" confirmName="查询" onCancel={onReset} />
          </div>
        </Form>
      </div>
      <div className="line"></div>
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1600 }}
        onChange={onChangeTable}
      >
        <Column title="业务编号" dataIndex="id" fixed="left" />
        <Column title="退款类型" dataIndex="refundTypeName" />
        <Column title="公司主体" dataIndex="companyName" />
        <Column title="客户/代理商名称" dataIndex="client" />
        <Column title="XOAID/XQID" dataIndex="clientId" />
        <Column title="项目" dataIndex="projectName" />
        <Column title="退款金额" dataIndex='amount' />
        <Column title="收款单位" dataIndex="acceptCompany" />
        <Column title="开户行" dataIndex="bankName" />
        <Column title="银行账号" dataIndex="bankAccount" />
        <Column title="状态" dataIndex="statusName" />
        <Column title="申请人" dataIndex="applyUserName" />
        <Column title="申请时间" dataIndex="applyTime" />
        <Column title="审批通过时间" dataIndex="acceptedTime" />
        <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
          <div>
            {record.isDetail && <Link target='_blank' to={`/fund/refundManage/details?id=${record.refundId}&time=${+new Date()}&sign=${CryptoJS.MD5(record.refundId + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`} style={{ cursor: 'pointer' }}>详情</Link>}
            {/* <div><a style={{ cursor: 'pointer' }} onClick={() => onRevise(record)}>修改</a></div> */}
            {record.isPay && <div><a style={{ cursor: 'pointer' }} onClick={() => onClosing(record)}>支付</a></div>}
            {record.isPayFailed && <div><a style={{ cursor: 'pointer' }} onClick={() => onPayFailed(record)}>终止付款</a></div>}
            {record.isPreview && <div><a style={{ cursor: 'pointer' }} onClick={() => onVoucherAction(record)} >凭证预览</a></div>}
            {record.isDownload && <div><a style={{ cursor: 'pointer' }} onClick={() => onVoucherAction(record, true)} >下载凭证 </a></div>}
          </div>
        )} />
      </Table>
      <Modal
        title="收款方信息修改"
        visible={visibleRevise}
        onCancel={() => setVisibleRevise(false)}
        bodyStyle={{
          height: '400px'
        }}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        footer={null}
      >
        <ReviseModal revise={revise} setVisibleRevise={setVisibleRevise} getEmployeeList={getEmployeeList}/>
      </Modal>
      <Modal
        title="支付确认"
        visible={visibleClosing}
        onCancel={() => setVisibleClosing(false)}
        bodyStyle={{
          height: '680px'
        }}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        footer={null}
      >
        <ClosingModal closing={closing} setVisibleClosing={setVisibleClosing} getEmployeeList={getEmployeeList}/>
      </Modal>
      <Modal
        title="终止付款确认"
        visible={visiblePayFailed}
        onCancel={() => setVisiblePayFailed(false)}
        bodyStyle={{
          height: '530px'
        }}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        footer={null}
      >
        <PayFailedModal payFailed={payFailed} setVisiblePayFailed={setVisiblePayFailed} getEmployeeList={getEmployeeList}/>
      </Modal>
    </>
  )
}

export default Form.create()(RefundStay)