/**
 * 模块名称: 待结算列表 => 费用订单
 * @author liujingxue@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import CostStay from './CostStay'

const CostStayIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route component={CostStay} />
    </Switch>
  )
}

export default CostStayIndex