/**
 * 模块名称: 系统充值管理
 * @author zhuyan@372163.com
 */

 import React from 'react'
 import { Switch, Route } from 'react-router-dom'
 import TopUp from './TopUp'
 import AddTopUp from './module/AddTopUp'
 import TopUpDetail from './module/TopUpDetail'

 const TopUpIndex = ({ match, history }) => {
 
   return (
     <Switch> 
       <Route path={match.path + '/addTopUp'} component={AddTopUp} />
       <Route path={match.path + '/detail'} component={TopUpDetail} />
       <Route component={TopUp} />
     </Switch>
   )
 }
 
 export default TopUpIndex