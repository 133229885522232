/*
 * @Author: zhuyan 
 * @Date: 2021-11-03 09:23:54 
 * @Last Modified by: yangzhihang
 * @Last Modified time: 2021-12-22 14:54:29
 * 供应商管理 => 系统充值管理
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Popconfirm,
  DatePicker,
  Switch
} from 'antd'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CorpQuanQian from '@/components/CorpQuanQian'
import { Link } from 'react-router-dom'
import CryptoJS from 'crypto-js'
import moment from 'moment'

const { Column } = Table
const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1
let filter = {}
let shouldMe = null

const payTypeData = [{
  id: 1,
  name: '银行转账'
}, {
  id: 2,
  name: '线上支付'
}, {
  id: 3,
  name: '信用金还款'
}]

const statusData = [{
  id: 1,
  name: '审批中'
}, {
  id: 2,
  name: '已驳回'
}, {
  id: 3,
  name: '待付款'
}, {
  id: 4,
  name: '已付款'
}, {
  id: 5,
  name: '终止付款'
}]

const TopUp = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [authList, setAuthList] = useState([])
  const search = parseSearch(location.search)
  const [projectList, setProjectList] = useState([])

  useEffect(() => {
    currentPage = search.page ? +search.page : currentPage
    pageSize = search.limit ? +search.limit : pageSize
    if (!history.location.state) {
      filter = {
        companyId: '',
        id: '',
        projectId: '',
        payType: '',
        status: '',
        applyStafName: '',
        applyStart: '',
        applyEnd: ''
      }
      shouldMe = null
    } else {
      const { 
        id, 
        projectId, 
        payType, 
        status, 
        applyStafName,
        applyStart,
        applyEnd, 
      } = filter
      setTimeout(() => {
        setFieldsValue({
          id:  id ? id : undefined,
          projectId: +projectId ? projectId : undefined,
          payType: +payType ? payType : undefined,
          status: +status ? status : undefined,
          applyStafName: applyStafName ? applyStafName : undefined,
          applyTime: applyStart ? [moment(applyStart, 'YYYY-MM-DD'), moment(applyEnd, 'YYYY-MM-DD')] : undefined,
        })
      }, 600)
    }
    getList()
    getFilterData()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getList = () => {
    setLoading(true)
    if(shouldMe){
      filter.shouldMe = shouldMe
    }else{
      for(let i in filter){
        if(i == 'shouldMe')delete filter[i]
      }
    }
    let params = Object.assign({}, filter)
    for (let i in params) {
      if (params[i] === '') delete params[i]
    }
    api.getTopUpList({ ...params, limit: pageSize, page: currentPage })
      .then(data => {
        setLoading(false)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
  }

  // 查询
  const onSearch = evt => {
    evt.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        currentPage = 1
        history.replace(match.path)
        if (vals.applyTime) vals.applyStart = vals.applyTime[0].format('YYYY-MM-DD')
        if (vals.applyTime) vals.applyEnd = vals.applyTime[1].format('YYYY-MM-DD')
        filter = { ...filter, ...vals }
        for (let i in filter) {
          if (filter[i] === undefined || i === 'applyTime') {
            filter[i] = ''
          }
        }
        getList()
      }
    })
  }

  // 重置
  const onReset = () => {
    resetFields()
    shouldMe = null
    filter = {
      companyId: filter.companyId,
      id: '',
      projectId: '',
      payType: '',
      status: '',
      applyStafName: '',
      applyStart: '',
      applyEnd: ''
    }
    currentPage = 1
    history.replace(match.path)
    getList()
  }

  // 筛选条件
  const getFilterData = () => {
    api.getsysProjectChilds({ pid: 0 }).then(data => {
      setProjectList(data)
    })
  }

  // 删除
  const onDelete = (id) => {
    api.getTopUpDelete({ id }).then(() => {
      message.success('删除成功')
      getList()
    })
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getList()
  }

  const onChangeCorp = (data) => {
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const onSetShouldMe = checked => {
    shouldMe = checked ? 1 : null
    getList()
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }
  return (
    <>
      <CorpQuanQian onChange={onChangeCorp} />
      <div className="search-doc-wrap">
        <Form onSubmit={onSearch} {...formItemLayout}>
          <div className="form-box">
            <FormItem label="业务编号">
              {getFieldDecorator('id')(<Input allowClear={true}/>)}
            </FormItem>
            <FormItem label="项目">
              {getFieldDecorator('projectId')(
                <Select
                  placeholder="全部"
                  showSearch={true}
                  optionFilterProp="children"
                  dropdownMatchSelectWidth={false}
                  allowClear={true}
                >
                  {projectList && projectList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>)}
            </FormItem>
            <FormItem label="付款方式">
              {getFieldDecorator('payType')(
                <Select placeholder="全部" allowClear={true}>
                  {payTypeData.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              )}
            </FormItem>
            <FormItem label="状态">
              {getFieldDecorator('status')(
                <Select placeholder="全部" allowClear={true}>
                  {statusData.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              )}
            </FormItem>
            <FormItem label="申请人">
              {getFieldDecorator('applyStafName')(<Input allowClear={true} />)}
            </FormItem>
            <FormItem label="申请时间">
              {getFieldDecorator('applyTime')(<RangePicker allowClear={false} />)}
            </FormItem>
          </div>
          <FormItem className="btns" label=" " colon={false}>
            <Switch 
              checkedChildren="待审批" 
              unCheckedChildren="待审批" 
              disabled={loading} 
              onClick={onSetShouldMe} 
              className="shouldme-switch-btn" 
              checked={shouldMe ? true : false} 
            />
            <Button onClick={onReset}>重置</Button>
            <Button type="primary" htmlType="submit">查询</Button>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="topup-apply">
          <Link to={`${match.path}/addTopUp`}>
            <Button type="primary">充值申请</Button>
          </Link>
        </Auth>
      </div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1400, y: 500 }}
        onChange={onChangeTable}
      >
        <Column title="业务编号" dataIndex="id"/>
        <Column title="项目" dataIndex="projectName" />
        <Column title="公司主体" dataIndex="companyName" />
        <Column title="付款方式" dataIndex="payTypeName" />
        <Column title="收款单位" dataIndex="payee" />
        <Column title="开户行" dataIndex="openBank" />
        <Column title="银行账号" dataIndex="bankNumber" />
        <Column title="充值金额" dataIndex="money" />
        <Column title="状态" dataIndex="statusName" />
        <Column title="申请人" dataIndex="applyStafName" />
        <Column title="申请时间" dataIndex="createTime" width={120}/>
        <Column title="操作" key="set" fixed="right" align="center" width={80} render={(text, record) => (
          <>
            {record.hasDetail && (
              <div>
                <Link to={`${match.path}/detail?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`}>
                  详情
                </Link>
              </div>
            )}
            {record.hasEdit && (
              <a onClick={() => {
                history.push(`/supplie/topUp/addTopUp`, {
                  id: record.id,
                })
              }}>编辑</a>
            )}
            {record.hasDel && (
              <div>
                <Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                  <a>删除</a>
                </Popconfirm>
              </div>
            )}
          </>
        )} />
      </Table>
    </>
  )
}

export default Form.create()(TopUp)
