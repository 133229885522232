/**
 * 模块名称: 待结算列表 => 退款订单
 * @author liujingxue@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import RefundStay from './RefundStay'

const RefundStayIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route component={RefundStay} />
    </Switch>
  )
}

export default RefundStayIndex