/**
 * 模块名称: 待结算列表 => 退款订单列表页 => 修改弹窗
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
	Form,
	Input,
	message
} from 'antd'
import BtnGroup from '@/components/BtnGroup'

const FormItem = Form.Item

const ReviseModal = (props) => {
	const { match, location, history, revise, setVisibleRevise, getEmployeeList } = props
	const { getFieldDecorator, resetFields, setFieldsValue, validateFields } = props.form

	useEffect(() => {
		setFieldsValue({
			bankName: revise.bankName,
			bankAccount: revise.bankAccount
		})
	}, [])

	const formSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			if (!err) {
				api.setRefundOrderEdit({
					id: revise.id,
					bankName: vals.bankName,
					bankAccount: vals.bankAccount
				}).then(res => {
					setVisibleRevise(false)
					getEmployeeList()
				}).catch(err => console.log(err))
			}
		})
	}

	const formItemLayout = {
		labelCol: {
			span: 6
		},
		wrapperCol: {
			span: 14
		}
	}

	return (
		<Form onSubmit={formSubmit} {...formItemLayout}>
			<FormItem label="业务编号">
				<div>{revise.id}</div>
			</FormItem>
			<FormItem label="退款金额">
				<div>{revise.amount}</div>
			</FormItem>
			<FormItem label="收款单位">
				<div>{revise.acceptCompany}</div>
			</FormItem>
			<FormItem label="开户行">
				{getFieldDecorator('bankName', {
					rules: [{ required: true, message: '请输入' }]
				})(<Input />)}
			</FormItem>
			<FormItem label="银行账户">
				{getFieldDecorator('bankAccount', {
					rules: [{ required: true, message: '请输入' }]
				})(<Input />)}
			</FormItem>
			<BtnGroup cancelName="取消" confirmName="确认" onConfirm={formSubmit} onCancel={() => setVisibleRevise(false)} />
		</Form>
	)
}

export default Form.create()(ReviseModal)