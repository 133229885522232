/**
 * 模块名称: 待结算列表 => 采购订单列表
 * @author jiashaowang@372163.com
 */

import React, { useState, useEffect, useRef } from 'react';
import api from '@/api';
import { Table, message, Button, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { parseSearch } from '@/utils';
import Auth from '@/components/AuthMiddleware';
import CorpQuanQian from '@/components/CorpQuanQian';
import FiltersForm from '@/components/FiltersForm';
import CryptoJS from 'crypto-js';
import PurchaseActionsForm from './components/PurchaseActionsForm';
import '../assets/style.scss';

//筛选初始值
let exporfilter = {
  contractNo: '',
  contract_no: '',
  applyName: '',
  customerName: '',
  liablerName: '',
  staffNo: '',
  departId: '',
  businessStatus: '',
  auditStatus: '',
  templateName: '',
  contentId: '',
  applyDate: '',
  collectionDate: '',
  backdata: '',
};

const PurchaseStay = (props) => {
  const { match, location, history } = props;
  const search = parseSearch(location.search);

  const initParams = {
    corpId: '',
    businessStatus: 2,
    page: search.page || 1,
    limit: search.limit || 10,
  };

  const [states, setStates] = useState({
    dataSource: [], // 列表
    loading: true,
    total: 0, // 总条数
    actions: {
      type: -1,
      values: {},
    },
    filter: {
      types: [], // 筛选：资产类别
      payTypes: [], // 筛选：付款方式
      businessStatus: [],
    },
    // 筛选条件：列表接口参数
    params: initParams,
  });
  const { dataSource, actions, filter, loading, total, params } = states;
  const [authList, setAuthList] = useState([]);

  useEffect(() => {
    getList();
    const { page, limit, ...others } = params;
    exporfilter = { ...others };
  }, [params]);

  useEffect(() => {
    getFilters();
    getPageAuth();
  }, []);

  // 获取列表
  const getList = () => {
    api
      .getAssetPurchaseWaitCloseList(params)
      .then((res) => {
        setStates((current) => ({
          ...current,
          loading: false,
          dataSource: res.list,
          total: res.count,
        }));
      })
      .catch(() => {
        setStates((current) => ({
          ...current,
          loading: false,
        }));
      });
  };

  // 获取按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then((list) => {
      setAuthList(list);
    });
  };

  // 获取筛选项
  const getFilters = () => {
    api.getAssetPurchaseFiltersList().then((data) => {
      setStates((current) => ({
        ...current,
        filter: {
          ...data,
          businessStatus: data.businessStatus.filter(
            (item) => [2, 5].indexOf(item.id) >= 0
          ),
        },
      }));
    });
  };

  // 点击筛选
  const onSubmit = (values, type) => {
    initParams.page = 1;
    initParams.limit = 10;
    if (type!== 2) {
      delete initParams.businessStatus
    } 
    setStates((current) => ({
      ...current,
      loading: true,
      params: {
        ...initParams,
        corpId: params.corpId,
        ...values,
      },
    }));
    history.replace(match.path);
  };

  // 点击选择所属公司
  const onChangeCorp = (data) => {
    setStates((current) => ({
      ...current,
      loading: true,
      params: {
        ...params,
        corpId: data.map((item) => item.id),
        page: 1,
        limit: 10,
      },
    }));
    history.replace(match.path);
  };

  // 分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    const { current: page, pageSize: limit } = pagination;
    setStates((current) => ({
      ...current,
      loading: true,
      params: {
        ...params,
        page: page,
        limit: limit,
      },
    }));
    history.replace(match.path + '?page=' + page + '&limit=' + limit);
  };

  // 操作：修改-0、结算-1、终止付款-2
  const onActions = (record, type) => {
    setStates((current) => ({
      ...current,
      actions: {
        type,
        values: record,
      },
    }));
  };

  const onActionsCancel = () => {
    setStates((current) => ({
      ...current,
      actions: { type: -1 },
    }));
  };

  const onActionsSubmit = (values, callback, type) => {
    callback(true);
    api[
      [
        'setAssetPurchaseModify',
        'setAssetPurchaseSettlement',
        'setAssetPurchasePaymentFailed',
      ][type]
    ](values)
      .then((res) => {
        onActionsCancel();
        getList();
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };

  // 预览/下载凭证
  const onVoucherAction = (record, download = false) => {
    api.getHost({}).then((res) => {
      if (download) {
        window.location = `${res.url}/api/fortune/File/DownPdf?id=${record.id}&type=${'PurchaseProof'}`;
      } else {
        window.open(
          `${res.url}/api/fortune/File/PrevPdf?id=${record.id}&type=${'PurchaseProof'}`,
          '_blank'
        );
      }
    });
  };

  const columns = [
    {
      title: '业务编号',
      dataIndex: 'id',
      fixed: 'left',
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入业务编号',
      },
    },
    {
      title: '资产类别',
      dataIndex: 'type',
      valueType: 'Select',
      fieldProps: {
        placeholder: '全部',
        showSearch: true,
        options: filter.types.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      },
      render: (text, record) => record.typeName,
    },
    { title: '公司主体', dataIndex: 'corpName' },
    {
      title: '付款方式',
      dataIndex: 'payType',
      valueType: 'Select',
      fieldProps: {
        placeholder: '全部',
        showSearch: true,
        options: filter.payTypes.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      },
      render: (text, record) => record.payTypeName,
    },
    { title: '付款金额', dataIndex: 'payMoney' },
    { title: '收款单位', dataIndex: 'payee' },
    { title: '开户行', dataIndex: 'payeeBank' },
    { title: '银行账号', dataIndex: 'payeeAccount' },
    {
      title: '状态',
      dataIndex: 'businessStatus',
      valueType: 'Select',
      fieldProps: {
        placeholder: '全部',
        showSearch: true,
        options: filter.businessStatus.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      },
      render: (text, record) => record.businessStatusName,
    },
    {
      title: '申请人',
      dataIndex: 'creatorName',
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入申请人',
      },
      render: (text, record) => record.creatorName,
    },
    {
      title: '申请时间',
      dataIndex: 'addTime',
      valueType: 'RangePicker',
      search: {
        transform: (value) => {
          return {
            applyStart: value[0].format('YYYY-MM-DD'),
            applyEnd: value[1].format('YYYY-MM-DD'),
          };
        },
      },
    },
    {
      title: '审批通过时间',
      dataIndex: 'auditTime',
      valueType: 'RangePicker',
      search: {
        transform: (value) => {
          return {
            auditStart: value[0].format('YYYY-MM-DD'),
            auditEnd: value[1].format('YYYY-MM-DD'),
          };
        },
      },
    },
    {
      title: '操作',
      dataIndex: 'oprate',
      fixed: 'right',
      width: 120,
      render: (text, record) => {
        return (
          <>
            {record.isDetail && (
              <Link
                target='_blank'
                to={`/asset/purchase/details?id=${
                  record.id
                }&time=${+new Date()}&sign=${CryptoJS.MD5(
                  record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + +new Date()
                ).toString()}`}
              >
                <Button block type='link' size='small'>
                  详情
                </Button>
              </Link>
            )}

            {record.businessStatus === 2 && (
              <>
                {/* {record.payType === 1 && (
                  <Button
                    block
                    type='link'
                    size='small'
                    onClick={() => onActions(record, 0)}
                  >
                    修改
                  </Button>
                )} */}
                {record.isPay && (
                  <Button
                    block
                    type='link'
                    size='small'
                    onClick={() => onActions(record, 1)}
                  >
                    支付
                  </Button>
                )}
                {record.isPayFailed && (
                  <Button
                    block
                    type='link'
                    size='small'
                    onClick={() => onActions(record, 2)}
                  >
                    终止付款
                  </Button>
                )}
              </>
            )}
            {record.hasPrev &&
              <Button
                block
                type='link'
                size='small'
                onClick={() => onVoucherAction(record)}
              >
                凭证预览
            </Button>
            }
            {record.hasDown &&
              <Button
                block
                type='link'
                size='small'
                onClick={() => onVoucherAction(record, true)}
              >
                下载凭证
              </Button>
            }
          </>
        );
      },
    },
  ];
  return (
    <>
      {/* <CorpQuanQian supra={false} defaultValue={params.corpId} onChange={onChangeCorp} part='feiyong' /> */}
      <CorpQuanQian onChange={onChangeCorp} part='feiyong' />
      <FiltersForm columns={columns} initialValues={{ businessStatus: 2 }} onSubmit={onSubmit} onReset={(val) => onSubmit(val, 2)} />
      <div className='line'></div>
      <div className='add-wrap'></div>
      <Table
        size='small'
        columns={columns}
        dataSource={dataSource}
        rowKey='id'
        loading={loading}
        pagination={{
          pageSize: parseInt(params.limit),
          total: total,
          current: parseInt(params.page),
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        scroll={{ x: 2500 }}
      ></Table>
      <Modal
        title={['收款方信息修改', '支付确认', '终止付款确认'][actions.type]}
        visible={actions.type >= 0}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        footer={null}
      >
        <PurchaseActionsForm
          type={actions.type}
          values={actions.values}
          onSubmit={(values, callback) =>
            onActionsSubmit(values, callback, actions.type)
          }
          onCancel={onActionsCancel}
        />
      </Modal>
    </>
  );
};

export default PurchaseStay;
