/*
 * @Author: zhuyan 
 * @Date: 2021-4-27 10:38:14 
 * 模块名称: 已支付-公司内部往来款
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import InternalAccount from './InternalAccount'

const InternalAccountIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route component={InternalAccount} />
    </Switch>
  )
}

export default InternalAccountIndex