/**
 * 模块名称: 培训管理
 * @author xuzhongyuan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import './assets/style.scss'
import Documents from './Documents'
import Evaluate from './Evaluate'

const Train = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/documents'} component={Documents} />
      <Route path={match.path + '/evaluate'} component={Evaluate} />
    </Switch>
  )
}

export default Train