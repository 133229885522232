/**
 * 模块名称: 已结算列表
 * @author jiashaowang@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import RefundDone from './RefundDone'
import CostDone from './CostDone'
import PayDone from './PayDone'
import PurchaseDone from './PurchaseDone'
import InternalAccount from './InternalAccount'

import './assets/style.scss'

const SettledListIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/refundDone'} component={RefundDone} />
      <Route path={match.path + '/costDone'} component={CostDone} />
      <Route path={match.path + '/payDone'} component={PayDone} />
      <Route path={match.path + '/purchaseDone'} component={PurchaseDone} />
      <Route path={match.path + '/InternalAccount'} component={InternalAccount} />
      <Route component={RefundDone} />
    </Switch>
  )
}

export default SettledListIndex