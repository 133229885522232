/**
 * 模块名称: 供应商管理 => 合同盖章申请 => 申请
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
	Form,
	Input,
	Select,
	Upload,
	message,
	Icon,
	Spin,
	Row,
	Col,
	Button
} from 'antd'
import api from '@/api'
import BtnGroup from '@/components/BtnGroup'
import { upLoadModule } from '@/utils/common'
import { parseSearch } from '@/utils'

const FormItem = Form.Item
const Option = Select.Option
const { Dragger } = Upload

const Apply = (props) => {
	const { getFieldDecorator, validateFields, setFieldsValue, getFieldValue } = props.form
	const { location, history } = props
	const [selectData, setSelectData] = useState({
		projectList: [], // 项目
		companyList: [] // 主体
	})
	const search = parseSearch(location.search)
	const [formLoading, setformLoading] = useState(true)
	const draggerRef = useRef(null)
	const [fileList, setFileList] = useState([])
	const [loading, setLoading] = useState(false)
	const [supplierName, setSupplierName] = useState('')
	const [supplierContract, setSupplierContract] = useState({})

	useEffect(() => {
		if (search && search.type == 1) {
			onEdit2()
		} else if(search && search.id) {
			onEdit()
		}
		setformLoading(false)
	}, [])

	//编辑2
	const onEdit2 = () => {
		api.getSupplierContractInfo({ id: search.id }).then(res => {
			api.getSupplierContractSealCheckContract({ supplierContractNo: res.contractNo }).then(res => {
				setSupplierContract(res)
			})
			setSupplierName(res.contractNo)
			setFieldsValue({
				supplierContractNo: res.contractNo,
				remark: res.remark
			})
		})
	}

	// 编辑
	const onEdit = () => {
		api.getSupplierContractSealInfo({ id: search.id }).then(res => {
			api.getSupplierContractSealCheckContract({ supplierContractNo: res.supplierContractNo }).then(res => {
				setSupplierContract(res)
			})
			setSupplierName(res.supplierContractNo)
			setFieldsValue({
				supplierContractNo: res.supplierContractNo,
				remark: res.remark
			})
		})
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 10 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 14 },
		}
	}

	//提交表单
	const formSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			let params = Object.assign({}, vals)
			if (!err) {
				if(JSON.stringify(supplierContract) == '{}') {
					return message.error('请先点击确认验证合同号')
				}
				setLoading(true)
				if (search && search.id && !search.type) {
					params.id = search.id
					api.setSupplierContractSealEdit(params).then(res => {
						setLoading(false)
						message.success('提交成功')
						history.push('/supplie/supplierContractSeal')
					}).catch(() => setLoading(false))
				} else {
					api.setSupplierContractSealApply(params).then(res => {
						setLoading(false)
						message.success('提交成功')
						history.push('/supplie/supplierContractSeal')
					}).catch(() => setLoading(false))
				}
			}
		})
	}

	//验证合同号按钮
	const onClickSupplier = () => {
		api.getSupplierContractSealCheckContract({ supplierContractNo: supplierName }).then(res => {
			setSupplierContract(res)
		})
	}

	//取消
	const onCancel = () => {
		history.goBack()
	}

	return (
		<div>
			<Spin spinning={formLoading}>
				<h3 style={{ marginTop: 0, marginBottom: 20, fontWeight: 600 }}>盖章申请</h3>
				<div className="line"></div>
				<Form onSubmit={formSubmit} {...formItemLayout} className="ant-advanced-search-formTwo">
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="合同号" labelCol={{ span: 8 }}>
								{getFieldDecorator('supplierContractNo', {
									rules: [{ required: true, message: '请填写完整后提交' }]
								})(<Input disabled={search.type == 1 ? true : false} style={{ width: 200 }} onChange={(e) => {
									setSupplierContract({})
									setSupplierName(e.target.value)
								}}/>)}
							</FormItem>
						</Col>
						{!search.type == 1 && <Col span={6}>
							<Button type="primary" style={{ marginTop: '3px', marginLeft: '20px'}} onClick={onClickSupplier}>确认</Button>
						</Col>}
					</Row>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="我方签约主体" labelCol={{ span: 8 }}>
								<div style={{ width: '800px' }}>{supplierContract.companyName}</div>
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="对方签约主体" labelCol={{ span: 8 }}>
								<div style={{ width: '800px' }}>{supplierContract.supplierName}</div>
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="附件" labelCol={{ span: 8 }}>
								{supplierContract.files && supplierContract.files.length > 0 && supplierContract.files.map((v, i) => {
									return <a key={i} href={v.url} target="_blank" rel="noopener noreferrer">{v.name}</a>
								})} 
							</FormItem>
						</Col>
					</Row>
				
					<div style={{ marginTop: 20 }}>
						<FormItem label="备注" labelCol={{ span: 2 }}>
							{getFieldDecorator('remark')(
								<Input.TextArea maxLength={100} />
							)}
						</FormItem>
					</div>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<BtnGroup onCancel={onCancel} confirmName='提交' right loading={loading} />
					</div>
				</Form>
			</Spin>
		</div>
	)
}

export default Form.create()(Apply)