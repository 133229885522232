/**
 * 社保公积金
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Insurance from './Insurance'
import Funds from './Funds'

const Socialfund = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/insurance'} component={Insurance} />
      <Route path={match.path + '/funds'} component={Funds} /> 
      <Route component={Insurance} />
    </Switch>
  )
}

export default Socialfund