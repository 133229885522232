/**
 * 模块名称: 供应商管理
 * @author zhuyan@372163.com
 */

 import React from 'react'
 import { Switch, Route } from 'react-router-dom'
 import SupplierMgt from './SupplierMgt'
 import TopUp from './TopUp'
 import SupplierContract from './SupplierContract'
 import SupplierContractSeal from './SupplierContractSeal'
 import './assets/style.scss'
 
 const SupplieIndex = ({ match, history }) => {
 
   return (
     <Switch> 
       <Route path={match.path + '/supplierMgt'} component={SupplierMgt} />
       <Route path={match.path + '/supplierContract'} component={SupplierContract} />
       <Route path={match.path + '/supplierContractSeal'} component={SupplierContractSeal} />
       <Route path={match.path + '/topUp'} component={TopUp} />
       <Route component={SupplierMgt} />
     </Switch>
   )
 }
 
 export default SupplieIndex