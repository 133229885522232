/*
 * @Author: zhuyan 
 * @Date: 2021-11-16 10:38:14 
 * 模块名称: 已支付-充值订单
 */

 import React from 'react'
 import { Switch, Route } from 'react-router-dom'
 import PayDone from './PayDone'
 
 const PayDoneIndex = ({ match, history }) => {
 
   return (
     <Switch>
       <Route component={PayDone} />
     </Switch>
   )
 }
 
 export default PayDoneIndex