/**
 * 模块名称: 关联项目
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Checkbox,
  Row,
  Col,
  message,
  Empty
} from 'antd'
import BtnGroup from '@/components/BtnGroup'

let newProject = []

const Setprojects = (props) => {
  const { getList, supplierId, setProjectsVisible, setProjectLoading } = props
  const [project, setProject] = useState([])
  const [hasChoosedArr, setHasChoosedArr] = useState([])
  const [hasChoosedArrs, setHasChoosedArrs] = useState([])

  useEffect(() => {
    getSysSupplierProjectList()
  }, [])

  const getSysSupplierProjectList = () => {
    setProjectLoading(true)
    api.getSysSupplierProjectList({ supplierId, limit: global.paramsLimit }).then(data => {
      setProject(data.list)
      let arr = []
      for (let i = 0; i < data.list.length; i++) {
        if (data.list[i].hasChoosed === 1) {
          arr.push(data.list[i].id)
        }
      }
      // setFieldsValue({
      //   projects: arr,
      // })
      setHasChoosedArr(arr)
      setHasChoosedArrs(arr)
      setProjectLoading(false)
    }).catch(() => setProjectLoading(false))
  }

  const formSubmit = () => {
    // console.log(hasChoosedArr, hasChoosedArrs, newProject)
    let newArr = []
    if (newProject.length > 0) {
      newArr = [...new Set([...hasChoosedArr, ...hasChoosedArrs])]
    } else {
      newArr = hasChoosedArr
    }
    // console.log('newArr', newArr)
    api.updateSysSupplierProjects({ supplierId: supplierId, projects: newArr.join(',') }).then(data => {
      setProjectsVisible(false)
      message.success('设置成功')
      getList()
      newProject = []
    }).catch()
  }

  const onChange = (checkedValues) => {
    // console.log('checkedValues', checkedValues, hasChoosedArrs)
    setHasChoosedArr(checkedValues)
  }

  const departInp = useRef(null)

  const onSearch = () => {
    // console.log(departInp.current.value)
    // console.log(project)
    newProject = project.filter((item) => !(item.name.indexOf(departInp.current.value)))
    // console.log(newProject)
    setProject(newProject)
    let arr = []
    for (let i = 0; i < newProject.length; i++) {
      if (newProject[i].hasChoosed === 1) {
        arr.push(newProject[i].id)
      }
    }
    setHasChoosedArr(arr)
    // console.log(getArrDifference(arr, hasChoosedArrs))
    setHasChoosedArrs(getArrDifference(arr, hasChoosedArrs))
  }

  //取出两个数组中的不同
  const getArrDifference = (arr1, arr2) => {
    return arr1.concat(arr2).filter(function(v, i, arr) {
      return arr.indexOf(v) === arr.lastIndexOf(v)
    })
  }

  const onReset = () => {
    departInp.current.value = ''
    newProject = []
    getSysSupplierProjectList()
  }

  const onKeyup = (e) => {
    if (e.keyCode === 13) {
      onSearch()
    }
  }

  return (
    <div className="setIndustry">
      <div className="setIndustry-wrap">
        <div className="setIndustry-title">搜索：</div>
        <div className="setIndustry-item">
          <input className="ant-input" ref={departInp} type="text" placeholder="关联项目" onKeyUp={onKeyup}/>
        </div>
        <BtnGroup confirmName="筛选" cancelName="重置" onConfirm={onSearch} onCancel={onReset} />
      </div>
      {
        project.length > 0 ? <Checkbox.Group value={hasChoosedArr} style={{ width: '100%', display: 'flex', flexWrap:'wrap' }} onChange={onChange}>
          {project.map((item, index) => (
            <Row span={8} key={index}>
              <Col>
                <Checkbox value={item.id} style={{ marginLeft: 20, marginBottom: 6 }} >{item.name}</Checkbox>
              </Col>
            </Row>
          ))}
        </Checkbox.Group> : <Empty />
      }
      <div style={{ height: 60 }}></div>
      <div className="setShortCloseBtn">
        <BtnGroup cancelName="取消" confirmName="确定" onConfirm={formSubmit} onCancel={() => setProjectsVisible(false)} />
      </div>
    </div>
  )
}

export default Setprojects