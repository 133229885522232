/**
 * 模块名称: 供应商管理 => 供应商列表
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Drawer,
  Form,
  Alert,
  Button,
  Input,
  message,
  Spin,
  Switch,
  Modal
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import Setprojects from './module/Setprojects'
import SetIndustry from './module/SetIndustry'

const { Column } = Table
const FormItem = Form.Item
let editId = '' //编辑使用
let pageSize = 10
let currentPage = 1

let boardData = {
  keyword: '',
}

const SupplierMgt = (props) => {
  const { match, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  // const { getOfficeTemplateGetList, editRowId, setAddModuleLoading } = props
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [fieldModal, setFieldModal] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [formLoading, setformLoading] = useState(true)
  const [projectLoading, setProjectLoading] = useState(true)
  const [authList, setAuthList] = useState([])
  const [projectsVisible, setProjectsVisible] = useState(false)
  const [industryVisible, setIndustryVisible] = useState(false)
  const [supplierId, setSupplierId] = useState('')

  const search = parseSearch(props.location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    boardData = {
      keyword: ''
    }
    getSysSupplierList()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getSysSupplierList = () => {
    setLoading(true)
    api.getSysSupplierList({
      keyword: boardData.keyword,
      limit: pageSize,
      page: currentPage
    }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }


  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getSysSupplierList()
  }

  const deparkeyword = useRef(null)

  const onSearch = () => {
    boardData.keyword = deparkeyword.current.value
    history.replace(match.path)
    currentPage = 1
    getSysSupplierList()
  }

  const onReset = () => {
    boardData.keyword = ''
    deparkeyword.current.value = ''
    history.replace(match.path)
    currentPage = 1
    getSysSupplierList()
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  const addFields = () => {
    editId = ''
    setFieldModal(true)
  }

  // 编辑
  const onEdit = (data) => {
    resetFields()
    editId = data.id
    setFieldModal(true)
    setTimeout(() => {
      setFieldsValue({
        name: data.name,
        alias: data.alias,
        code: data.code,
        sort: data.sort
      })
    }, 0)
  }

  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        if(vals.name.trim() === '') {
          message.error('供应商名称不能为空')
          return
        }
        if(vals.alias.trim() === '') {
          message.error('供应商金蝶名称不能为空')
          return
        }
        if (editId) {
          vals.id = editId
        }
        setSaveLoading(true)
        api.saveSysSupplier({
          id: vals.id,
          name: vals.name,
          alias: vals.alias,
          code: vals.code,
          sort: vals.sort
        })
          .then(() => {
            setSaveLoading(false)
            setFieldModal(false)
            message.success('保存成功')
            getSysSupplierList()
          })
          .catch(() => setSaveLoading(false))
      }
    })
  }

  // 关联项目
  const onProjects = (id) => {
    setSupplierId(id)
    setProjectsVisible(true)
  }
  // 关联行业
  const onIndustry = (id) => {
    setSupplierId(id)
    setIndustryVisible(true)
  }
  // 有效
  const onisValidateChange = (item) => {
    api.updateSysSupplierIsValidate({ id: item.id, isValidate: item.isValidate == 0 ? 1 : 0 }).then(data => {
      getSysSupplierList()
    })
  }

  const onKeyup = (e) => {
    if (e.keyCode === 13) {
      onSearch()
    }
  }

  return (
    <>
      <div className="bulle-wrap">
        <div className="filter-item">
          <label htmlFor="">搜索：</label>
          <input className="ant-input ant-inputTwo" placeholder="供应商/关联项目/供应商编码" ref={deparkeyword} type="keyword" onKeyUp={onKeyup} />
        </div>
        <div className="bulle-wrap-btn">
          <BtnGroup cancelName="重置" confirmName="筛选" onConfirm={onSearch} onCancel={onReset} />
        </div>
      </div>
      <div className="line"></div>
      <div className="bulle-wrap-add">
        <Auth auths={authList} code="operate">
          <Button type="primary" icon="plus" onClick={addFields}>新增端口</Button>
        </Auth>
      </div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        // scroll={{x: 1400}}
        onChange={onChangeTable}
      >
        <Column title="ID" dataIndex="id" />
        <Column title="供应商名称" dataIndex="name" width='20%' />
        <Column title="供应商金蝶名称" dataIndex="alias" width='20%' />
        <Column title="供应商编码" dataIndex="code" />
        <Column title="关联项目" dataIndex="projectIdCns" width='30%' />
        <Column title="排序" dataIndex="sort" />
        <Column title="有效" key="isValidate" render={(text, record) => (
          <Auth auths={authList} code="operate">
            <Switch defaultChecked={record.isValidate === 0 ? false : true} onClick={() => onisValidateChange(record)} />
          </Auth>
        )} />

        <Column title="操作" key="set" width='15%' render={(text, record) => (
          <>
            <Auth auths={authList} code="operate">
            <a onClick={() => onEdit(record)}>修改</a>
            </Auth>
            {' '}
            <Auth auths={authList} code="operate">
            <a onClick={() => onProjects(record.id)}>关联项目</a>
            </Auth>
            {' '}
            <Auth auths={authList} code="operate">
            <a onClick={() => onIndustry(record.id)}>关联行业</a>
            </Auth>
          </>
        )} />
      </Table>
      <Modal
        title={editId ? '修改供应商' : '新增供应商'}
        visible={fieldModal}
        onOk={formSubmit}
        onCancel={() => { setFieldModal(false) }}
        destroyOnClose={true}
        confirmLoading={saveLoading}
      >
        <Form {...formItemLayout}>
          <FormItem label="供应商名称">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: '请输入供应商名称' },
                // {pattern: /^[\u4e00-\u9fa5]{0,}$/, message: '仅能输入中文'}
              ]
            })(
              <Input placeholder="供应商名称" />
            )}
          </FormItem>
          <FormItem label="供应商金蝶名称">
            {getFieldDecorator('alias', {
              rules: [{ required: true, message: '请输入供应商金蝶名称' },
                // {pattern: /^[\u4e00-\u9fa5]{0,}$/, message: '仅能输入中文'}
              ]
            })(
              <Input placeholder="供应商金蝶名称" />
            )}
          </FormItem>
          <FormItem label="供应商编码">
            {getFieldDecorator('code', {
              rules: [{ required: true, message: '请输入端口编码' },
              {
                pattern: /^[0-9]*$/, message: '仅能输入数字'
              }]
            })(
              <Input placeholder="端口编码" />
            )}
          </FormItem>
          <FormItem label="排序">
            {getFieldDecorator('sort', {
              rules: [{ required: true, message: '请输入字段排序' },
              {
                pattern: /^\+?[1-9][0-9]*$/, message: '仅能输入正整数'
              }]
            })(
              <Input placeholder="排序" />
            )}
          </FormItem>
        </Form>
      </Modal>
      <Drawer
        title="关联项目"
        width={640}
        onClose={() => setProjectsVisible(false)}
        visible={projectsVisible}
        destroyOnClose={true}
      >
        <Spin spinning={projectLoading}>
          <Setprojects
            setProjectsVisible={setProjectsVisible}
            supplierId={supplierId}
            getList={getSysSupplierList}
            setProjectLoading={setProjectLoading}
          />
        </Spin>
      </Drawer>
      <Drawer
        title="关联行业"
        width={640}
        onClose={() => setIndustryVisible(false)}
        visible={industryVisible}
        destroyOnClose={true}
      >
        <Spin spinning={formLoading}>
          <SetIndustry
            setIndustryVisible={setIndustryVisible}
            supplierId={supplierId}
            getList={getSysSupplierList}
            setformLoading={setformLoading}
          />
        </Spin>
      </Drawer>
    </>
  )
}

export default Form.create()(SupplierMgt)