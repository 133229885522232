/**
 * 模块名称: 待结算列表 => 退款订单列表页 => 结算弹窗
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
	Form,
	Icon,
	message,
	Upload
} from 'antd'
import { upLoadModule } from '@/utils/common'
import BtnGroup from '@/components/BtnGroup'

const { Dragger } = Upload
const FormItem = Form.Item

const ClosingModal = (props) => {
	const { match, location, history, closing, setVisibleClosing, getEmployeeList } = props
	const { getFieldDecorator, resetFields, setFieldsValue, validateFields } = props.form
	const draggerRef = useRef(null)
	const [fileList, setFileList] = useState([])

	useEffect(() => {

	}, [])

	const formSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			let uploadList = []
			for (let i = 0, k = fileList.length; i < k; i++) {
				if (fileList[i].status !== 'removed') {
					uploadList.push({
						url: fileList[i].url,
						name: fileList[i].name
					})
				}
			}
			if (!err) {
				// if (!uploadList.length > 0) {
				// 	return message.error('请上传付款凭证后确认')
				// }
				api.setRefundOrderSettlement({
					id: closing.id,
					certificate: uploadList
				}).then(res => {
					setVisibleClosing(false)
					getEmployeeList()
				}).catch(err => console.log(err))

			}
		})
	}

	// 文件上传 
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.png, .jpg, .PDF',
		allowSizeType: 2
	})

	const formItemLayout = {
		labelCol: {
			span: 6
		},
		wrapperCol: {
			span: 14
		}
	}

	return (
		<Form onSubmit={formSubmit} {...formItemLayout}>
			<FormItem label="业务编号">
				<div>{closing.id}</div>
			</FormItem>
			<FormItem label="退款金额">
				<div>{closing.amount}</div>
			</FormItem>
			<FormItem label="公司主体">
				<div>{closing.companyName}</div>
			</FormItem>
			<FormItem label="收款单位">
				<div>{closing.acceptCompany}</div>
			</FormItem>
			<FormItem label="开户行">
				<div>{closing.bankName}</div>
			</FormItem>
			<FormItem label="付款凭证" >
				<div className="dragger-box" ref={draggerRef} style={{ position: 'relative' }}>
					{/* <span style={{ position: 'absolute', left: '-90px', top: '0px', color: 'red' }}>*</span> */}
					<Dragger
						{...uploadFiles}
					>
						<p className="ant-upload-drag-icon">
							<Icon type="inbox" />
						</p>
						<p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
						<p className="ant-upload-hint">
							支持扩展名：.png, jpg, .pdf 单个文件大小不超过5M。
						</p>
					</Dragger>
				</div>
			</FormItem>
			<BtnGroup cancelName="取消" confirmName="确认" onConfirm={formSubmit} onCancel={() => setVisibleClosing(false)} />
		</Form>
	)
}

export default Form.create()(ClosingModal)