/**
 * 模块名称: 关联行业
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Tree
} from 'antd'
import BtnGroup from '@/components/BtnGroup'

const { TreeNode } = Tree

const SetIndustry = (props) => {
  const { getList, supplierId, setIndustryVisible, setformLoading } = props
  const [industryArr, setIndustryArr] = useState([])
  const [arrList, setArrList] = useState([])
  const [selectMap, setSelectMap] = useState({})

  useEffect(() => {
    getSysSupplierProjectIndustryList()
  }, [])

  const getSysSupplierProjectIndustryList = () => {
    setformLoading(true)
    api.getSysSupplierProjectIndustryList({ supplierId }).then(data => {
      setIndustryArr(data.list)
      let arr = []
      let map = {}
      let temp = data.list
      for (let i = 0; i < temp.length; i++) {
        let tempArr = []

        let tempArr2 = temp[i].industryList
        for (let k = 0; k < tempArr2.length; k++) {
          let temp2 = tempArr2[k]
          if (temp2.industryList.length == 0) {
            if (temp2.hasChoosed == 1) {
              arr.push(temp2.id)
              tempArr.push(temp2.id)
            }
          } else {
            for (let j = 0; j < temp2.industryList.length; j++) {
              if (temp2.industryList[j].hasChoosed) {
                arr.push(temp2.industryList[j].id)
                tempArr.push(temp2.industryList[j].id)
              }
            }
          }
        }
        map[temp[i].sysProjectId] = tempArr
      }
      // console.log("map", map)
      setArrList(arr)
      setSelectMap(map)
      setformLoading(false)
    }).catch(() => setformLoading(false))
  }

  const onSelect = (selectedKeys, info) => {
    // console.log('selected', selectedKeys, info);
  }

  const onCheck = (checkedKeys, id, v) => {
    // console.log('onCheck', checkedKeys, id, v);

    // 1.判断是否选择
    let checked = v.checked
    // 2.拿到此次点击的id
    let clickId = parseInt(v.node.props.eventKey)

    let arr = []
    let map = { ...selectMap }
    let tempArr = (map[id] == undefined ? [] : map[id])

    // 3.如果是选择就将id添加到数组中，否则删除
    if (checked) {
      tempArr.push(clickId)
    } else {
      tempArr.splice(tempArr.findIndex(item => item === clickId), 1)
    }
    map[id] = tempArr
    // 4.将所有选择的元素id遍历赋值给arrList
    for (let key in map) {
      arr.push(...map[key])
    }
    setArrList(arr)
    setSelectMap(map)
  }

  const onSubmit = () => {
    api.updateSysSupplierProjectIndustrys({
      supplierId: supplierId,
      package: selectMap
    }).then(data => {
      setIndustryVisible(false)
      getList()
    })
  }

  // 全选
  const onChooseAll = (v) => {
    // 一级数组
    let arr1 = v.industryList
    let ids = []
    let map = { ...selectMap }
    for (let i = 0; i < arr1.length; i++) {
      // 二级数组
      let arr2 = arr1[i].industryList
      if (arr2 == null || arr2.length == 0) {
        ids.push(arr1[i].id)
      }
      for (let j = 0; j < arr2.length; j++) {
        ids.push(arr2[j].id)
      }
    }
    map[v.sysProjectId] = ids
    let arr3 = [...arrList, ...ids]
    setArrList(Array.from(new Set(arr3)))
    setSelectMap(map)
  }

  return (
    <div className="setIndustry">
      <div className="industrySty">{
        industryArr.length > 0 && industryArr.map((v, i) => {
          return <div key={v.id}>
            <div className="industryTopBtn">
              <div style={{fontSize: 14}}>{v.name}</div>
              {v.industryList.length !== 0 && <a onClick={() => onChooseAll(v)}>全选</a>}
            </div>
            {
              v.industryList.length > 0 && v.industryList.map((item, i) => {

                return <Tree
                  checkable
                  // defaultExpandedKeys={['0-0-0', '0-0-1']}
                  // defaultSelectedKeys={['0-0-0', '0-0-1']}
                  checkedKeys={arrList}
                  onSelect={onSelect}
                  onCheck={(checkedKeys, e) => { onCheck(checkedKeys, v.sysProjectId, e) }}
                  defaultExpandAll={true}
                  style={{marginLeft: 20}}
                  key={item.id}
                >
                  <TreeNode title={item.name} key={item.id} disabled={item.industryList.length > 0 ? true : false}>
                    {
                      item.industryList.length > 0 && item.industryList.map((val, i) => {
                        return <TreeNode title={val.name} key={val.id} />
                      })
                    }
                  </TreeNode>
                </Tree>
              })
            }
          </div>
        })
      }</div>
      <div style={{ height: 60 }}></div>
      <div className="setShortCloseBtn">
        <BtnGroup cancelName="关闭" confirmName="确定" onConfirm={onSubmit} onCancel={() => setIndustryVisible(false)} />
      </div>
    </div>
  )
}

export default SetIndustry