/**
 * 模块名称: 待结算列表 => 采购订单
 * @author jiashaowang@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PurchaseStay from './PurchaseStay'

const PurchaseStayIndex = ({ match, history }) => {
  return (
    <Switch>
      <Route component={PurchaseStay} />
    </Switch>
  )
}

export default PurchaseStayIndex