/**
 * 模块名称: 供应商管理 => 合同盖章申请 => 详情
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
	Descriptions,
	Spin,
	Icon,
	message,
	Steps,
	Button,
	Modal,
	Input,
	Form
} from 'antd'
import api from '@/api'
import { parseSearch, debounce } from '@/utils'
import CryptoJS from 'crypto-js'
import '../assets/style.scss'

const { Step } = Steps
const dItem = Descriptions.item

let areaTxt = '', result = ''

var temp

const Details = (props) => {
	const { history, location } = props
	const { getFieldDecorator } = props.form
	const draggerRef = useRef(null)
	const [detail, setDetail] = useState({})
	const [flow, setFlow] = useState([])
	const [modalVisible, setModalVisible] = useState(false)
	const [pageId, setPageId] = useState('')
	const [auditRecord, setAuditRecord] = useState([])
	const [loading, setLoading] = useState(false)
	const [resPlaceholder, setResPlaceholder] = useState('请输入至少1个字符')
	const [files, setFiles] = useState([])
	//操作权限
	const [auditAuth, setAuditAuth] = useState(false)//设置按钮组是否展示
	const [hasChargerPower, setHasChargerPower] = useState(false)//设置驳回和通过按钮是否展示
	const [replenish, setReplenish] = useState(false)//设置补充资料按钮是否展示
	const [returnUp, setReturnUp] = useState(false)//设置转上级按钮是否展示
	const [rejectMsgRequire, setRejectMsgRequire] = useState(false)//设置审批意见驳回必填

	useEffect(() => {
		let plaintext = parseSearch(location.search).sign
		let plaintextTwo = CryptoJS.MD5(parseSearch(location.search).id + 'ad629fddf8b8756d2e72e96ae035a5a4' + parseSearch(location.search).time).toString()

		if (plaintext == plaintextTwo && +new Date() - +parseSearch(location.search).time <= 21600000) {
			areaTxt = ''
			result = ''
			if (location.search) {
				setPageId(parseSearch(location.search).id)
				getSupplierContractSealInfo(parseSearch(location.search).id)
			} else {
				history.push({
					pathname: '/corpAccount/InternalAccount'
				})
			}
		} else {
			message.error('没有本页访问权限')
			setTimeout(() => {
				history.go(-2)
			}, 1000)
		}
	}, [])

	//获取详情
	const getSupplierContractSealInfo = (id) => {
		api.getSupplierContractSealInfo({ id: id }).then(res => {
			if (parseSearch(location.search).msgId) {
				api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
			}
			if (JSON.stringify(res) !== "[]") {
				let data = Object.assign({}, res)
				console.log(data)
				if (data.status === 0) data.current = 0
				if (data.status === 1) data.current = 1
				if (data.status === 2) data.current = 2
				if (data.status === 3) data.current = 3
				data.attachment && setFiles(JSON.parse(data.attachment))
				setDetail(data)
				let auditFlow = data.audit_flow
				auditFlow.forEach((item, index, self) => {
					if (item.persons && item.persons.length) {
						self[index].persons = item.persons.join('，')
					}
				})
				setFlow(auditFlow)
				data.audit_log && setAuditRecord(data.audit_log)
				if (data.auditAuth && JSON.stringify(data.auditAuth) !== '[]') {
					setAuditAuth(true)
					setHasChargerPower(data.auditAuth.hasChargerPower)
					setReplenish(data.auditAuth.replenish)
					setReturnUp(data.auditAuth.return_up)
					setRejectMsgRequire(data.auditAuth.reject_msg_require)
				} else {
					setAuditAuth(false)
					setHasChargerPower(false)
					setReplenish(false)
					setReturnUp(false)
					setRejectMsgRequire(false)
				}
				// setOldData(data.oldData)
			} else {
				setDetail({})
				setFlow([])
				setAuditRecord([])
			}
			setLoading(false)
		})
	}

	//点击4个审批的按钮
	const leaveOutAudit = (no) => {
		areaTxt = ''
		result = no
		if (no == 0) {
			if (detail.luruhetongFlag) {
				setResPlaceholder('请输入审批意见')
				setModalVisible(true)
			} else {
				setResPlaceholder('请输入审批意见')
				setModalVisible(true)
			}

		} else if (no == 1) {
			//审批意见是否为必填项改由审批流控制 2020年2月22日 17:19:14
			setResPlaceholder('请输入审批意见')
			setModalVisible(true)
		}
		// else {
		//   api.setInnerFundTransferAudit({
		//     id: pageId,
		//     type: result,
		//     remark: areaTxt
		//   }).then(res => {
		//     //todo 转上级或者补充资料 返回列表页
		//     let timer = setTimeout(() => {
		//       history.push({
		//         pathname: '/corpAccount/InternalAccount'
		//       })
		//       clearTimeout(timer)
		//     }, 1000)
		//   })
		// }
	}

	const oprateHandleOk = () => {
		if (areaTxt.length < 1 && result == 1 && rejectMsgRequire) {
			message.warning('请输入至少1个字符')
			return
		}
		let params = {
			id: pageId,
			type: result,
			remark: areaTxt
		}
		api.setSupplierContractSealAudit(params).then(res => {
			message.success('审批成功')
			setModalVisible(false)
			getSupplierContractSealInfo(pageId)
		})
	}

	//取消弹窗
	const oprateHandleCancel = () => {
		setModalVisible(false)
	}

	//同意的样式
	const agreeNode = () => {
		return result === 0 ? <>
			通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
		</> : result === 1 ? <>驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" /></> :
			<>转上级</>
	}

	//审批意见
	const getAreaTxt = (e) => {
		areaTxt = e.target.value.trim()
	}

	//退回列表页
	const onGoBack = () => {
		history.push({ pathname: '/supplie/supplierContractSeal', state: { goBack: 1 } })
	}

	return (
		<>
			<Spin spinning={loading}>
				{
					parseSearch(location.search).new && parseSearch(location.search).new == 1 && <Button onClick={onGoBack} type="primary" style={{
						position: 'absolute',
						right: 0,
						top: '-20px'
					}}>返回上一页</Button>
				}
				<h1 style={{ padding: '5px 0', fontWeight: 800 }}>盖章申请详情</h1>
				<div className="line"></div>
				<div className="step-wrap">
					<div className="contract-list-title">审批进度</div>
					<Steps>
						{
							flow.map((item, index, self) => {
								let status = 'wait'
								if (item.time && item.time.length > 1) {
									status = 'process'
								}
								return <Step status={status} title={item.nodeName} key={index} description={
									<>
										{item.persons && <div title={item.persons}>{item.persons}</div>}
										<div title={item.statusName}>{item.statusName}</div>
										<div title={item.time}>{item.time}</div>
									</>
								} />
							})
						}
					</Steps>
				</div>
				<div className="line"></div>
				<Descriptions title="合同信息" layout="horizontal" column={1} className="order-detail-title" style={{ borderBottom: 'none', marginTop: '10px' }}>
					<dItem label="合同号">{detail.supplierContractNo}</dItem>
					<dItem label="我方签约主体">{detail.firstPartyName}</dItem>
					<dItem label="对方签约主体">{detail.secondPartyName}</dItem>
					<dItem label="附件">
						<>
							{
								files.length > 0 ? files.map((item, i) => {
									return <div key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
								}) : <div>无</div>
							}
						</>
					</dItem>
					<dItem label="备注">{detail.remark ? detail.remark : '无'}</dItem>
				</Descriptions>
				<div className="line"></div>
				<div className="record-list" style={{ marginTop: '10px', marginBottom: '50px' }}>
					<Descriptions title="审批记录" layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none", marginBottom: "-30px" }}>
						<dItem label="姓名"></dItem>
						<dItem label="时间"></dItem>
						<dItem label="状态"></dItem>
						<dItem label="审批意见"></dItem>
					</Descriptions>
					{
						auditRecord.map((item, index) => {
							return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{ borderBottom: "none", marginBottom: "-10px" }}>
								<dItem label="">{item.userInfo.staffName}</dItem>
								<dItem label="">{item.addTime}</dItem>
								<dItem label="">{item.status}</dItem>
								<dItem label="">{item.chargeMsg}</dItem>
							</Descriptions>
						})
					}
				</div>
				{/* <div className="line"></div> */}
			</Spin>
			<div className="supplier-contract-bottom">

				{auditAuth && <div>
					{/* {replenish && <Button onClick={debounce(() => { leaveOutAudit(3) }, 3000)} className="orange-btn">补充资料</Button>}
            			{returnUp && <Button onClick={debounce(() => { leaveOutAudit(2) }, 3000)} className="orange-btn">转上级</Button>} */}
					{hasChargerPower && <Button onClick={() => leaveOutAudit(1)} style={{ marginLeft: '15px' }}>驳回</Button>}
					{hasChargerPower && <Button onClick={() => leaveOutAudit(0)} type="primary" style={{ marginLeft: '15px' }}>通过</Button>}
				</div>}
				<Modal
					title={agreeNode()}
					visible={modalVisible}
					onOk={debounce(() => { oprateHandleOk() }, 3000)}
					onCancel={oprateHandleCancel}
					destroyOnClose={true}
					footer={[
						<Button key="back" onClick={oprateHandleCancel}>取消</Button>,
						<Button key="submit" type="primary" onClick={debounce(() => { oprateHandleOk() }, 3000)}>
							确定
						</Button>]}
				>
					<div className="officework-modal-content">
						<span className="label">审批意见：</span><Input.TextArea className="content-area" placeholder={resPlaceholder} onChange={getAreaTxt} rows={4} style={{ width: "100%" }} maxLength={100} />
					</div>
				</Modal>

			</div>
		</>
	)
}

export default Form.create()(Details)