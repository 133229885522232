/**
 * 模块名称: 待结算列表 => 采购订单列表页 => 操作表单
 * @author jiashaowang@372163.com
 */

import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Upload, Icon, message } from 'antd';
import BtnGroup from '@/components/BtnGroup';
import { upLoadModule } from '@/utils/common';

const FormItem = Form.Item;
const TextArea = Input.TextArea;
const { Dragger } = Upload;
const PurchaseActionsForm = (props) => {
  const { type, values, onSubmit, onCancel, form } = props;
  const { getFieldDecorator, resetFields, setFieldsValue, validateFields } =
    form;
  const draggerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (values) {
      const { id, payeeBank, payeeAccount } = values;
      console.log(type);
      console.log(values);
      setFieldsValue(type === 0 ? { id, payeeBank, payeeAccount } : { id });
    }
  }, [type]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        if (type === 1) {
          values.certificate = fileList.map((item) => ({
            name: item.name,
            url: item.url,
          }));
          // if (fileList.length < 1) {
          //   message.error('请上传付款凭证后确认');
          //   return false;
          // } else {
          //   values.certificate = fileList.map((item) => ({
          //     name: item.name,
          //     url: item.url,
          //   }));
          // }
        }
        onSubmit(values, setLoading);
      }
    });
  };

  // 文件上传
  const uploadFiles = upLoadModule({
    fileList: fileList,
    setFileList: setFileList,
    draggerRef: draggerRef,
    accept: '.png, .jpg, .PDF',
    allowSizeType: 2,
  });

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
  };

  return (
    <Form onSubmit={onFormSubmit} {...formItemLayout}>
      {getFieldDecorator('id', {
        rules: [{ required: true }],
      })(<Input hidden />)}
      <FormItem label='业务编号'>
        <div>{values.id}</div>
      </FormItem>
      <FormItem label='付款金额'>
        <div>{values.payMoney}</div>
      </FormItem>
      {type === 0 ? (
        <FormItem label='收款单位'>
          <div>{values.payee}</div>
        </FormItem>
      ) : (
        <>
          <FormItem label='公司主体'>
            <div>{values.corpName}</div>
          </FormItem>
          <FormItem label='收款单位'>
            <div>{values.payee}</div>
          </FormItem>
          <FormItem label='开户行'>
            <div>{values.payeeBank}</div>
          </FormItem>
        </>
      )}

      {type === 0 && (
        <>
          <FormItem label='开户行'>
            {getFieldDecorator('payeeBank', {
              rules: [{ required: true, message: '请输入开户行' }],
            })(<Input />)}
          </FormItem>
          <FormItem label='银行账号'>
            {getFieldDecorator('payeeAccount', {
              rules: [{ required: true, message: '请输入银行账号' }],
            })(<Input />)}
          </FormItem>
        </>
      )}
      {type === 1 && (
        <FormItem label='付款凭证'>
          <div
            className='dragger-box'
            ref={draggerRef}
            style={{ position: 'relative' }}
          >
            {/* <span
              style={{
                position: 'absolute',
                left: '-90px',
                top: '0px',
                color: 'red',
              }}
            >
              *
            </span> */}
            <Dragger {...uploadFiles}>
              <p className='ant-upload-drag-icon'>
                <Icon type='inbox' />
              </p>
              <p className='ant-upload-text'>点击或将文件拖拽到这里上传</p>
              <p className='ant-upload-hint'>
                支持扩展名：.png, jpg, .pdf 单个文件大小不超过5M。
              </p>
            </Dragger>
          </div>
        </FormItem>
      )}
      {type === 2 && (
        <FormItem label='终止原因'>
          {getFieldDecorator('failedReason', {
            rules: [{ required: true, message: '请填写终止原因后确认' }],
          })(<TextArea rows={4} maxLength={100} />)}
        </FormItem>
      )}
      <BtnGroup
        cancelName='取消'
        confirmName='确认'
        loading={loading}
        onConfirm={onFormSubmit}
        onCancel={onCancel}
      />
    </Form>
  );
};

export default Form.create()(PurchaseActionsForm);
