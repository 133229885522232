/**
 * 模块名称: 待结算列表 => 退款订单列表页 => 终止付款弹窗
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
	Form,
	Input,
	message
} from 'antd'
import BtnGroup from '@/components/BtnGroup'

const { TextArea } = Input
const FormItem = Form.Item

const PayFailedModal = (props) => {
	const { match, location, history, payFailed, setVisiblePayFailed, getEmployeeList } = props
	const { getFieldDecorator, resetFields, setFieldsValue, validateFields } = props.form
	console.log(payFailed)

	useEffect(() => {
		
	}, [])

	const formSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			if (!err) {
				// console.log(vals)
				api.setInnerFundTransferFail({
					id: payFailed.id,
					reason: vals.failedReason
				}).then(res => {
					// message.success('成功') 
					setVisiblePayFailed(false)
					getEmployeeList()
				}).catch(err => console.log(err))
			}
		})
	}

	const formItemLayout = {
		labelCol: {
			span: 6
		},
		wrapperCol: {
			span: 14
		}
	}

	return (
		<Form onSubmit={formSubmit} {...formItemLayout}>
			<FormItem label="业务编号">
				<div>{payFailed.id}</div>
			</FormItem>
			<FormItem label="转款金额">
				<div>{payFailed.money}</div>
			</FormItem>
			<FormItem label="转出账户">
				<div>{payFailed.fromAccountName}</div>
			</FormItem>
			<FormItem label="转入账户">
				<div>{payFailed.toAccountName}</div>
			</FormItem>
			<FormItem label="终止原因">
				{getFieldDecorator('failedReason', {
					rules: [{required: true, message: '请填写终止原因后确认'}]
				})(<TextArea rows={4} />)}
			</FormItem>
			<BtnGroup cancelName="取消" confirmName="确认" onConfirm={formSubmit} onCancel={() => setVisiblePayFailed(false)}/>
		</Form>
	)
}

export default Form.create()(PayFailedModal)