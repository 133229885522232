/**
 * 模块名称: 待结算列表 => 内部往来款订单
 * @author liujingxue@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import InternalAccount from './InternalAccount'

const InternalAccountIndex = ({ match, history }) => {

	return (
		<Switch>
			<Route component={InternalAccount} />
		</Switch>
	)
}

export default InternalAccountIndex