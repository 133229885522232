/**
 * 模块名称: 已结算列表 => 退款订单
 * @author jiashaowang@372163.com
 */

 import React from 'react'
 import { Switch, Route } from 'react-router-dom'
 import RefundDone from './RefundDone'
 
 const RefundDoneIndex = ({ match, history }) => {
 
   return (
     <Switch>
       <Route component={RefundDone} />
     </Switch>
   )
 }
 
 export default RefundDoneIndex