/*
 * @Author: zhuyan 
 * @Date: 2021-11-16 10:38:14 
 * 模块名称: 已支付-采购订单
 */

 import React from 'react'
 import { Switch, Route } from 'react-router-dom'
 import PurchaseDone from './PurchaseDone'
 
 const PurchaseDoneIndex = ({ match, history }) => {
 
   return (
     <Switch>
       <Route component={PurchaseDone} />
     </Switch>
   )
 }
 
 export default PurchaseDoneIndex