/**
 * 模块名称: 培训档案
 * @author lids@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import {
  Table,
  Form,
  Drawer,
  DatePicker,
  Input,
  Button,
  Select,
  Alert,
  Icon,
  Popconfirm,
  message,
  Spin,
  Modal,
  Dropdown,
  Menu
} from 'antd'

import Auth from '@/components/AuthMiddleware'
import Export from '@/components/Export'
import Import from '@/components/Import'
import EditDoc from './module/EditDoc'
import { parseSearch } from '@/utils'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option

const EditableContext = React.createContext()

let pageSize = 10
let currentPage = 1
let searchParamsTmp = {
  limit: pageSize, 
  page: currentPage
}
let storeFilterName = ''
let storeFilterContent = {}
let selectedIds = ''

const Documents = (props) => {
  const productsRef = useRef()
  const { history, match, location } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldsValue } = props.form
  
  const [showAll, setShowAll] = useState(false)
  const [filterModelvisible, setFilterModelvisible] = useState(false)
  const [departmentList, setDepartmentList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [organizationList, setOrganizationList] = useState([])
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [filterContent, setFilterContent] = useState([])
  const [filterName, setFilterName] = useState(undefined)
  const [showExport, setShowExport] = useState(false)
  const [showImport, setShowImport] = useState(false)
  const [checked, setChecked] = useState(0)
  const [editVisible, setEditVisible] = useState(false)
  const [editLoading, setEditLoading] = useState(true)
  const [rowData, setRowData] = useState({})
  const [courseList, setCourseList] = useState([])
  const [deleteModelvisible, setDeleteModelvisible] = useState(false)

  const [cateVisible, setCateVisible] = useState(false)
  const [cateEditVal, setCateEditVal] = useState('')
  const [editingCateKey, setEditingCateKey] = useState('')
  const [addCateVisible, setAddCateVisible] = useState(false)
  const [newCateName, setNewCateName] = useState('')

  const [courseVisible, setCourseVisible] = useState(false)
  const [courseEditVal, setCourseEditVal] = useState('')
  const [editingCourseKey, setEditingCourseKey] = useState('')
  const [addCourseVisible, setAddCourseVisible] = useState(false)
  const [newCourseName, setNewCourseName] = useState('')

  const ImportRef = useRef()

  const [authList, setAuthList] = useState([])//按钮权限
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)

  const search = parseSearch(props.location.search)

  useEffect(() => {
    //console.log(search)
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
    }
    getTrainrecordList()
    getPageAuth()
  },[])

  //按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then(list => {
      setAuthList(list)
    })
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  //获取培训档案列表
  const getTrainrecordList = () => {
    setLoading(true)
    api.getTrainrecordList(searchParamsTmp).then(res => {
      setCount(res.count)
      let list = res.list
      
      setList(list)
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(selectedRowKeys, selectedRows)
      // setCheckedId(selectedRowKeys.join(","))
      setChecked(selectedRowKeys.length)
      selectedIds = selectedRowKeys
    }
  }

  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getTrainrecordList()
  }

  /** 类别设置抽屉 start **/

  const isCateEditing = record => record.id === editingCateKey

  const editCateSave = (id) => {
    api.onTrainRecordCategoryOperate({
      id: id,
      catName: cateEditVal
    }).then(res => {
      message.success('保存成功')
      setEditingCateKey('')
      getCategoryList()
    })
  }

  const editCateCancel = (id) => {
    setEditingCateKey('')
  }

  const editCate = (record) => {
    setCateEditVal(record.catName)
    setEditingCateKey(record.id)
  }

  const onDeleteCate = (id) => {
    api.onTrainRecordCategoryDelete({id}).then(res => {
      message.success('删除成功')
      getCategoryList()
    })
  }

  const addCateType = () => {
    setAddCateVisible(true)
  }

  const onAddCateOperation = () => {
    if(!newCateName.length){
      message.warning('分类名不能为空')
      return
    }
    api.onTrainRecordCategoryOperate({
      catName: newCateName
    }).then(res => {
      message.success('添加分类成功')
      setEditingCateKey('')
      getCategoryList()
      setAddCateVisible(false)
    })
  }

  const getAddCateName = e => {
    setNewCateName(e.target.value)
  }

  const onChangeCateVal = (e) =>{
    e.persist()
    //console.log(e.target.value, e.target.defaultValue)
    setCateEditVal(e.target.value)
  }

  const setCate = () => {
    setCateVisible(true)
    getCategoryList()
  }

  /** 类别设置抽屉 end **/

  /** 课程设置抽屉 start **/

  const isCourseEditing = record => record.id === editingCourseKey

  const editCourseSave = (id) => {
    api.onTrainRecordCourseOperate({
      id: id,
      name: courseEditVal
    }).then(res => {
      message.success('保存成功')
      setEditingCourseKey('')
      getTrainRecordCourseList()
    })
  }

  const editCourseCancel = (id) => {
    setEditingCourseKey('')
  }

  const editCourse = (record) => {
    setCourseEditVal(record.name)
    setEditingCourseKey(record.id)
  }

  const onDeleteCourse = (id) => {
    api.onTrainRecordCourseDelete({id}).then(res => {
      message.success('删除成功')
      getTrainRecordCourseList()
    })
  }

  const addCourseType = () => {
    setAddCourseVisible(true)
  }

  const onAddCourseOperation = () => {
    if(!newCourseName.length){
      message.warning('课程名不能为空')
      return
    }
    api.onTrainRecordCourseOperate({
      name: newCourseName
    }).then(res => {
      message.success('添加课程成功')
      setEditingCourseKey('')
      getTrainRecordCourseList()
      setAddCourseVisible(false)
    })
  }

  const getAddCourseName = e => {
    setNewCourseName(e.target.value)
  }

  const onChangeCourseVal = (e) =>{
    e.persist()
    //console.log(e.target.value, e.target.defaultValue)
    setCourseEditVal(e.target.value)
  }

  const setCourse = () => {
    setCourseVisible(true)
    getTrainRecordCourseList()
  }

  /** 课程设置抽屉 end **/

  //点击重置
  const onReset = () => {
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
    }
    resetFields()
    setFilterName(undefined)
		setStatusValName(undefined)
    getTrainrecordList()
  }

  //导出
  const onExportFile = () => {
    /*if (checked !== 0) {
      setShowExport(true)
    }else{
      message.error('请选择导出内容')
    }*/
    setShowExport(true)
  }

  //获取部门列表
  const getDepartmentList = () => {
    api.getDepartment({limit: global.paramsLimit}).then(res => {
      setDepartmentList(res.list)
    })
  }
  //获取类别列表
  const getCategoryList = () => {
    api.getTrainrecordCategoryList().then(res => {
      setCategoryList(res)
    })
  }
  //获取组织/讲授类别列表
  const getOrganizationList = () => {
    api.getTrainRecordOrganizaData().then(res => {
      setOrganizationList(res)
    })
  }
  //课程列表接口
  const getTrainRecordCourseList = () => {
    api.getTrainRecordCourseList().then(res => {
      setCourseList(res)
    })
  }

  //数据转换
  const transData = (vals) => {
    if(vals !== undefined){
      if(vals.staffName !== undefined)searchParamsTmp.staffName = vals.staffName
      if(vals.departmentId !== undefined)searchParamsTmp.departmentId = vals.departmentId
      if(vals.categoryId !== undefined)searchParamsTmp.categoryId = vals.categoryId
      if(vals.courseId !== undefined)searchParamsTmp.courseId = vals.courseId
      if(vals.organization !== undefined)searchParamsTmp.organization = vals.organization
      if(vals.trainer !== undefined)searchParamsTmp.trainer = vals.trainer
      if(vals.trainDate !== undefined) searchParamsTmp.trainDate = moment(vals.trainDate).format('YYYY-MM-DD')
    }
  }
  
  //点击筛选
  const searchSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //console.log(vals)
      transData(vals)
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getTrainrecordList()
        setFilterName(undefined)
      }
    })
    
  }
  //选择保存方案
  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({key: location.pathname}).then(res => {
      setFilterContent(res.list)
    })
  }
  //选择方案
  const onFilterChecked = (item, val2) => {
    setFilterName(item)
    setStatusValName(val2.props.children.props.children[0])
    // let data = JSON.parse(item)
    let data = filterContent[item].content
    searchParamsTmp.staffName = data.staffName
    searchParamsTmp.departmentId = data.departmentId
    searchParamsTmp.categoryId = data.categoryId
    searchParamsTmp.courseId = data.courseId
    searchParamsTmp.organization = data.organization
    searchParamsTmp.trainer = data.trainer
    searchParamsTmp.trainDate = data.trainDate
    setFieldsValue({
      staffName: data.staffName ? data.staffName : undefined,
      departmentId: data.departmentId ? data.departmentId : undefined,
      categoryId: data.categoryId ? data.categoryId : undefined,
      courseId: data.courseId ? data.courseId : undefined,
      organization: data.organization ? data.organization : undefined,
      trainer: data.trainer ? data.trainer : undefined,
      trainDate: data.trainDate ? moment(data.trainDate) : undefined
    })
    getDepartmentList()
    getCategoryList()
    getOrganizationList()
    getTrainRecordCourseList()
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  //保存筛选条件
  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      //console.log(vals)
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item === '' || item !== undefined)) {
          storeFilterContent = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'trainDate') {
                storeFilterContent[k] = moment(vals[k]).format('YYYY-MM-DD')
              } else {
                storeFilterContent[k] = vals[k]
              }
            }
          }
          setFilterModelvisible(true)
        } else {
          message.error('请选择筛选内容')
        }
      }
    })
  }
  //保存筛选点击确定
  const filterHandleOk = () => {
    validateFields((err, vals) => {   
      api.saveSearchStorage({
        name: storeFilterName,
        key: location.pathname,
        content: storeFilterContent
      }).then(() =>{
        setFilterModelvisible(false)
        message.success('添加成功')
      })
    })
  }
  //取消筛选点击确定
  const filterHandleCancel = () => {
    setFilterModelvisible(false)
  }
  //修改筛选名称
  const onFilterInfoChange = (e) => {
    storeFilterName = e.target.value
  }

  //打开导入弹窗
  const onImportFile = () => {
    ImportRef.current.onRemoved()
    setShowImport(true)
  }

  const onEdit = (record) => {
    //console.log(record)
    setRowData(record)
    setEditVisible(true)
  }

  // 导入确定
  const onConfirmImport = (fileList) => {
    if (fileList[0].status !== 'removed') {
      api.getTrainRecordImportExcel({
        path: fileList[0].url
      }).then(res => {
        getTrainrecordList()
        message.success('导入成功')
        ImportRef.current.onLoading()
        setShowImport(false)
      }).catch(res => {
        ImportRef.current.onLoading()
        // console.log('导入', res)
      })
    } else {
      message.error('请导入模板')
    }
  }

  const onDelete = (id) => {
    api.getTrainRecordDelete({
      id: id
    }).then(res => {
      message.success('删除成功')
      for(let i = 0; i<selectedIds.length; i++){
        if(selectedIds[i] === id){
          selectedIds.splice(i, 1)
          setChecked(selectedIds.length)
          i--
        }
      }
      getTrainrecordList()
    })
  }

  const onBatchOperation = () => {
    api.getTrainRecordDelete({
      id: selectedIds.join(',')
    }).then(res => {
      message.success('删除成功')
      setChecked(0)
      getTrainrecordList()
      setDeleteModelvisible(false)
    })
  }
  const showDelModelClick = () => {
    if (checked === 0) {
      message.error('请选择批量操作内容')
      return
    }
    setDeleteModelvisible(true)
  }
  //批量设置
  const menu = (
    <>
    <Menu>
      <Menu.Item key="1">
        <Auth auths={authList} code="train-category-settings">
          <div onClick={setCate}>类别设置</div>
        </Auth>
      </Menu.Item>
      <Menu.Item key="2">
        <Auth auths={authList} code="train-course-settings">
          <div onClick={setCourse}>课程设置</div>
        </Auth>
      </Menu.Item>
      <Menu.Item key="3">
        <Auth auths={authList} code="delete">
          <div onClick={showDelModelClick}>批量删除</div>
        </Auth>
      </Menu.Item>
    </Menu>
    <Modal
      title="提示"
      visible={deleteModelvisible}
      onOk={onBatchOperation}
      onCancel={() => {setDeleteModelvisible(false)}}
    >
      确定要删除选中的档案吗？
    </Modal>
    </>
  )

  return (
    <>
      <div className="search-askforleave-wrap">
        <h4 className="title">筛选选项：</h4>
        {
          !showAll ? <span className="askforleave-filter-control" onClick={() => setShowAll(true)}>展开<Icon type="down" /></span>
          : <span className="askforleave-filter-control" onClick={() => setShowAll(false)}>收起<Icon type="up" /></span>
        }
        <Form onSubmit={searchSubmit} {...formItemLayout}>
          <div className={`form-box${showAll ? ' askforleave-form-auto' : ''}`}>
            <FormItem label="姓名">
              {getFieldDecorator('staffName')(<Input 
                placeholder="请输入姓名" 
              />)}
            </FormItem>
            <FormItem label="部门">
              {getFieldDecorator('departmentId')(<Select
                placeholder="全部" 
                onDropdownVisibleChange={bool => bool && getDepartmentList()}
                showSearch
                optionFilterProp="children"
              >
                {
                  departmentList.map((item, index) => {
                    return <Option key={item.id}>{item.name}（{item.companyName}）</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="类别">
              {getFieldDecorator('categoryId')(<Select
                placeholder="全部" 
                onDropdownVisibleChange={bool => bool && getCategoryList()}
              >
                {
                  categoryList.map((item, index) => {
                    return <Option key={item.id}>{item.catName}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="课程/项目">
              {getFieldDecorator('courseId')(<Select
                placeholder="全部" 
                onDropdownVisibleChange={bool => bool && getTrainRecordCourseList()}
              >
                {
                  courseList.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="组织/讲授部门">
              {getFieldDecorator('organization')(<Select
                placeholder="全部" 
                onDropdownVisibleChange={bool => bool && getOrganizationList()}
                showSearch
                optionFilterProp="children"
              >
                {
                  organizationList.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="主讲人">
              {getFieldDecorator('trainer')(<Input 
                placeholder="请输入主讲人姓名" 
              />)}
            </FormItem>
            <FormItem label="培训时间">
              {getFieldDecorator('trainDate', {
              })(<DatePicker allowClear={false} />)}
            </FormItem>
          </div>
          <FormItem className="btns" label=" " colon={false}>
            <div className="staff-filter-btns">
              {showAll && <>
                <h4 className="filter-title">已保存筛选方案：</h4>
                <div className="filter-select">
                  <Select style={{display: 'inline-block', width: '160px'}} placeholder="请选择" onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)} onSelect={onFilterChecked} value={filterName}> 
                    {filterContent.map((item, index) =>
                      <Option value={index} key={index}>
                        <div className="closeStyBtn">
                          {item.name}
                          {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                            e.stopPropagation()
                            onSearchDelete(item.id)
                          }} /></span> : null}
                        </div>
                      </Option>
                    )}
                  </Select>
                </div>
                <Button className="filter-savebtn" onClick={onSaveFilterTerm}>保存筛选条件</Button>
              </>}
              <Button onClick={onReset}>重置</Button>
              <Button type="primary" htmlType="submit">筛选</Button>
              <Modal
                title="保存筛选条件"
                visible={filterModelvisible}
                onOk={filterHandleOk}
                onCancel={filterHandleCancel}
              >
                <Input placeholder="请输入筛选名称" onChange={onFilterInfoChange}/>
              </Modal>
            </div>
          </FormItem>   
        </Form>
      </div>
      <div className="line"></div>
      <div className="add-wrap">
        {authList.length > 0 && !cateVisible && !courseVisible && <Dropdown overlay={menu}>
          <span className="ant-dropdown-link">
            <Button type="primary" icon="align-left">批量操作</Button>
          </span>
        </Dropdown>}
        <Auth auths={authList} code="import">
          <Button type="primary" title="导入" icon="download" onClick={onImportFile} style={{marginLeft: "20px"}}>导入</Button>
        </Auth>
        <Auth auths={authList} code="export">
          <Button type="primary" title="导出" icon="upload" onClick={onExportFile} style={{marginLeft: "20px"}}>导出</Button>
        </Auth>
      </div>
      <Alert className="corp-count" message={`已选择 ${checked}项，总计：${count}条数据`} type="info" showIcon />
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 2200 }}
        onChange={onChangeTable}
        rowSelection={rowSelection}

      >
        <Column title="序号" dataIndex="id" fixed="left" width={80} />
        <Column title="姓名" dataIndex="staffName" fixed="left" width={120} />
        <Column title="部门" dataIndex="departmentName" width={220} />
        <Column title="类别" dataIndex="categoryName" width={120} />
        <Column title="培训课程/项目" dataIndex='courseName' width={150} />
        <Column title="组织/讲授部门" dataIndex="organizationName" width={150} />
        <Column title="主讲人" dataIndex="trainer" width={120} />
        <Column title="培训课时" dataIndex="trainclass" width={120} />
        <Column title="整体评分" dataIndex="allscore" width={120} />
        <Column title="考核方式" dataIndex="testway" width={120} />
        <Column title="培训时间" dataIndex="trainingdate" width={120} />
        <Column title="考核时间" dataIndex="testdate" width={120} />
        <Column title="考核成绩" dataIndex="testresult" width={120} />
        <Column title="备注" dataIndex="note" width={150} />
        <Column title="补考分数" dataIndex="makeupscore" width={120} />
        <Column title="提出的意见和建议" dataIndex="idea" width={200} />
        <Column title="操作"
          key="set" 
          fixed="right"
          width={100}
          render={(text, record) =>
            <>
              <Auth auths={text.operateAuth} code="edit">
                <Icon className="operate-icon" style={{ color: '#1890ff' }} type="edit" onClick={() => onEdit(record)} />
              </Auth>
              {' '}
              <Auth auths={text.operateAuth} code="delete">
                <Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                  <Icon className="operate-icon" style={{ color: 'red' }} type="delete" />
                </Popconfirm>
              </Auth>
            </>
          }
        />
      </Table>
      <Drawer
        title="编辑培训档案"
        width={800}
        onClose={() => setEditVisible(false)}
        visible={editVisible}
        destroyOnClose={true}
      >
        <Spin spinning={editLoading}>
          <EditDoc
            setEditVisible={setEditVisible}
            getTrainrecordList={getTrainrecordList}
            setEditLoading={setEditLoading}
            rowData={rowData}
          />
        </Spin>
      </Drawer>
      <Drawer
        title="类别设置"
        width={600}
        onClose={() => setCateVisible(false)}
        visible={cateVisible}
        destroyOnClose={true}
        className="train-type-drawer"
      >
        <EditableContext.Provider>
          <Table
            dataSource={categoryList}
            rowKey="id"
            className="cate-table"
            loading={false}
            pagination={false}
          >
            <Column title="序号" dataIndex="id" width={'15%'} />
            <Column title="类别名称"
              width={'55%'}
              render={(text, record) => 
                <>                  
                  {isCateEditing(record) ? <div><Input onPressEnter={() => editCateSave(record.id)} defaultValue={text.catName} onChange={onChangeCateVal} /></div> : <div style={{lineHeight: '32px'}}>{text.catName}</div>}
                </>
              }
            />
            <Column title="操作"
              width={'30%'}
              render={(text, record) => {
                const editable = isCateEditing(record)
                return editable ? (
                  <span>
                    <EditableContext.Consumer>
                      {() => (
                        <span
                          style={{cursor: 'pointer', color: '#1890ff', marginRight: 8}}
                          onClick={() => editCateSave(record.id)}
                        >
                          <Icon className="operate-icon" type="check" />
                        </span>
                      )}
                    </EditableContext.Consumer>
                    <Popconfirm title="确定要取消编辑吗？" onConfirm={() => editCateCancel(record.id)}>
                      <Icon className="operate-icon" type="close" />
                    </Popconfirm>
                  </span>
                ) : (
                  <>
                    <span style={{cursor: 'pointer', color: '#1890ff'}} disabled={editingCateKey !== ''} onClick={() => editCate(record)}>
                      <Icon className="operate-icon" type="edit" />
                    </span>    
                    <span style={{cursor: 'pointer', color: '#1890ff'}} disabled={editingCateKey !== ''}>
                      <Popconfirm title="确定要删除该类别吗？" onConfirm={() => onDeleteCate(record.id)}>
                        <Icon className="operate-icon" type="delete" />
                      </Popconfirm>
                    </span>
                  </>            
                )
              }}
            />
          </Table>
          <div className="type-add" onClick={addCateType}><span>+</span></div>
          <Modal
            title="新建分类"
            visible={addCateVisible}
            onOk={onAddCateOperation}
            onCancel={() => {setAddCateVisible(false)}}
            destroyOnClose={true}
          >
            <Input placeholder="请输入要添加的分类名称" onChange={getAddCateName} />
          </Modal>
        </EditableContext.Provider>
      </Drawer>
      <Drawer
        title="课程设置"
        width={600}
        onClose={() => setCourseVisible(false)}
        visible={courseVisible}
        destroyOnClose={true}
        className="train-type-drawer"
      >
        <EditableContext.Provider>
          <Table
            dataSource={courseList}
            rowKey="id"
            className="course-table"
            loading={false}
            pagination={false}
          >
            <Column title="序号" dataIndex="id" width={'15%'} />
            <Column title="课程名称"
              width={'55%'}
              render={(text, record) => 
                <>                  
                  {isCourseEditing(record) ? <div><Input onPressEnter={() => editCourseSave(record.id)} defaultValue={text.name} onChange={onChangeCourseVal} /></div> : <div style={{lineHeight: '32px'}}>{text.name}</div>}
                </>
              }
            />
            <Column title="操作"
              width={'30%'}
              render={(text, record) => {
                const editable = isCourseEditing(record)
                return editable ? (
                  <span>
                    <EditableContext.Consumer>
                      {() => (
                        <span
                          style={{cursor: 'pointer', color: '#1890ff', marginRight: 8}}
                          onClick={() => editCourseSave(record.id)}
                        >
                          <Icon className="operate-icon" type="check" />
                        </span>
                      )}
                    </EditableContext.Consumer>
                    <Popconfirm title="确定要取消编辑吗？" onConfirm={() => editCourseCancel(record.id)}>
                      <Icon className="operate-icon" type="close" />
                    </Popconfirm>
                  </span>
                ) : (
                  <>
                    <span style={{cursor: 'pointer', color: '#1890ff'}} disabled={editingCourseKey !== ''} onClick={() => editCourse(record)}>
                      <Icon className="operate-icon" type="edit" />
                    </span>    
                    <span style={{cursor: 'pointer', color: '#1890ff'}} disabled={editingCourseKey !== ''}>
                      <Popconfirm title="确定要删除该类别吗？" onConfirm={() => onDeleteCourse(record.id)}>
                        <Icon className="operate-icon" type="delete" />
                      </Popconfirm>
                    </span>
                  </>            
                )
              }}
            />
          </Table>
          <div className="type-add" onClick={addCourseType}><span>+</span></div>
          <Modal
            title="新建课程"
            visible={addCourseVisible}
            onOk={onAddCourseOperation}
            onCancel={() => {setAddCourseVisible(false)}}
            destroyOnClose={true}
          >
            <Input placeholder="请输入要添加的课程名称" onChange={getAddCourseName} />
          </Modal>
        </EditableContext.Provider>
      </Drawer>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="trainRecordExportTpl"
        fieldsUrl="getTrainTplItems"
        saveUrl="saveTrainExportTpls"
        exportUrl="exportTrain"
        method="getlist"
        parame={{ ExportType: 'trainrecord.getlist', id: selectedIds, ...getFieldsValue() }}
        cRef={productsRef}
      />
      <Import 
        onSearch={onConfirmImport}
        visibleFile={showImport}
        onCloseImport={() => setShowImport(false)}
        ImportTemplate="/api/File/DownTemplate?url=/templates/trainrecord.xlsx&name=培训档案导入模板"
        ImportRef={ImportRef}
      />
    </>
  )
}

export default Form.create()(Documents)