/**
 * 模块名称: 待结算列表
 * @author liujingxue@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import RefundStay from './RefundStay'
import PurchaseStay from './PurchaseStay'
import PayStay from './PayStay'
import CostStay from './CostStay'
import InternalAccount from './InternalAccount'
import './assets/style.scss'

const ProtocolIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/refundStay'} component={RefundStay} />
      <Route path={match.path + '/purchaseStay'} component={PurchaseStay} />
      <Route path={match.path + '/payStay'} component={PayStay} />
      <Route path={match.path + '/costStay'} component={CostStay} />
      <Route path={match.path + '/InternalAccount'} component={InternalAccount} />
      <Route component={RefundStay} />
    </Switch>
  )
}

export default ProtocolIndex